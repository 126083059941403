import { Button } from '../reusable/button';
import { AuthService } from '../../actions/auth/api-services';
import { ModalForm } from '../reusable/modal-form';
import { AuthContent } from './auth-content';

export class SettingsContent {
    private element: HTMLDivElement;
    private modalForm: ModalForm;
    private authContent: AuthContent;

    private readonly appSections = [
        'Authentication', 'Analytix', 'PlatoChat', 'Kommin', 'Design', 
        'Notifications', 'Emailing Services', 'Location Services', 
        'Profile Management', 'Search Functionality', 'Settings',
        'User Interactions', 'Content Display', 'Media Handling',
        'Performance', 'Security', 'Data Synchronization',
        'Offline Mode', 'Accessibility Features', 'Language/Localization',
        'Third-party Integrations', 'Payments/Transactions', 
        'Onboarding Process', 'Tutorial/Help', 'Other', 'Unsure'
    ];

    private readonly issueTypes = [
        'Not Functioning', 'Not Navigating', 'Permanently Loading', 'Frozen App',
        'Retrieving Incorrect Data', 'Overflow on the X or Y Axis', 
        'Crash on Specific Action', 'Unexpected Logout', 'Data Loss',
        'Slow Performance', 'High Battery Drain', 'Excessive Data Usage',
        'Inconsistent Behavior', 'UI Elements Misaligned', 'Unresponsive Buttons',
        'Incorrect Error Messages', 'Feature Not Working as Expected',
        'Compatibility Issues', 'Login/Authentication Failure',
        'Notification Issues', 'Search Not Yielding Results',
        'Sync Problems', 'Accessibility Feature Not Working',
        'Language/Translation Errors', 'Security Concern',
        'Payment Processing Issue', 'Other'
    ];

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'space-y-6 p-3';
        this.modalForm = new ModalForm();
        this.authContent = new AuthContent();
        
        // Mount the modal form to the document body
        this.modalForm.mount(document.body);

        const isLoggedIn = localStorage.getItem('userId') !== null;

        // Tutorial Video Section
        const videoSection = this.createSection('Tutorial Video', 'fa-video');
        const videoContainer = document.createElement('div');
        videoContainer.className = 'relative w-full sm:w-[400px] aspect-video rounded-lg overflow-hidden shadow-md ml-0';
        videoContainer.innerHTML = `
            <iframe 
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/SpwQBoC9EqE" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
                class="absolute top-0 left-0 w-full h-full"
            ></iframe>
        `;
        videoSection.appendChild(videoContainer);

        // Toggles Section - only show if logged in
        if (isLoggedIn) {
            const togglesSection = this.createSection('Preferences', 'fa-sliders-h');
            const gamificationToggle = this.createToggle('Enable Gamification', 'gamification');
            const newsletterToggle = this.createToggle('Subscribe to Newsletter', 'newsletter');
            togglesSection.append(gamificationToggle, newsletterToggle);
            this.element.appendChild(togglesSection);
            
            // Initialize toggle states
            this.initializeToggles();
        }

        // Features Link Section
        const featuresSection = this.createSection('Features', 'fa-star');
        const featuresLink = document.createElement('a');
        featuresLink.href = '/features';
        featuresLink.className = 'text-purple-400 hover:text-purple-300 transition-colors duration-300 flex items-center gap-2';
        featuresLink.innerHTML = `
            Check out upcoming features here 
            <i class="fas fa-arrow-right text-sm"></i>
        `;
        featuresSection.appendChild(featuresLink);

        // Add Report Issue Section before the account section
        if (isLoggedIn) {
            const reportSection = this.createSection('EasyReport an Issue', 'fa-bug');
            const reportButton = new Button('EasyReport an Issue');
            reportButton.getElement().className = `
                w-full px-3 py-1.5 text-sm font-medium bg-gradient-to-br from-orange-500/20 to-red-500/20 hover:from-orange-500/30 hover:to-red-500/30 text-white rounded-lg transition-all duration-300 shadow-md hover:shadow-orange-500/25 hover:scale-[1.01] active:scale-[0.99] transform backdrop-blur-sm border border-white/10 flex items-center justify-center gap-2
            `;
            
            reportButton.getElement().innerHTML = `
                <i class="fas fa-bug"></i>
                Report Issue
            `;

            reportButton.onClick(() => this.showEasyReportDialog());
            reportSection.appendChild(reportButton.getElement());
            this.element.appendChild(reportSection);
        }

        // Account Section
        const accountSection = this.createSection('Profile', 'fa-user');
        const authButton = new Button(isLoggedIn ? 'Logout' : 'Login');
        
        // Set button styles based on login state
        authButton.getElement().className = isLoggedIn ? `
            w-full px-4 py-2.5 text-sm font-medium bg-gradient-to-br from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700 text-white rounded-lg transition-all duration-300 shadow-md hover:shadow-red-500/25 hover:scale-[1.01] active:scale-[0.99] transform backdrop-blur-sm border border-white/10
        ` : `
            w-full px-6 py-2.5 text-base bg-purple-900 hover:bg-purple-800 rounded-lg transition-all duration-300 font-medium text-white/90 shadow-md hover:shadow-purple-500/20 hover:scale-[1.02] animate-fade-in
        `;

        authButton.getElement().innerHTML = isLoggedIn ? `
            <i class="fas fa-sign-out-alt mr-2"></i>
            Logout
        ` : `
            <i class="fas fa-sign-in-alt mr-2"></i>
            Login or Signup
        `;

        authButton.getElement().addEventListener('click', async () => {
            if (isLoggedIn) {
                await AuthService.logout();
                localStorage.removeItem('userId');
                window.location.reload();
            } else {
                this.modalForm.append(this.authContent.getElement());
                this.modalForm.open();
            }
        });

        accountSection.appendChild(authButton.getElement());

        this.element.append(videoSection, featuresSection, accountSection);
    }

    private createSection(title: string, iconClass: string): HTMLDivElement {
        const section = document.createElement('div');
        section.className = 'space-y-3';
        
        const heading = document.createElement('h3');
        heading.className = 'text-base font-medium text-white/90 flex items-center gap-2';
        heading.innerHTML = `
            <i class="fas ${iconClass}"></i>
            ${title}
        `;
        
        section.appendChild(heading);
        return section;
    }

    private async initializeToggles(): Promise<void> {
        const userId = localStorage.getItem('userId');
        if (!userId) return;

        const userData = await AuthService.getUserById(parseInt(userId));
        if (!userData) return;

        const gamificationToggle = this.element.querySelector('[data-preference="gamification"]');
        const newsletterToggle = this.element.querySelector('[data-preference="newsletter"]');
        
        if (gamificationToggle) {
            this.updateToggleState(gamificationToggle as HTMLElement, userData.gamification || false);
        }
        if (newsletterToggle) {
            this.updateToggleState(newsletterToggle as HTMLElement, userData.newsletter || false);
        }
    }

    private createToggle(label: string, preference: string): HTMLDivElement {
        const container = document.createElement('div');
        container.className = 'flex items-center justify-between py-1.5 px-2 hover:bg-white/5 rounded-lg cursor-pointer transition-colors duration-300';
        container.dataset.preference = preference;

        const labelElement = document.createElement('span');
        labelElement.className = 'text-white/70';
        labelElement.textContent = label;

        const toggle = document.createElement('div');
        toggle.className = `
            relative 
            w-10 
            h-5 
            rounded-full 
            bg-purple-600/20 
            transition-colors 
            duration-300
        `;

        const toggleCircle = document.createElement('span');
        toggleCircle.className = `
            absolute 
            left-1 
            top-1 
            w-3 
            h-3 
            rounded-full 
            bg-white 
            transition-transform 
            duration-300
        `;

        toggle.appendChild(toggleCircle);
        container.append(labelElement, toggle);

        container.addEventListener('click', async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) return;

            const isActive = toggle.classList.contains('bg-purple-600');
            const success = await AuthService.updateUser(parseInt(userId), {
                [preference]: !isActive
            });

            if (success) {
                this.updateToggleState(container, !isActive);
                if (preference === 'gamification') {
                    window.location.reload();
                }
            }
        });

        return container;
    }

    private updateToggleState(container: HTMLElement, isActive: boolean): void {
        const toggle = container.querySelector('div');
        const toggleCircle = container.querySelector('span:last-child') as HTMLElement;
        
        if (toggle && toggleCircle) {
            if (isActive) {
                toggle.className = 'relative w-10 h-5 rounded-full bg-purple-600 transition-colors duration-300';
                toggleCircle.style.transform = 'translateX(20px)';
            } else {
                toggle.className = 'relative w-10 h-5 rounded-full bg-white/10 transition-colors duration-300';
                toggleCircle.style.transform = 'translateX(0)';
            }
        }
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public getElement(): HTMLElement {
        return this.element;
    }

    private showEasyReportDialog(): void {
        const content = document.createElement('div');
        content.className = 'space-y-4 w-full max-w-md';

        content.innerHTML = `
            <h2 class="text-lg font-semibold text-white mb-3">
                Help us improve PLATO5 by reporting any issues you encounter during beta testing. 
                Your feedback is invaluable!
            </h2>
            
            <div class="space-y-3">
                <div class="space-y-2">
                    <label class="text-white/90 text-sm">Affected Applet</label>
                    <select class="w-full bg-white/5 border border-white/10 rounded-xl p-2 text-white">
                        ${this.appSections.map(section => 
                            `<option value="${section}">${section}</option>`
                        ).join('')}
                    </select>
                </div>

                <div class="space-y-2">
                    <label class="text-white/90 text-sm">Issue Type</label>
                    <select class="w-full bg-white/5 border border-white/10 rounded-xl p-2 text-white">
                        ${this.issueTypes.map(type => 
                            `<option value="${type}">${type}</option>`
                        ).join('')}
                    </select>
                </div>

                <div class="space-y-2">
                    <label class="text-white/90 text-sm">Description</label>
                    <textarea 
                        class="w-full bg-white/5 border border-white/10 rounded-xl p-2 text-white min-h-[100px] resize-none"
                        placeholder="Please describe the issue..."
                    ></textarea>
                </div>
            </div>

            <div class="flex justify-end gap-4 mt-6">
                <button class="px-4 py-2 text-white/70 hover:text-white transition-colors duration-300">
                    Cancel
                </button>
                <button class="px-4 py-2 bg-purple-500/20 hover:bg-purple-500/30 rounded-xl border border-white/10 text-white transition-all duration-300">
                    Submit
                </button>
            </div>
        `;

        const [cancelButton, submitButton] = content.querySelectorAll('button');
        const [appletSelect, issueSelect, descriptionTextarea] = content.querySelectorAll('select, textarea') as unknown as [HTMLSelectElement, HTMLSelectElement, HTMLTextAreaElement];

        cancelButton.onclick = () => this.modalForm.close();
        
        submitButton.onclick = async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) return;

            const success = await AuthService.submitEasyReport(
                parseInt(userId),
                appletSelect.value,
                issueSelect.value,
                descriptionTextarea.value
            );

            if (success) {
                this.modalForm.close();
            }
        };

        this.modalForm.clear();
        this.modalForm.append(content);
        this.modalForm.open();
    }
}