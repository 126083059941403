import { Button } from '../reusable/button';
import { ModalForm } from '../reusable/modal-form';
import { AuthContent } from '../auth/auth-content';
import { DownloadContent } from '../reusable/download-content';

export class Blurb {
    private element: HTMLDivElement;
    private authContent: AuthContent;
    private downloadContent: DownloadContent;

    constructor(private modalForm: ModalForm) {
        this.element = document.createElement('div');
        this.element.className = `
            flex
            flex-col
            items-center
            justify-center
            gap-8
            text-center
            mt-20
            px-4
            w-full 
        `;
        this.authContent = new AuthContent();
        this.downloadContent = new DownloadContent();

        this.modalForm.append(this.authContent.getElement());

        const text = document.createElement('p');
        text.textContent = 'PLATO5 is a social engine built to generate platonic relationships based on personality traits, location, and interests';
        text.className = `
            text-transparent bg-clip-text
            bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400
            text-2xl md:text-3xl lg:text-4xl
            font-extrabold
            font-['Inter']
            leading-relaxed
            max-w-3xl
            animate-gradient
            tracking-tight
            animate-fade-in
        `;

        const subheading = document.createElement('p');
        subheading.innerHTML = 'Check out some of the things you can do below <i class="fa-solid fa-arrow-down"></i>';
        subheading.className = `
            text-gray-400
            text-base
            font-medium
            animate-fade-in
        `;

        const actionButton = new Button('Login or Signup');
        actionButton.getElement().onclick = () => {
            this.modalForm.append(this.authContent.getElement());
            this.modalForm.open();
        };
        actionButton.getElement().className = `
            px-6 py-2
            text-base
            bg-purple-900
            hover:bg-purple-800
            rounded-full
            transition-all duration-300
            font-medium
            text-white/90
            shadow-lg
            hover:shadow-purple-500/20
            hover:scale-105
            animate-fade-in
        `;

        const downloadButton = new Button('Download the App');
        downloadButton.getElement().onclick = () => {
            this.modalForm.append(this.downloadContent.getElement());
            this.modalForm.open();
        };
        downloadButton.getElement().className = `
            px-6 py-2
            text-base
            bg-black
            hover:bg-gray-900
            rounded-full
            transition-all duration-300
            font-medium
            text-white
            shadow-lg
            hover:shadow-gray-500/20
            hover:scale-105
            animate-fade-in
            ml-4
        `;

        const buttonContainer = document.createElement('div');
        buttonContainer.className = 'flex items-center gap-3';
        buttonContainer.appendChild(actionButton.getElement());
        buttonContainer.appendChild(downloadButton.getElement());

        this.element.appendChild(text);
        this.element.appendChild(subheading);
        this.element.appendChild(buttonContainer);
    }

    mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}