import { AnalytixService } from '../../actions/analytix/api-services';
import { Question } from '../../actions/analytix/models';
import { AuthService } from '../../actions/auth/api-services';
import { KomminService } from '../../actions/kommin/api-services';
import { UINotifications } from '../../utilities/errors';

export class AnalytixCollector {
    private element: HTMLDivElement;
    private questions: Question[] = [];
    private responses: Map<number, number> = new Map();
    private currentPage: number = 0;
    private isShortVersion: boolean = true;
    private topITags: string[] = [];
    private geohash: string = '';
    private readonly userId: number;
    private onComplete: (success: boolean, result: any) => void;
    private lastPage?: number;

    constructor(userId: number, onComplete: (success: boolean, result: any) => void) {
        this.userId = userId;
        this.onComplete = onComplete;
        this.element = document.createElement('div');
        this.element.className = 'w-full';
        this.fetchData();
        this.render();
    }

    private async fetchData(): Promise<void> {
        try {
            const [topITags, userData] = await Promise.all([
                KomminService.getTopiTagsForUserId(this.userId),
                AuthService.getUserById(this.userId)
            ]);

            this.topITags = topITags ?? [];
            this.geohash = userData?.geohash ?? '';
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }

    private async fetchShortQuestions(): Promise<void> {
        try {
            const response = await AnalytixService.getShortAssessmentQuestions();
            this.questions = response.questions;
            this.initializeResponses();
            this.nextPage();
        } catch (error) {
            console.error('Failed to fetch short questions:', error);
        }
    }

    private async fetchLongQuestions(): Promise<void> {
        try {
            const response = await AnalytixService.getQuestions();
            this.questions = response.questions;
            this.initializeResponses();
            this.nextPage();
        } catch (error) {
            console.error('Failed to fetch long questions:', error);
        }
    }

    private initializeResponses(): void {
        this.questions.forEach((question) => {
            this.responses.set(question.id, 50.0); // Default value
        });
    }

    private nextPage(): void {
        this.currentPage++;
        this.render();
    }

    private previousPage(): void {
        this.currentPage--;
        this.render();
    }

    private async submit(): Promise<void> {
        const answers = Array.from(this.responses.entries()).map(([questionId, score]) => ({
            question_id: questionId,
            answer_score: score
        }));

        const request = {
            user_id: this.userId,
            answers,
            geohash: this.geohash,
            top_i_tags: this.topITags
        };

        try {
            const result = await AnalytixService.calculateAnalytixScores(request);
            if (result) {
                console.log('Analytix scores calculated successfully:', result);
                UINotifications.shipSuccessToUI(
                    'Personality Analytix built successfully!', 
                    { persist: true }
                );

            } else {
                console.error('Failed to calculate Analytix scores');
                UINotifications.shipErrorToUI('Failed to calculate assessment scores. Please try again.');
                this.onComplete(false, null);
            }
        } catch (error) {
            console.error('Error calculating Analytix scores:', error);
            UINotifications.shipErrorToUI('An error occurred while submitting your assessment. Please try again.');
            this.onComplete(false, null);
        }
    }

    private renderIntroScreen(): string {
        return `
            <div class="p-3 sm:p-6 flex flex-col items-center gap-4">
                <h2 class="text-xl sm:text-2xl font-bold text-white/90 text-center">
                    Welcome to the PLATO5 Personality Assessment!
                </h2>
                <p class="text-sm sm:text-base text-white/70 text-center max-w-md">
                    This assessment helps us understand your personality traits. You can choose between a short version, which will yield less accurate results, or a long version, which will generate the best personality profile.
                </p>
                <div class="flex flex-col gap-4 w-full sm:w-auto">
                    <button class="px-4 sm:px-6 py-2 sm:py-3 bg-purple-500 text-white rounded-lg text-sm sm:text-base" data-action="long">
                        Long Version (50 Questions)
                    </button>
                    <button class="px-4 sm:px-6 py-2 sm:py-3 bg-purple-500 text-white rounded-lg text-sm sm:text-base" data-action="short">
                        Short Version (20 Questions)
                    </button>
                </div>
            </div>
        `;
    }

    private renderQuestionPages(): string {
        const questionsPerPage = 3;
        const startIndex = (this.currentPage - 1) * questionsPerPage;
        const pageQuestions = this.questions.slice(startIndex, startIndex + questionsPerPage);

        return `
            <div class="p-3 sm:p-6 flex flex-col gap-6 sm:gap-8">
                ${pageQuestions.map((question, index) => this.renderQuestion(question, startIndex + index)).join('')}
            </div>
        `;
    }

    private renderQuestion(question: Question, index: number): string {
        const value = (this.responses.get(question.id) ?? 50).toFixed(1);
        return `
            <div class="flex flex-col gap-3 sm:gap-4">
                <p class="text-sm sm:text-base text-white/90 text-center">${question.question}</p>
                <div class="flex flex-col items-center gap-2">
                    <input 
                        type="range" 
                        min="1" 
                        max="100" 
                        step="0.1"
                        value="${value}"
                        class="w-full appearance-none h-2 rounded-lg bg-white/10 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-gradient-to-r [&::-webkit-slider-thumb]:from-purple-500 [&::-webkit-slider-thumb]:to-pink-500 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:cursor-pointer [&::-moz-range-thumb]:w-4 [&::-moz-range-thumb]:h-4 [&::-moz-range-thumb]:bg-gradient-to-r [&::-moz-range-thumb]:from-purple-500 [&::-moz-range-thumb]:to-pink-500 [&::-moz-range-thumb]:border-0 [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:cursor-pointer"
                        data-question-id="${question.id}"
                    >
                    <span class="text-xs sm:text-sm text-white/60">
                        ${value}
                    </span>
                </div>
            </div>
        `;
    }

    private renderFinalPage(): string {
        return `
            <div class="p-6 flex flex-col items-center gap-4">
                <h2 class="text-2xl font-bold text-white/90 text-center">
                    Thank you for completing the assessment!
                </h2>
                <p class="text-white/70 text-center">
                    Please review your responses before submitting.
                </p>
                <button class="px-6 py-3 bg-purple-500 text-white rounded-lg" data-action="submit">
                    Submit
                </button>
            </div>
        `;
    }

    private render(): void {
        const totalPages = Math.ceil(this.questions.length / 3) + 2;
        const direction = this.currentPage > (this.lastPage ?? 0) ? 'next' : 'prev';
        this.lastPage = this.currentPage;

        this.element.innerHTML = `
            <div class="w-full max-h-[90vh] sm:max-h-[500px] overflow-hidden p-2 sm:p-5">
                <div class="animate-${direction}-slide">
                    ${this.currentPage === 0 ? this.renderIntroScreen() :
                      this.currentPage === totalPages - 1 ? this.renderFinalPage() :
                      this.renderQuestionPages()}
                </div>
                
                <div class="flex items-center justify-between px-3 sm:px-6 py-3 sm:py-4 border-t border-white/10">
                    ${this.currentPage > 0 ? `
                        <button class="flex items-center gap-2 text-white/60 hover:text-white/90 transition-colors" data-action="previous">
                            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                            </svg>
                           
                        </button>
                    ` : '<div></div>'}
                    
                    <div class="text-white/60">
                        Page ${this.currentPage + 1} of ${totalPages}
                    </div>
                    
                    ${this.currentPage > 0 && this.currentPage < totalPages - 1 ? `
                        <button class="flex items-center gap-2 text-white/60 hover:text-white/90 transition-colors" data-action="next">
                           
                            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                            </svg>
                        </button>
                    ` : '<div></div>'}
                </div>
            </div>
        `;

        this.attachEventListeners();
    }

    private attachEventListeners(): void {
        this.element.querySelectorAll('button').forEach(button => {
            button.addEventListener('click', (e) => {
                const action = button.getAttribute('data-action');
                if (action === 'short') {
                    this.isShortVersion = true;
                    this.fetchShortQuestions();
                } else if (action === 'long') {
                    this.isShortVersion = false;
                    this.fetchLongQuestions();
                } else if (action === 'previous') {
                    this.previousPage();
                } else if (action === 'next') {
                    this.nextPage();
                } else if (action === 'submit') {
                    this.submit();
                }
            });
        });

        this.element.querySelectorAll('input[type="range"]').forEach(input => {
            input.addEventListener('input', (e) => {
                const questionId = parseInt((e.target as HTMLInputElement).getAttribute('data-question-id') ?? '0');
                const value = parseFloat((e.target as HTMLInputElement).value);
                this.responses.set(questionId, value);
                
                // Update just the value display without re-rendering
                const valueDisplay = (e.target as HTMLElement).nextElementSibling;
                if (valueDisplay) {
                    valueDisplay.textContent = value.toFixed(1);
                }
            });
        });
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        this.element.remove();
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}
