export class DownloadContent {
    private element: HTMLDivElement;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'space-y-4 text-center p-4';
        this.element.innerHTML = `
            <h2 class="text-2xl font-bold text-white mb-4">PLATO5 Mobile Apps</h2>
            <p class="text-white/80 mb-6">
                The iOS app will be released soon, and the Android app is pending Google Developer organization approval.
            </p>
            <p class="text-white/70">
                For updates, follow <a href="https://x.com/GoldsteinNichol" target="_blank" rel="noopener" 
                class="text-blue-400 hover:text-blue-300 transition-colors">@GoldsteinNichol</a> on <b>𝕏</b>
            </p>
        `;
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}