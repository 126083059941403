import { howSocialEnginesCanSolveTheLonelinessEpidemic, gamifiedConversationsAndNanoBlogging, exploreThePlato5SuiteKomminPlatochatAndMore, safetySuggestionsForOnlineMeetups } from "../../5mark/8-3";

export const articles = [
  {
    route: "/about/how-social-engines-can-solve-the-loneliness-epidemic",
    title: "How Social Engines Can Solve the Loneliness Epidemic",
    author: "Nick Goldstein",
    position: "Founder of PLATO5",
    publishDate: "2024-08-03",
    fiveMark: howSocialEnginesCanSolveTheLonelinessEpidemic,
  },
  {
    route: "/about/gamified-conversations-and-nano-blogging",
    title: "Gamified Conversations and Nano-Blogging",
    author: "Nick Goldstein",
    position: "Founder of PLATO5",
    publishDate: "2024-08-03",
    fiveMark: gamifiedConversationsAndNanoBlogging,
  },
  {
    route: "/about/explore-the-plato5-suite-kommin-platochat-and-more",
    title: "Explore the PLATO5 Suite of Applets: Kommin, PlatoChat, and More",
    author: "Nick Goldstein",
    position: "Founder of PLATO5",
    publishDate: "2024-08-03",
    fiveMark: exploreThePlato5SuiteKomminPlatochatAndMore,
  },
  {
    route: "/about/safety-suggestions-for-online-meetups",
    title: "Safety Suggestions for Online Meetups",
    author: "The PLATO5 Team",
    position: "",
    publishDate: "2024-08-03",
    fiveMark: safetySuggestionsForOnlineMeetups,
  },
];
