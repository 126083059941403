export class SocialLinks {
    private element: HTMLElement;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'social-links-container text-sm';
        this.render();
    }

    private render() {
        this.element.innerHTML = `
            <div class="social-links">
                <a href="https://x.com/PLATO5Social" target="_blank" rel="noopener noreferrer" class="social-link">
                    <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://www.instagram.com/plato5socialengines/" target="_blank" rel="noopener noreferrer" class="social-link">
                    <i class="fab fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/@PLATOtalk" target="_blank" rel="noopener noreferrer" class="social-link">
                    <i class="fab fa-youtube"></i>
                </a>
                <a href="https://www.tiktok.com/@GoldsteinNichol" target="_blank" rel="noopener noreferrer" class="social-link">
                    <i class="fab fa-tiktok"></i>
                </a>
                <a href="mailto:support@plato5.us" class="social-link">
                    <i class="fas fa-envelope"></i>
                </a>
            </div>
        `;
    }

    mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }
}