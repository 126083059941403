import { TopBar } from '../pieces/reusable/top-bar';
import { ModalForm } from '../pieces/reusable/modal-form';
import { ArticleRolodex } from '../pieces/tutorial/article-rolodex';

interface Feature {
    title: string;
    description: string;
    icon: string;
}

export class FeatureScreen {
    private element: HTMLElement;
    private modalForm: ModalForm;
    private topBar: TopBar;
    private articleRolodex: ArticleRolodex;

    constructor() {
        this.modalForm = new ModalForm();
        this.topBar = new TopBar(this.modalForm);
        this.articleRolodex = new ArticleRolodex();
        this.element = document.createElement('div');
        this.element.className = 'min-h-screen';
        
        this.modalForm.mount(this.element);
        
        this.initialize();
    }

    private initialize(): void {
        this.topBar.mount(this.element);

        const mainContainer = document.createElement('div');
        mainContainer.className = `
            container
            mx-auto
            px-4
            py-8
            mt-12
            rounded-2xl
            border
            border-white/10
            backdrop-blur-md
            bg-gradient-to-br
            from-purple-900/20
            via-blue-900/10
            to-purple-800/20
        `;

        const header = document.createElement('div');
        header.className = 'text-center mb-12';
        header.innerHTML = `
            <style>
                @keyframes gradientTitle {
                    0% { background-position: 0% 50% }
                    50% { background-position: 100% 50% }
                    100% { background-position: 0% 50% }
                }
                .gradient-title {
                    background: linear-gradient(
                        270deg,
                        #c084fc,
                        #818cf8,
                        #38bdf8,
                        #818cf8,
                        #c084fc
                    );
                    background-size: 300% 300%;
                    animation: gradientTitle 8s ease infinite;
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                    display: inline-block;
                }
            </style>
            <h1 class="text-3xl md:text-4xl font-bold mb-3 gradient-title">PLATO5 Features</h1>
            <p class="text-lg text-white/70 max-w-2xl mx-auto">
                Discover the innovative features that make PLATO5 the future of meaningful social connection.
            </p>
        `;
        mainContainer.appendChild(header);

        const featuresGrid = document.createElement('div');
        featuresGrid.className = 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6';

        this.getCurrentFeatures().forEach(feature => {
            featuresGrid.appendChild(this.createFeaturePanel(feature));
        });
        mainContainer.appendChild(featuresGrid);

        const comingSoonHeader = document.createElement('div');
        comingSoonHeader.className = 'text-center my-16';
        comingSoonHeader.innerHTML = `
            <style>
                @keyframes gradientComingSoon {
                    0% { background-position: 100% 0% }
                    50% { background-position: 0% 100% }
                    100% { background-position: 100% 0% }
                }
                .gradient-coming-soon {
                    background: linear-gradient(
                        -45deg,
                        #ffd700,
                        #daa520,
                        #cd7f32,
                        #b8860b,
                        #ffd700
                    );
                    background-size: 300% 300%;
                    animation: gradientComingSoon 10s ease infinite;
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                    display: inline-block;
                    text-shadow: 0 0 30px rgba(255, 215, 0, 0.1);
                }
            </style>
            <h2 class="text-2xl md:text-3xl font-bold mb-3 gradient-coming-soon">Coming Soon</h2>
            <p class="text-lg text-white/70 max-w-2xl mx-auto">
                Exciting new features on the horizon. Stay tuned!
            </p>
        `;
        mainContainer.appendChild(comingSoonHeader);

        const comingSoonGrid = document.createElement('div');
        comingSoonGrid.className = 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6';

        this.getComingSoonFeatures().forEach(feature => {
            comingSoonGrid.appendChild(this.createFeaturePanel(feature, true));
        });
        mainContainer.appendChild(comingSoonGrid);

        this.element.appendChild(mainContainer);

        this.articleRolodex.mount(this.element);
    }

    private createFeaturePanel(feature: Feature, isComingSoon: boolean = false): HTMLElement {
        const panel = document.createElement('div');
        panel.className = `
            group
            p-4
            rounded-xl
            border
            border-white/10
            backdrop-blur-md
            transition-all
            duration-300
            hover:border-white/20
            hover:transform
            hover:scale-[1.01]
            ${isComingSoon 
                ? 'bg-gradient-to-br from-purple-900/20 via-blue-800/10 to-purple-800/10' 
                : 'bg-gradient-to-br from-blue-500/10 via-purple-500/10 to-blue-400/5'}
        `;

        panel.innerHTML = `
            <div class="text-3xl mb-3 transition-transform duration-300 group-hover:scale-105 group-hover:transform">${feature.icon}</div>
            <h3 class="text-xl font-bold mb-2 text-white/90">${feature.title}</h3>
            <p class="text-sm text-white/70 leading-relaxed">${feature.description}</p>
        `;

        return panel;
    }

    private getCurrentFeatures(): Feature[] {
        return [
            {
                title: "PlatoChat",
                description: "Experience the heart of PLATO5 with our innovative PlatoChat microservice. This dynamic platform empowers users to engage in meaningful conversations within nanos - our unique four-person chat groups. PlatoChat isn't just about talking; it's about fostering deep, engaging discussions that spark connections and broaden horizons. Dive into a world where every conversation has the potential to change your perspective!",
                icon: "💬"
              },
              {
                title: "PIMS (Personality Interest Match System)",
                description: "Meet PIMS, your personal matchmaker in the PLATO5 universe! Our sophisticated Personality Interest Match System goes beyond surface-level connections. By analyzing personality traits and interests, PIMS creates platonic matches that resonate on a deeper level. Say goodbye to small talk and hello to conversations that truly matter. With PIMS, every new connection is an opportunity for growth and genuine friendship.",
                icon: "🧩"
              },
              {
                title: "Nanos/Nanoblogs",
                description: "Welcome to the future of social interaction - Nanos! These four-person micro-communities are the building blocks of PLATO5. But Nanos are more than just group chats; they're Attachment-Based Conversation hubs. Every message is encouraged to be a thoughtful reaction to a discussion, event, icebreaker, or another chat. This innovative approach provides an endless wellspring of engaging topics, ensuring your conversations are always fresh, relevant, and meaningful. Dive into Nanos and experience the joy of never running out of things to talk about!",
                icon: "🔬"
              },
              {
                title: "Gamified Conversation",
                description: "Turn chatting into an exciting adventure with our Gamified Conversation system! Earn points for engaging in diverse activities across the app, from starting thought-provoking discussions to participating in community events. But here's the twist - use your hard-earned points to unlock new matches and expand your social circle. It's not just about scoring; it's about creating a well-rounded social experience that rewards curiosity and active participation. Are you ready to level up your conversations?",
                icon: "🎮"
              },
              {
                title: "Kommin",
                description: "Dive into Kommin, our vibrant discussion board that's more than meets the eye! This bustling hub of ideas and debates isn't just for sharing thoughts - it's a sophisticated system that tracks iTags (interest tags) to fine-tune our understanding of what truly captivates our users. Post your own discussions, rate others' contributions, or jump into existing debates. With the ability to sort by interests, you'll always find conversations that resonate with you. Kommin isn't just a forum; it's a living, breathing reflection of our community's passions!",
                icon: "🗣️"
              },
              {
                title: "Analytix",
                description: "Unlock the power of self-awareness with Analytix! This cutting-edge system doesn't just store your personality trait data - it brings it to life in captivating, interactive displays. Dive deep into your psychological profile, track your personal growth, and gain insights that can transform your relationships and self-understanding. With Analytix, your journey of self-discovery becomes a visually stunning and intellectually stimulating adventure. Are you ready to see yourself in a whole new light?",
                icon: "📊"
              },
              {
                title: "Preference Toggling",
                description: "Take control of your PLATO5 experience with our flexible Preference Toggling system! Want to expand your horizons? Disable all preferences for a completely random match and embrace the unexpected. Feeling specific? Fine-tune your search by toggling personality traits, location, and interests. This powerful feature puts you in the driver's seat of your social journey, allowing you to balance familiarity with novelty. Whether you're in the mood for like-minded individuals or looking to step out of your comfort zone, Preference Toggling ensures your PLATO5 experience is always tailored to your current mood and goals.",
                icon: "🎛️"
              },
              {
                title: "Commibs/Icebreakers",
                description: "Break the ice with style! Commibs (Community Icebreakers) are user-generated and community-rated conversation starters designed to ignite fascinating discussions. These clever prompts push conversations in unexpected and intriguing directions, ensuring that every interaction on PLATO5 is an opportunity for discovery. Vote on your favorites, create your own, and watch as your icebreaker becomes the spark for countless engaging conversations across the platform!",
                icon: "🧊"
              },
              {
                title: "The PLATO5 Newsletter",
                description: "Stay in the loop with the PLATO5 Newsletter - your window into the world of meaningful connections and innovative social technology! Whether you're fascinated by PLATO5 as a groundbreaking project, passionate about startups, or deeply invested in our mission to solve human loneliness, this newsletter delivers. Gain exclusive insights into our development process, read inspiring stories of connections made on the platform, and get expert tips on fostering genuine relationships in the digital age. The PLATO5 Newsletter isn't just an update - it's a community-driven exploration of what it means to truly connect in the modern world.",
                icon: "📰"
              }
            ];
    }

    private getComingSoonFeatures(): Feature[] {
        return [
            {
                title: "PLATO5 Nearby",
                description: "Transform your local experience! Create and discover exciting events happening right in your neighborhood. From community meetups to hidden gem adventures, PLATO5 Nearby helps you forge meaningful connections and explore your area like never before. Get ready to turn your community into your playground!",
                icon: "🗺️"
              },
              {
                title: "The Fifth Member: Steward (P5AI)",
                description: "Meet your new digital companion! Steward, our AI-powered conversation assistant, is here to elevate your PLATO5 journey. From suggesting topics to providing real-time information, Steward ensures your discussions are always engaging, informed, and flowing smoothly. Experience the future of social interaction with P5AI by your side!",
                icon: "🤖"
              },
              {
                title: "ID Verification",
                description: "Building trust, one verified profile at a time! Our optional identity verification system creates a safer, more transparent community. Enjoy peace of mind knowing you're connecting with real people who share your values. Stand out with a verified badge and foster genuine, trustworthy relationships on PLATO5.",
                icon: "🛡️"
              },
              {
                title: "PLATO Premier & PLATO Precision",
                description: "Unlock the full potential of PLATO5 with our premium subscriptions! PLATO Premier offers an enhanced user experience with exclusive features and unparalleled customization options. For event creators, PLATO Precision provides a powerful metrics and customization hub, allowing you to target specific users based on personality traits, interests, and locations. Elevate your PLATO5 experience and make every interaction count!",
                icon: "⭐"
              },
              {
                title: "Multinanos",
                description: "Expand your social circle with our new 8-person group chats! Multinanos bring together more minds, more perspectives, and more fun. Whether you're planning a group outing, brainstorming ideas, or just hanging out virtually, these expanded conversations create a vibrant, dynamic space for connection and collaboration.",
                icon: "👥"
              },
              {
                title: "College Mode",
                description: "Campus life, amplified! Our College Mode is tailor-made for students, offering features that make connecting and collaborating a breeze. Find study groups, organize campus events, or discover like-minded classmates with ease. Make the most of your college experience and build a network that lasts long after graduation!",
                icon: "🎓"
              },
              {
                title: "Romance Mode",
                description: "Discover meaningful connections in a safe, respectful environment. Our Romance Mode is designed to help you find that special someone who shares your values and interests. With thoughtful matching algorithms and icebreaker prompts, we're here to spark genuine conversations and potential love stories. Navigate the world of online dating with confidence and authenticity!",
                icon: "❤️"
              },
              {
                title: "Founder Mode",
                description: "Calling all visionaries and trailblazers! Founder Mode is your gateway to a vibrant ecosystem of entrepreneurs and innovators. Network with like-minded individuals, find potential co-founders, or get valuable feedback on your ideas. From brainstorming sessions to mentor matchups, we're here to fuel your entrepreneurial journey and help turn your vision into reality.",
                icon: "💼"
              },
              {
                title: "PLATO5 International",
                description: "The world is at your fingertips! PLATO5 International breaks down borders, allowing you to connect with people from every corner of the globe. Immerse yourself in diverse cultures, practice new languages, and gain global perspectives without leaving your home. Expand your horizons and become a true citizen of the world with PLATO5 International!",
                icon: "🌍"
              }
            ];
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }
}