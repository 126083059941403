export class ModalForm {
    private overlay: HTMLDivElement;
    private modal: HTMLDivElement;
    private isOpen = false;
    private closeHandlers: (() => void)[] = [];
    private voluntaryCloseHandlers: (() => void)[] = [];
  
    constructor() {
      // Create overlay
      this.overlay = document.createElement('div');
      this.overlay.className = `
        fixed
        inset-0
        bg-black/50
        backdrop-blur-sm
        opacity-0
        transition-opacity
        duration-300
        hidden
        z-[9998]
      `;
  
      // Create modal
      this.modal = document.createElement('div');
      this.modal.className = `
        fixed
        top-1/2
        left-1/2
        -translate-x-1/2
        -translate-y-1/2
        backdrop-blur-lg
        bg-white/10
        dark:bg-black/30
        rounded-xl
        border
        border-white/10
        shadow-2xl
        p-8
        scale-95
        opacity-0
        transition-all
        duration-300
        hidden
        w-[90%]
        md:w-auto
        max-h-[90vh]
        md:max-h-[80vh]
        overflow-y-auto
        overscroll-contain
        -webkit-overflow-scrolling: touch
        scrollbar-thin
        scrollbar-track-white/20 
        scrollbar-thumb-white/40
        hover:scrollbar-thumb-white/60
        z-[9999]
      `;

          // Add close button
    const closeButton = document.createElement('button');
    closeButton.innerHTML = '×';  // Using × character
    closeButton.className = `
        absolute
        top-2
        right-2
        text-red-500
        hover:text-red-400
        text-3xl
        w-8
        h-8
        flex
        items-center
        justify-center
        cursor-pointer
        transition-colors
        font-bold
    `;
      closeButton.addEventListener('click', () => this.handleVoluntaryClose());
      this.modal.appendChild(closeButton);
  
      // Close on overlay click
      this.overlay.addEventListener('click', () => this.handleVoluntaryClose());
    }
  
    public open() {
      this.ensureCloseButton();
      this.overlay.classList.remove('hidden');
      this.modal.classList.remove('hidden');
      setTimeout(() => {
        this.overlay.classList.remove('opacity-0');
        this.modal.classList.remove('opacity-0', 'scale-95');
      }, 10);
      this.isOpen = true;
    }
  
    public close() {
        this.overlay.classList.add('opacity-0');
        this.modal.classList.add('opacity-0', 'scale-95');
        this.modal.classList.remove('scale-100');
        setTimeout(() => {
          this.overlay.classList.add('hidden');
          this.modal.classList.add('hidden');
          this.clear(); // Clear content after animation
          // Execute all close handlers
          this.closeHandlers.forEach(handler => handler());
          this.closeHandlers = []; // Clear handlers after executing
        }, 300);
        this.isOpen = false;
      }

      public clear() {
        while (this.modal.firstChild) {
          this.modal.removeChild(this.modal.firstChild);
        }
      }
  
    public append(element: HTMLElement): void {
      this.modal.innerHTML = '';
      this.ensureCloseButton();
      
      // Create a wrapper div for the content
      const contentWrapper = document.createElement('div');
      contentWrapper.className = 'w-full'; // Ensure full width
      contentWrapper.appendChild(element);
      
      this.modal.appendChild(contentWrapper);
    }
  
    public mount(parent: HTMLElement) {
      parent.appendChild(this.overlay);
      parent.appendChild(this.modal);
      
    }

    private ensureCloseButton(): void {
        if (!this.modal.querySelector('.modal-close-btn')) {
            const closeButton = document.createElement('button');
            closeButton.innerHTML = '×';
            closeButton.className = `
                modal-close-btn
                absolute
                top-1
                right-1
                text-red-400/70
                hover:text-red-400
                text-xl
                w-6
                h-6
                flex
                items-center
                justify-center
                cursor-pointer
                transition-colors
                leading-none
            `;
            closeButton.addEventListener('click', () => this.handleVoluntaryClose());
            this.modal.appendChild(closeButton);
        }
    }

    public onClose(handler: () => void) {
        this.closeHandlers.push(handler);
    }

    public onVoluntaryClose(handler: () => void) {
        this.voluntaryCloseHandlers.push(handler);
    }

    private handleVoluntaryClose() {
        this.voluntaryCloseHandlers.forEach(handler => handler());
        this.voluntaryCloseHandlers = []; // Clear handlers after executing
        this.close();
    }

    public unmount(): void {
        if (this.overlay.parentElement) {
            this.overlay.parentElement.removeChild(this.overlay);
        }
        if (this.modal.parentElement) {
            this.modal.parentElement.removeChild(this.modal);
        }
    }
  }