import { UINotifications } from '../../utilities/errors';
import { DiscussionResponse, ITagResponse, CommentResponse } from './models';
import { AuthService } from '../auth/api-services';
import { PlatoChatService } from '../platochat/api-services';

const KOMMIN_API_URL = import.meta.env.VITE_KOMMIN_API_URL;
const API_KEY = import.meta.env.VITE_API_KEY;

export class KomminService {
    private static getHeaders(): Record<string, string> {
        const baseHeaders = {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY
        };

        const sessionData = AuthService.getSessionData();
        if (sessionData) {
            return {
                ...baseHeaders,
                'X-Session-ID': sessionData.session_id,
                'X-Public-Key': sessionData.public_key,
                'X-User-ID': sessionData.userId?.toString() || ''
            };
        }

        return baseHeaders;
    }

    static async getDiscussionsByRecency(offset: number): Promise<DiscussionResponse[] | null> {
        try {
            console.log('🔍 Fetching discussions by recency with offset:', offset);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussion-by-recency?offset=${offset}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const discussionsData = await response.json();
            console.log('📦 Discussions data received:', discussionsData);

            if (!response.ok) {
                throw new Error(discussionsData.error || 'Failed to fetch discussions');
            }

            UINotifications.hideGlobalLoadingIndication();
            return discussionsData.discussions;
        } catch (error) {
            console.error('💥 Error fetching discussions by recency:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getDiscussionsByUserId(userId: number): Promise<DiscussionResponse[] | null> {
        try {
            console.log('🔍 Fetching discussions by user ID:', userId);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussions-by-user-id?user_id=${userId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const discussionsData = await response.json();
            console.log('📦 Discussions data received:', discussionsData);

            if (!response.ok) {
                throw new Error(discussionsData.error || 'Failed to fetch discussions');
            }

            UINotifications.hideGlobalLoadingIndication();
            return discussionsData.discussions;
        } catch (error) {
            console.error('💥 Error fetching discussions by user ID:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getDefaultTags(): Promise<ITagResponse[] | null> {
        try {
            console.log('🔍 Fetching default tags');
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-default-tags`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const tagsData = await response.json();
            console.log('📦 Tags data received:', tagsData);

            if (!response.ok) {
                throw new Error(tagsData.error || 'Failed to fetch tags');
            }

            UINotifications.hideGlobalLoadingIndication();
            return tagsData.tags;
        } catch (error) {
            console.error('💥 Error fetching default tags:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getDiscussionsByITag(itag: string): Promise<DiscussionResponse[] | null> {
        try {
            console.log('🔍 Fetching discussions by iTag:', itag);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussion-by-itag?iTag=${itag}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const discussionsData = await response.json();
            console.log('📦 Discussions data received:', discussionsData);

            if (!response.ok) {
                throw new Error(discussionsData.error || 'Failed to fetch discussions');
            }

            UINotifications.hideGlobalLoadingIndication();
            return discussionsData.discussions;
        } catch (error) {
            console.error('💥 Error fetching discussions by iTag:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async createDiscussion(
        postBody: string,
        userId: number,
        author: string,
        iTags: ITagResponse[]
    ): Promise<number | null> {
        try {
            console.log('🔍 Creating a new discussion');
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/create-discussion`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    post_body: postBody,
                    user_id: userId,
                    author: author,
                    itags: iTags.map(iTag => ({
                        ID: iTag.ID,
                        CreatedAt: iTag.CreatedAt,
                        UpdatedAt: iTag.UpdatedAt,
                        DeletedAt: iTag.DeletedAt || null,
                        Tag: iTag.Tag,
                        DiscussionCount: iTag.DiscussionCount
                    }))
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            const responseData = await response.json();
            console.log('📦 Discussion creation response:', responseData);

            if (response.status === 201) {
                const discussionId = responseData['Generated Discussion']['ID'];
                console.log('Discussion created successfully with ID:', discussionId);
                UINotifications.hideGlobalLoadingIndication();
                PlatoChatService.addToBalance(userId, 1000);
                return discussionId;
            } else {
                throw new Error(responseData.error || 'Failed to create discussion');
            }
        } catch (error) {
            console.error('💥 Error creating discussion:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getDiscussionComments(discussionId: number): Promise<CommentResponse[] | null> {
        try {
            console.log('🔍 Fetching comments for discussion ID:', discussionId);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussion-comments?discussion_id=${discussionId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const commentsData = await response.json();
            console.log('📦 Comments data received:', commentsData);

            if (response.ok) {
                return commentsData.data.map((comment: any) => ({
                    ID: comment.ID,
                    DiscussionID: comment.DiscussionID,
                    Timestamp: comment.CreatedAt,
                    Author: comment.Author,
                    Text: comment.Text
                    
                }
            ));
            } else {
                throw new Error(commentsData.error || 'Failed to fetch comments');
            }
        } catch (error) {
            console.error('💥 Error fetching comments:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    static async getDiscussionById(discussionId: number): Promise<DiscussionResponse | null> {
        try {
            console.log('🔍 Fetching discussion by ID:', discussionId);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussion-by-id?discussion_id=${discussionId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const discussionData = await response.json();
            console.log('📦 Discussion data received:', discussionData);

            if (response.ok) {
                return {
                    ID: discussionData.discussion.ID,
                    UserID: discussionData.discussion.UserID,
                    Timestamp: discussionData.discussion.Timestamp,
                    Author: discussionData.discussion.Author,
                    Body: discussionData.discussion.Body,
                    AverageRating: discussionData.discussion.AverageRating,
                    InteractionCount: discussionData.discussion.InteractionCount,
                    CreatedAt: discussionData.discussion.CreatedAt,
                    UpdatedAt: discussionData.discussion.UpdatedAt,
                    DeletedAt: discussionData.discussion.DeletedAt || null,
                    Tags: discussionData.discussion.Tags || []
                };
            } else {
                throw new Error(discussionData.error || 'Failed to fetch discussion');
            }
        } catch (error) {
            console.error('💥 Error fetching discussion by ID:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    static async postRateDiscussion(userId: number, discussionId: number, rating: number): Promise<boolean> {
        try {
            console.log('🔍 Rating discussion ID:', discussionId);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/update-rating`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    user_id: userId,
                    rating: rating,
                    discussion_id: discussionId
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            if (response.ok) {
                console.log('Discussion rated successfully');
                UINotifications.hideGlobalLoadingIndication();
                PlatoChatService.addToBalance(userId, 25);
                return true;
            } else {
                throw new Error('Failed to rate discussion');
            }
        } catch (error) {
            console.error('💥 Error rating discussion:', error);
            UINotifications.hideGlobalLoadingIndication();
            return false;
        }
    }

    static async postRecordImpression(discussionId: number): Promise<boolean> {
        try {
            console.log('🔍 Recording impression for discussion ID:', discussionId);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/record-interaction`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    discussion_id: discussionId
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            if (response.ok) {
                console.log('Impression recorded successfully');
                UINotifications.hideGlobalLoadingIndication();
                return true;
            } else {
                throw new Error('Failed to record impression');
            }
        } catch (error) {
            console.error('💥 Error recording impression:', error);
            UINotifications.hideGlobalLoadingIndication();
            return false;
        }
    }

    static async postAddComment(userId: number, author: string, discussionId: number, text: string): Promise<CommentResponse | null> {
        try {
            console.log('🔍 Adding comment to discussion ID:', discussionId);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/create-comment`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    user_id: userId,
                    author: author,
                    discussion_id: discussionId,
                    text: text
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            const responseData = await response.json();
            console.log('📦 Comment creation response:', responseData);

            if (response.ok) {
                console.log('Comment added successfully');
                UINotifications.hideGlobalLoadingIndication();
                PlatoChatService.addToBalance(userId, 150);
                return {
                    ID: responseData.ID,
                    DiscussionID: responseData.DiscussionID,
                    Timestamp: responseData.Timestamp,
                    Author: responseData.Author,
                    Text: responseData.Text
                };
            } else {
                throw new Error(responseData.error || 'Failed to add comment');
            }
        } catch (error) {
            console.error('💥 Error adding comment:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getTags(offset: number): Promise<ITagResponse[] | null> {
        try {
            console.log('🔍 Fetching tags with offset:', offset);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-tags?offset=${offset}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const tagData = await response.json();
            console.log('📦 Tags data received:', tagData);

            if (response.ok) {
                return tagData.iTags.map((tag: any) => ({
                    Tag: tag.Tag
                }));
            } else {
                throw new Error('Failed to fetch tags');
            }
        } catch (error) {
            console.error('💥 Error fetching tags:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getTagByTagName(tagName: string): Promise<ITagResponse | null> {
        try {
            console.log('🔍 Fetching tag by name:', tagName);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-tag-by-tag-name?tag_name=${tagName}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const tagData = await response.json();
            console.log('📦 Tag data received:', tagData);

            if (response.ok) {
                return {
                    ID: tagData.iTag.ID,
                    CreatedAt: tagData.iTag.CreatedAt,
                    UpdatedAt: tagData.iTag.UpdatedAt,
                    DeletedAt: tagData.iTag.DeletedAt || null,
                    Tag: tagData.iTag.Tag,
                    DiscussionCount: tagData.iTag.DiscussionCount
                };
            } else {
                throw new Error('Failed to fetch tag by name');
            }
        } catch (error) {
            console.error('💥 Error fetching tag by name:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getTopiTagsForUserId(userId: number): Promise<string[] | null> {
        try {
            console.log('🔍 Fetching top iTags for user ID:', userId);
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-topi-tags-for-user-id?user_id=${userId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const data = await response.json();
            console.log('📦 Top iTags data received:', data);

            if (!response.ok) {
                throw new Error(data.error || 'Failed to fetch top iTags');
            }

            UINotifications.hideGlobalLoadingIndication();
            return data.topTags.map((tag: ITagResponse) => tag.Tag);
        } catch (error) {
            console.error('💥 Error fetching top iTags:', error);
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }
} 