import { Button } from '../reusable/button';
import { CreateDiscussionContent } from '../reusable/create-discussion-content';
import { ModalForm } from '../reusable/modal-form';

export class KomminFooter {
    private element: HTMLElement;
    private modalForm: ModalForm;

    constructor(modalForm: ModalForm) {
        this.modalForm = modalForm;
        this.element = document.createElement('div');
        this.element.className = `
            w-full 
            px-6 
            py-4
            fixed
            bottom-0
            left-0
            flex 
            justify-end 
            items-center 
            bg-gradient-to-t from-black/50
            backdrop-blur-sm
        `;
        
        // Create button container for alignment
        const buttonContainer = document.createElement('div');
        buttonContainer.className = 'flex items-center justify-end w-full max-w-[200px] mr-5';
        
        // Add Create Discussion button
        const startDiscussionButton = new Button('Start a Discussion');
        startDiscussionButton.getElement().className = `
            bg-orange-500/20 
            hover:bg-orange-500/30 
            text-white 
            px-4 
            py-2 
            rounded-xl
            border 
            border-white/10 
            flex 
            items-center 
            gap-2 
            transition-all
            duration-300
            text-sm 
            justify-center
            backdrop-blur-sm
            hover:shadow-[0_0_30px_rgba(249,115,22,0.1)]
            hover:border-orange-500/30
        `;
        startDiscussionButton.getElement().innerHTML = `
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M12 4v16m8-8H4"></path>
            </svg>
            Start a Discussion
        `;
        
        startDiscussionButton.onClick(async () => {
            const createDiscussionContent = new CreateDiscussionContent();
            const contentElement = await createDiscussionContent.createDiscussionForm();
            this.modalForm.clear();
            this.modalForm.append(contentElement);
            this.modalForm.open();
        });

        // Append button to container and container to element
        buttonContainer.appendChild(startDiscussionButton.getElement());
        this.element.appendChild(buttonContainer);
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }
}