export class ColorPicker {
    private element: HTMLElement;
    private onSelect: (color: string) => void;

    constructor(onSelect: (color: string) => void) {
        this.element = document.createElement('div');
        this.onSelect = onSelect;
        this.render();
    }

    private render() {
        this.element.innerHTML = `
            <div class="p-6">
                <h3 class="text-lg font-bold mb-4 text-white">Choose a Color</h3>
                <div class="flex flex-col gap-4">
                    <input 
                        type="color" 
                        class="w-full h-12 cursor-pointer rounded-xl"
                        value="#0088ff"
                    >
                    <button 
                        class="w-full p-3 text-white bg-white/10 hover:bg-white/20 
                        rounded-xl transition-colors font-medium"
                    >
                        Change Color
                    </button>
                </div>
            </div>
        `;

        const input = this.element.querySelector('input');
        const button = this.element.querySelector('button');

        if (input && button) {
            button.addEventListener('click', () => {
                const color = (input as HTMLInputElement).value.replace('#', '');
                this.onSelect('ff' + color);
            });
        }
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}