import { KomminService } from '../../actions/kommin/api-services';
import { UINotifications } from '../../utilities/errors';
import { TextInput } from './text-input';
import { Button } from './button';
import { ITagResponse } from '../../actions/kommin/models';
import { AuthService } from '../../actions/auth/api-services';
import { navigate } from '../../app-router';
import { Hash } from '../../utilities/hash';

export class CreateDiscussionContent {
    private selectedITags: string[] = [];
    private maxITags = 5;

    public async createDiscussionForm(): Promise<HTMLElement> {
        const formContent = document.createElement('div');
        const userId = localStorage.getItem('userId');

        if (!userId) {
            formContent.innerHTML = `
                <div class="flex flex-col items-center justify-center p-8 text-center">
                    <div class="w-16 h-16 mb-6 rounded-full bg-blue-500/20 
                        flex items-center justify-center">
                        <i class="fas fa-lock text-2xl text-blue-400"></i>
                    </div>
                    <h2 class="text-xl font-semibold text-white mb-3">
                        Login or Signup to Start a Conversation
                    </h2>
                    <p class="text-white/60 max-w-md">
                        Join our community to create discussions and connect with others.
                    </p>
                </div>
            `;
            return formContent;
        }

        formContent.innerHTML = `
            <div>
                <h2 class="text-white text-xl font-semibold mb-4">Create a Discussion</h2>
                <textarea id="discussion-body" class="w-full p-2 border rounded mb-4 h-32 text-white bg-transparent" placeholder="Write your discussion..."></textarea>
                <p class="text-sm text-white mb-2">
                    An iTag is an interest tag that helps people find your discussion and identify it. You can add 1-5 iTags. (Spaces are automatically removed)
                </p>
                <div class="flex items-center gap-2 mb-4">
                    <div class="flex-grow" id="custom-itag-input"></div>
                    <button id="add-custom-itag" class="bg-blue-500 text-white rounded px-2 py-1">Add</button>
                </div>
                <div id="default-itags" class="flex flex-wrap gap-2 mb-4">
                    <!-- Default iTags will be inserted here -->
                </div>
                <div id="search-results" class="flex flex-wrap gap-2 mb-4">
                    <!-- Search results will be inserted here -->
                </div>
                <div id="selected-itags" class="flex flex-wrap gap-2 mb-4">
                    <!-- Selected iTags will be inserted here -->
                </div>
            </div>
        `;

        const createDiscussionButton = new Button('Create');
        createDiscussionButton.getElement().className += ' mt-4 bg-green-500 text-white rounded px-4 py-2';
        createDiscussionButton.mount(formContent);

        const defaultITagsContainer = formContent.querySelector('#default-itags') as HTMLElement;
        const addCustomITagButton = formContent.querySelector('#add-custom-itag') as HTMLElement;

        // Fetch and display default iTags
        const defaultITags = await this.getDefaultTags();
        this.displayITagResults(defaultITagsContainer, defaultITags);

        const customITagInput = new TextInput('Custom iTags...');
        const inputElement = customITagInput.getElement().querySelector('input');
        if (inputElement) {
            inputElement.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.key === ' ' || e.keyCode === 32) {
                    e.preventDefault();
                }
            });
            
            inputElement.addEventListener('change', (e: Event) => {
                const target = e.target as HTMLInputElement;
                target.value = target.value.replace(/\s/g, "");
            });
        }
        formContent.querySelector('#custom-itag-input')?.appendChild(customITagInput.getElement());

        addCustomITagButton.addEventListener('click', () => {
            const customTag = customITagInput.getValue().trim().replace(/\s+/g, '');
            if (customTag && !this.selectedITags.includes(customTag)) {
                if (this.selectedITags.length < this.maxITags) {
                    this.selectedITags.push(customTag);
                    this.updateSelectedITagsDisplay(formContent);
                    customITagInput.clear();
                    // Scroll to selected tags
                    const selectedTagsSection = formContent.querySelector('#selected-itags');
                    selectedTagsSection?.scrollIntoView({ behavior: 'smooth' });
                } else {
                    UINotifications.shipHintToUI("You've hit the maximum of 5 iTags");
                }
            }
        });

        createDiscussionButton.onClick(async () => {
            const bodyInput = formContent.querySelector<HTMLTextAreaElement>('#discussion-body');
            const body = bodyInput?.value.trim();
            
            if (!body || this.selectedITags.length === 0) {
                UINotifications.shipHintToUI("Please enter a discussion body and select at least one iTag.");
                return;
            }

            try {
                // Get userId from localStorage
                const userId = localStorage.getItem('userId');
                if (!userId) {
                    UINotifications.shipErrorToUI("You must be logged in to create a discussion");
                    return;
                }

                // Get user data to get the name
                const userData = await AuthService.getUserById(parseInt(userId));
                if (!userData) {
                    UINotifications.shipErrorToUI("Could not fetch user data");
                    return;
                }

                // Get iTags
                const iTags: ITagResponse[] = await Promise.all(this.selectedITags.map(async tag => {
                    const itagResponse = await KomminService.getTagByTagName(tag);
                    if (!itagResponse) {
                        throw new Error(`Tag not found: ${tag}`);
                    }
                    return itagResponse;
                }));

                // Create discussion with user's name as author
                const discussionId = await KomminService.createDiscussion(
                    body, 
                    parseInt(userId), 
                    userData.name, 
                    iTags
                );

                UINotifications.shipSuccessToUI("Successfully Created Discussion");
                if (discussionId) {
                    const hashedId = Hash.encodeId(discussionId);
                    navigate(`/kommin/discussion/${hashedId}`);
                }
            } catch (error) {
                console.error('Error creating discussion:', error);
                UINotifications.shipErrorToUI("Failed to create discussion");
            }
        });

        return formContent;
    }

    private async getDefaultTags(): Promise<string[]> {
        try {
            const tags: ITagResponse[] | null = await KomminService.getDefaultTags();
            return tags ? tags.map(tag => tag.Tag) : [];
        } catch (error) {
            console.error('Error fetching default tags:', error);
            return [];
        }
    }

    private displayITagResults(container: HTMLElement | null, itags: string[]) {
        if (container) {
            container.innerHTML = '';
            itags.forEach(itag => {
                const itagElement = document.createElement('button');
                itagElement.className = 'bg-gray-200 text-gray-800 px-2 py-1 rounded';
                itagElement.textContent = itag;
                itagElement.addEventListener('click', () => {
                    if (this.selectedITags.length < this.maxITags && !this.selectedITags.includes(itag)) {
                        this.selectedITags.push(itag);
                        this.updateSelectedITagsDisplay(container.parentElement);
                        // Scroll to selected tags
                        const selectedTagsSection = container.parentElement?.querySelector('#selected-itags');
                        selectedTagsSection?.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        UINotifications.shipHintToUI("You've hit the maximum of 5 iTags or the iTag is already selected");
                    }
                });
                container.appendChild(itagElement);
            });
        }
    }

    private updateSelectedITagsDisplay(parent: HTMLElement | null) {
        const itagsContainer = parent?.querySelector('#selected-itags');
        if (itagsContainer) {
            itagsContainer.innerHTML = '';
            this.selectedITags.forEach(tag => {
                const tagElement = document.createElement('span');
                tagElement.className = 'bg-blue-500 text-white px-2 py-1 rounded';
                tagElement.textContent = tag;
                itagsContainer.appendChild(tagElement);
            });
        }
    }
} 