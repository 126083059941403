/**
 * Utility functions for sanitizing user input
 */

type SanitizeType = 'string' | 'email' | 'username' | 'password';

// For collecting input - minimal processing
export function sanitize(
    input: string | number,
    type: SanitizeType,
): string | number {
    if (input === undefined || input === null) return '';
    
    switch (type) {
        case 'email':
            return String(input).toLowerCase().trim();
        case 'password':
            return String(input);
        default:
            return String(input).trim();
    }
}

// For rendering user-generated content to prevent XSS
export function sanitizeHTML(input: string): string {
    return String(input)
        .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '') // Remove script tags
        .replace(/on\w+="[^"]*"/g, '') // Remove event handlers
        .replace(/javascript:[^"']*/g, ''); // Remove javascript: protocols
}
