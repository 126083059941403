export class AppletSelector {
    private element: HTMLElement;
    private isHidden = false;
    private container: HTMLElement;
    private collapseButtonText: HTMLSpanElement;
    private collapseButtonIcon: SVGElement;
    private collapseButton: HTMLButtonElement;
    private currentApplet: string;
    private onAppletChange?: (applet: string) => void;

    constructor() {
        // Retrieve the chosen applet from local storage or default to 'profile'
        const savedApplet = localStorage.getItem('chosenApplet') || 'profile';
        const validApplets = ['kommin', 'profile', 'nearby', 'platochat', 'settings'];
        this.currentApplet = validApplets.includes(savedApplet) ? savedApplet : 'profile';

        this.element = document.createElement('div');
        this.element.className = 'fixed bottom-2 mx-2 left-0 right-0 md:bottom-6 md:left-1/2 md:-translate-x-1/2 md:w-fit opacity-0 translate-y-full transition-all duration-300 ease-out z-50';
        this.element.style.maxWidth = 'calc(100% - 2rem)';
        
        this.container = document.createElement('div');
        this.container.className = 'relative bg-black/30 backdrop-blur-lg px-1 md:px-6 py-1.5 md:py-4 rounded-xl border border-white/10 shadow-lg transition-all duration-300';
        
        this.collapseButton = document.createElement('button');
        this.collapseButton.className = 'absolute -top-8 right-2 px-2 py-1 bg-black/30 backdrop-blur-lg rounded-t-lg border border-white/10 border-b-0 text-[10px] text-white/70 hover:text-white/90 transition-all duration-300';
        
        this.collapseButtonText = document.createElement('span');
        this.collapseButtonText.textContent = 'Collapse';
        
        this.collapseButtonIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        this.collapseButtonIcon.setAttribute('class', 'w-4 h-4 transition-transform duration-300');
        this.collapseButtonIcon.setAttribute('fill', 'none');
        this.collapseButtonIcon.setAttribute('stroke', 'currentColor');
        this.collapseButtonIcon.setAttribute('viewBox', '0 0 24 24');
        this.collapseButtonIcon.innerHTML = `
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        `;

        const buttonContent = document.createElement('div');
        buttonContent.className = 'flex items-center gap-2';
        buttonContent.appendChild(this.collapseButtonText);
        buttonContent.appendChild(this.collapseButtonIcon);
        
        this.collapseButton.appendChild(buttonContent);
        this.collapseButton.addEventListener('click', () => this.toggleVisibility());

        const buttonsContainer = document.createElement('div');
        buttonsContainer.className = 'flex justify-center gap-1 md:gap-6 items-center';
        
        buttonsContainer.innerHTML = `
            <button data-applet="platochat" class="group flex flex-col items-center gap-0.5 md:gap-1">
                <div class="w-7 h-7 md:w-10 md:h-10 flex items-center justify-center rounded-lg bg-blue-500/10 group-hover:bg-blue-500/20 transition-all">
                    <svg class="w-3.5 h-3.5 md:w-5 md:h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
                    </svg>
                </div>
                <span class="text-[7px] md:text-[10px] text-blue-400">PlatoChat</span>
            </button>

            <button data-applet="kommin" class="group flex flex-col items-center gap-0.5 md:gap-1">
                <div class="w-7 h-7 md:w-10 md:h-10 flex items-center justify-center rounded-lg bg-orange-500/10 group-hover:bg-orange-500/20 transition-all">
                    <span class="text-base md:text-xl font-bold text-orange-400">K</span>
                </div>
                <span class="text-[7px] md:text-[10px] text-orange-400">Kommin</span>
            </button>

            <button data-applet="nearby" class="group flex flex-col items-center gap-0.5 md:gap-1">
                <div class="w-7 h-7 md:w-10 md:h-10 flex items-center justify-center rounded-lg bg-purple-500/10 group-hover:bg-purple-500/20 transition-all">
                    <svg class="w-3.5 h-3.5 md:w-5 md:h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                    </svg>
                </div>
                <span class="text-[7px] md:text-[10px] text-purple-400">Nearby</span>
            </button>

            <button data-applet="profile" class="group flex flex-col items-center gap-0.5 md:gap-1">
                <div class="w-7 h-7 md:w-10 md:h-10 flex items-center justify-center rounded-lg bg-yellow-500/10 group-hover:bg-yellow-500/20 transition-all">
                    <svg class="w-3.5 h-3.5 md:w-5 md:h-5 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                    </svg>
                </div>
                <span class="text-[7px] md:text-[10px] text-yellow-400">Profile</span>
            </button>

            <button data-applet="settings" class="group flex flex-col items-center gap-0.5 md:gap-1">
                <div class="w-7 h-7 md:w-10 md:h-10 flex items-center justify-center rounded-lg bg-gray-500/10 group-hover:bg-gray-500/20 transition-all">
                    <svg class="w-3.5 h-3.5 md:w-5 md:h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                </div>
                <span class="text-[7px] md:text-[10px] text-gray-400">Settings</span>
            </button>
        `;

        buttonsContainer.querySelectorAll('button').forEach(button => {
            button.addEventListener('click', () => {
                const applet = button.getAttribute('data-applet');
                if (this.onAppletChange && applet) {
                    this.onAppletChange(applet);
                }
                if (applet) {
                    this.highlightSelectedApplet(applet);
                }
            });
        });

        this.container.appendChild(buttonsContainer);
        this.element.appendChild(this.collapseButton);
        this.element.appendChild(this.container);

        this.setupScrollBehavior();

        this.highlightSelectedApplet(this.currentApplet);
    }

    private setupScrollBehavior() {
        const handleScroll = () => {
            const scrolledToBottom = 
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 10;

            if (window.scrollY > 200) {  // Show after scrolling down
                if (scrolledToBottom) {
                    // Move up when at bottom to avoid footer
                    this.element.classList.remove('bottom-2', 'md:bottom-6');
                    this.element.classList.add('bottom-16', 'md:bottom-20');
                } else {
                    // Normal position
                    this.element.classList.add('bottom-2', 'md:bottom-6');
                    this.element.classList.remove('bottom-16', 'md:bottom-20');
                }
                this.element.classList.remove('opacity-0', 'translate-y-full');
                this.element.classList.add('opacity-100', 'translate-y-0');
            } else {
                // Hide when at top
                this.element.classList.add('opacity-0', 'translate-y-full');
                this.element.classList.remove('opacity-100', 'translate-y-0');
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        // Initial check
        handleScroll();
    }

    private toggleVisibility() {
        this.isHidden = !this.isHidden;
        if (this.isHidden) {
            this.container.classList.add('scale-y-0', 'origin-bottom', 'h-0');
            this.container.classList.remove('scale-y-100');
            this.collapseButtonText.textContent = 'Reveal';
            this.collapseButtonIcon.style.transform = 'rotate(180deg)';
            this.collapseButton.classList.remove('-top-8');
            this.collapseButton.classList.add('top-0');
            this.collapseButton.style.transform = 'translateY(-100%)';
        } else {
            this.container.classList.remove('scale-y-0', 'h-0');
            this.container.classList.add('scale-y-100');
            this.collapseButtonText.textContent = 'Collapse';
            this.collapseButtonIcon.style.transform = 'rotate(0)';
            this.collapseButton.classList.add('-top-8');
            this.collapseButton.classList.remove('top-0');
            this.collapseButton.style.transform = '';
        }
    }

    private switchApplet(appletId: string) {
        this.currentApplet = appletId;
        if (this.onAppletChange) {
            this.onAppletChange(appletId);
        }
    }

    private highlightSelectedApplet(appletId: string) {
        const buttons = this.container.querySelectorAll('button');
        buttons.forEach(button => {
            const buttonAppletId = button.getAttribute('data-applet');
            if (buttonAppletId === appletId) {
                const colorClass = this.getAppletColorClass(appletId);
                button.classList.add('animate-bounce');
                button.classList.remove('border-gray-500/20');
                button.classList.add(colorClass);
            } else {
                button.classList.remove('animate-bounce', 'border-blue-400', 'border-orange-400', 'border-purple-400', 'border-yellow-400', 'border-gray-400');
                button.classList.add('border-gray-500/20');
            }
        });
    }

    private getAppletColorClass(appletId: string): string {
        switch (appletId) {
            case 'platochat':
                return 'border-blue-400';
            case 'kommin':
                return 'border-orange-400';
            case 'nearby':
                return 'border-purple-400';
            case 'profile':
                return 'border-yellow-400';
            case 'settings':
                return 'border-gray-400';
            default:
                return '';
        }
    }

    public onSelect(callback: (applet: string) => void) {
        this.onAppletChange = callback;
    }

    mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }
}