import '../../styles/tutorial.css'
import { articles } from './5mark-articles';
import { navigate } from '../../app-router';
import { build5MarkExcerpt } from '../../utilities/5mark-parser';


interface Article {
    route: string;
    title: string;
    author: string;
    position: string;
    publishDate: string;
    fiveMark: string;
  }
  
  export class ArticleContent {
    private container: HTMLDivElement;
    private articles: Article[];
    private currentIndex: number = 0;
  
    constructor() {
      this.container = document.createElement('div');
      this.container.className = 'article-container';
      this.articles = articles;
      
      this.initializeUI();
    }
  
    private initializeUI(): void {
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'content-wrapper';
      
        const articlesWrapper = document.createElement('div');
        articlesWrapper.className = 'articles-wrapper';
      
        const navigationWrapper = document.createElement('div');
        navigationWrapper.className = 'navigation-wrapper';
      
        // Navigation buttons
        const prevButton = document.createElement('button');
        prevButton.textContent = '←';
        prevButton.className = 'nav-button prev';
        prevButton.onclick = () => this.navigate(-1);
      
        const nextButton = document.createElement('button');
        nextButton.textContent = '→';
        nextButton.className = 'nav-button next';
        nextButton.onclick = () => this.navigate(1);
      
        this.renderCurrentArticle(articlesWrapper);
      
        navigationWrapper.appendChild(prevButton);
        navigationWrapper.appendChild(nextButton);
        contentWrapper.appendChild(articlesWrapper);
        
        this.container.appendChild(navigationWrapper);
        this.container.appendChild(contentWrapper);
      }
  
    private renderCurrentArticle(wrapper: HTMLDivElement): void {
      wrapper.innerHTML = '';
      const article = this.articles[this.currentIndex];
  
      const articleElement = document.createElement('div');
      articleElement.className = 'article';
  
      const title = document.createElement('h2');
      title.textContent = article.title;
  
      const metadata = document.createElement('div');
      metadata.className = 'metadata';
      metadata.innerHTML = `
        <span class="author">${article.author}</span>
        <span class="position">${article.position}</span>
        <span class="date">${article.publishDate}</span>
      `;
  
      const body = document.createElement('p');
      const excerpt = build5MarkExcerpt(article.fiveMark, 150);
      body.textContent = excerpt;
      
      const readMoreButton = document.createElement('button');
      readMoreButton.className = 'read-more';
      readMoreButton.textContent = 'Read More';
      readMoreButton.onclick = () => {
        navigate(article.route);
      };
  
      articleElement.appendChild(title);
      articleElement.appendChild(metadata);
      articleElement.appendChild(body);
      articleElement.appendChild(readMoreButton);
      wrapper.appendChild(articleElement);
    }

    public getElement(): HTMLElement {
        return this.container;
    }
  
    private navigate(direction: number): void {
      this.currentIndex = (this.currentIndex + direction + this.articles.length) % this.articles.length;
      this.renderCurrentArticle(this.container.querySelector('.articles-wrapper')!);
    }
}