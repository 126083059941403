import { initializeRouter } from './app-router';
import { UINotifications } from './utilities/errors';
import { bounceToTop } from './app-router';
import { AuthService } from './actions/auth/api-services';

async function main() {
  console.log("Starting app initialization");

  // Initialize app first
  initializeRouter();
  bounceToTop();

  // Mount initial app container
  const appContainer = document.querySelector('#app');
  if (!appContainer) {
    throw new Error('App container not found');
  }

  // Check for refresh messages
  UINotifications.checkForRefreshMessage();

  // Validate session if user ID exists
  const userId = localStorage.getItem('userId');
  if (userId) {
    console.log("Found user ID, validating session...");
    const isValid = await AuthService.validate();
    if (!isValid) {
      console.log("Session invalid, clearing user ID");
      localStorage.clear(); // Clear all localStorage
      document.cookie.split(";").forEach(cookie => {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      window.location.reload();
      return;
    }
  }

  console.log("App initialization complete");
}

// Start the application when DOM is ready
document.addEventListener('DOMContentLoaded', main);