export class Spacer {
    mount(parent: HTMLElement) {
        const spacer = document.createElement('div')
        spacer.className = 'section-divider'
        
        const line = document.createElement('div')
        line.className = 'divider-line'
        
        spacer.appendChild(line)
        parent.appendChild(spacer)
    }
}