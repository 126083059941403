import { AnalytixService } from '../../../actions/analytix/api-services';
import { UserData } from '../../../actions/auth/models';

type AnalytixResult = {
    userId: number;
    analytixId: number;
    userName: string;
    scores: {
        category: string;
        score: number;
    }[];
};

export class AnalytixSelectionContent {
    private element: HTMLElement;
    private users: UserData[];
    private onSelect: (result: AnalytixResult) => void;

    constructor(users: UserData[], onSelect: (result: AnalytixResult) => void) {
        this.users = users;
        this.onSelect = onSelect;
        this.element = document.createElement('div');
        this.element.className = 'flex flex-col h-[80vh] min-w-[300px]';
        this.render();
    }

    private async render() {
        // Header
        const header = document.createElement('div');
        header.className = 'flex-shrink-0 mb-6';
        header.innerHTML = `
            <h2 class="text-xl font-bold text-white/90 mb-2">Share Personality Profile</h2>
            <p class="text-white/70">Select a user to share their personality profile:</p>
        `;
        this.element.appendChild(header);

        // User Selection
        const userSelect = document.createElement('select');
        userSelect.className = `
            w-full px-4 py-3 mb-6 rounded-xl
            bg-white/5 border border-white/10
            text-white focus:border-white/20 focus:outline-none
        `;
        userSelect.innerHTML = `
            <option value="" disabled selected>Select a user...</option>
            ${this.users.map(user => `
                <option value="${user.id}">${user.name}</option>
            `).join('')}
        `;
        this.element.appendChild(userSelect);

        // Results Container
        const resultsContainer = document.createElement('div');
        resultsContainer.className = 'flex-1 overflow-y-auto';
        this.element.appendChild(resultsContainer);

        // Event Listener
        userSelect.addEventListener('change', async (e) => {
            const userId = Number((e.target as HTMLSelectElement).value);
            const userName = this.users.find(u => u.id === userId)?.name || '';
            resultsContainer.innerHTML = `
                <div class="animate-pulse text-white/60 text-center">
                    Loading personality profile...
                </div>
            `;

            try {
                const scores = await AnalytixService.getAnalytixByUserId(userId);
                if (scores) {
                    const result: AnalytixResult = {
                        userId,
                        analytixId: scores.analytixId,
                        userName,
                        scores: [
                            { category: 'O', score: scores.tOpenness },
                            { category: 'C', score: scores.tConscientiousness },
                            { category: 'E', score: scores.tExtraversion },
                            { category: 'A', score: scores.tAgreeableness },
                            { category: 'N', score: scores.tNeuroticism }
                        ].filter(score => typeof score.score === 'number')
                    };
                    
                    if (result.scores.length > 0) {
                        this.renderChart(resultsContainer, result);
                        
                        // Add share button
                        const shareButton = document.createElement('button');
                        shareButton.className = `
                            w-full px-4 py-3 mt-6 rounded-xl
                            bg-purple-500 text-white
                            hover:bg-purple-600 transition-colors
                        `;
                        shareButton.textContent = 'Share Profile';
                        shareButton.onclick = () => this.onSelect(result);
                        resultsContainer.appendChild(shareButton);
                    } else {
                        resultsContainer.innerHTML = `
                            <div class="text-white/60 text-center">
                                No personality profile available for this user
                            </div>
                        `;
                    }
                }
            } catch (error) {
                console.error('Failed to load profile:', error);
                resultsContainer.innerHTML = `
                    <div class="text-red-400 text-center">
                        Failed to load personality profile
                    </div>
                `;
            }
        });
    }

    private renderChart(container: HTMLElement, result: AnalytixResult) {
        const barContainer = document.createElement('div');
        barContainer.className = 'relative h-60 md:h-80 min-h-[200px] flex items-end gap-[2px] mb-8';

        result.scores.forEach(({ category, score }) => {
            const barWrapper = document.createElement('div');
            barWrapper.className = 'relative flex flex-col items-center gap-2 h-full flex-1';

            const bar = document.createElement('div');
            const height = Math.max(5, score);
            
            // Use the correct color for each trait
            const gradientClass = category === 'O' ? 'from-purple-500 to-pink-500' :
                                category === 'C' ? 'from-orange-500 to-yellow-300' :
                                category === 'E' ? 'from-blue-500 to-cyan-300' :
                                category === 'A' ? 'from-green-500 to-emerald-300' :
                                'from-red-500 to-rose-300';  // N

            bar.className = `absolute bottom-0 w-full bg-gradient-to-t ${gradientClass} transition-all duration-500`;
            bar.style.height = `${height}%`;

            const label = document.createElement('div');
            label.className = 'absolute bottom-0 transform translate-y-full mt-2 text-white/70 text-xs md:text-sm text-center flex flex-col items-center gap-1';
            label.innerHTML = `
                <span>${category}</span>
                <span class="text-white/90 font-medium">${score.toFixed(1)}%</span>
            `;

            barWrapper.appendChild(bar);
            barWrapper.appendChild(label);
            barContainer.appendChild(barWrapper);
        });

        container.innerHTML = '';
        container.appendChild(barContainer);
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}