export class GlassmorphismBox {
    private element: HTMLDivElement;
  
    constructor() {
      this.element = document.createElement('div');
      this.element.className = 'backdrop-blur-xl bg-white/5 dark:bg-black/10 rounded-2xl border border-white/20 shadow-2xl p-8 transition-all duration-500 ease-in-out hover:bg-white/10 dark:hover:bg-black/20 hover:shadow-3xl hover:scale-[1.02] hover:border-white/30';
    }
  
    public append(child: HTMLElement) {
      this.element.appendChild(child);
    }
  
    public mount(parent: HTMLElement) {
      parent.appendChild(this.element);
    }
  
    public getElement(): HTMLElement {
        return this.element;
    }
  }