import { AuthService } from "../../actions/auth/api-services";
import { PlatoChatService } from "../../actions/platochat/api-services";

export class GamificationReminder {
    private element: HTMLElement;
    private userId: number;
    private walletBalance: number = 0;
    private gamificationEnabled: boolean = true;

    constructor(userId: number) {
        this.userId = userId;
        this.element = document.createElement('div');
        this.initialize();
    }

    private async initialize() {
        await this.loadWalletBalance();
        this.render();
    }

    private async loadWalletBalance() {
        const userData = await AuthService.getUserById(this.userId);
        this.gamificationEnabled = userData?.gamification ?? true;

        if (this.gamificationEnabled) {
            const balance = await PlatoChatService.getWalletBalance(this.userId);
            if (balance === null) {
                await PlatoChatService.createWallet(this.userId);
                const newBalance = await PlatoChatService.getWalletBalance(this.userId);
                if (newBalance !== null) {
                    this.walletBalance = newBalance;
                }
            } else {
                this.walletBalance = balance;
            }
        }
    }

    private render() {
        this.element.className = 'inline-flex items-center gap-2 px-3 py-1.5 bg-gray-100 rounded-lg';
        this.element.innerHTML = `
            <i class="fas fa-wallet text-gray-600"></i>
            <span class="text-sm font-medium text-gray-900">
                ${this.gamificationEnabled ? `${this.walletBalance} pts` : 'Gamification Disabled'}
            </span>
        `;
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}