import { sanitize } from '../../utilities/input-sanitization';

export class TextInput {
    private element: HTMLInputElement;
    private container: HTMLDivElement;
    private type: 'string' | 'email' | 'username' | 'password';
    private toggleButton?: HTMLButtonElement;
  
    constructor(placeholder: string = '', type: 'string' | 'email' | 'username' | 'password' = 'string') {
        this.type = type;
        this.container = document.createElement('div');
        this.container.className = `
            relative
            group
        `;
  
        this.element = document.createElement('input');
        this.element.className = `
            w-full
            px-4
            py-2
            bg-white/5
            backdrop-blur-sm
            border
            border-white/10
            rounded-lg
            outline-none
            text-white/70
            placeholder-white/30
            transition-all
            duration-300
            focus:border-white/30
            focus:bg-white/10
            hover:bg-white/10
            ${type === 'password' ? 'pr-10' : ''}
        `;
        this.element.placeholder = placeholder;
  
        if (type === 'password') {
            this.element.type = 'password';
            this.addPasswordToggle();
        }
  
        this.container.appendChild(this.element);
    }
  
    private addPasswordToggle(): void {
        this.toggleButton = document.createElement('button');
        this.toggleButton.type = 'button';
        this.toggleButton.className = `
            absolute 
            right-2 
            top-1/2 
            -translate-y-1/2
            text-white/50
            hover:text-white/90
            transition-colors
            duration-300
            p-1
            z-10
        `;
        
        this.toggleButton.innerHTML = `
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
            </svg>
        `;

        this.toggleButton.addEventListener('click', () => {
            const isPassword = this.element.type === 'password';
            this.element.type = isPassword ? 'text' : 'password';
            
            this.toggleButton!.innerHTML = isPassword 
                ? `<svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                   </svg>`
                : `<svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                   </svg>`;
        });

        this.container.appendChild(this.toggleButton);
    }
  
    public getValue(): string {
        const value = this.element.value;
        return sanitize(value, this.type === 'password' ? 'string' : this.type) as string;
    }
  
    public setValue(value: string): void {
        this.element.value = value;
    }
  
    public onChange(callback: (value: string) => void): void {
        this.element.addEventListener('input', () => callback(this.element.value));
    }
    
    public clear(): void {
        this.element.value = '';
        this.element.dispatchEvent(new Event('input'));
    }

    public getHTML(): string {
        return this.element.outerHTML;
    }

    public getElement(): HTMLInputElement {
        return this.element;
    }
  
    public mount(parent: HTMLElement): void {
        parent.appendChild(this.container);
    }
}