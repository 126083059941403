import { NanoResponse } from "../../actions/platochat/models";
import { PlatoChatService } from "../../actions/platochat/api-services";
import { AuthService } from "../../actions/auth/api-services";
import { navigate } from "../../app-router";
import { Hash } from "../../utilities/hash";
import { ModalForm } from "../reusable/modal-form";

const defaultNanoStyling = [
    { color: '#184469', emoji: '🫨' },
    { color: '#6D1818', emoji: '🐊' },
    { color: '#1D5F21', emoji: '🌶️' },
    { color: '#B3881C', emoji: '🎱' },
    { color: '#5F1674', emoji: '👀' },
    { color: '#003366', emoji: '🌨️' },
    { color: '#993300', emoji: '🍀' },
    { color: '#006600', emoji: '🔥' },
    { color: '#330066', emoji: '🌟' },
    { color: '#663300', emoji: '🔮' }
];

export class NanoBubbles {
    private element: HTMLElement;
    private userId: number;
    private nanos: NanoResponse[] = [];
    private offset: number = 0;
    private hasMore: boolean = true;
    private userNames: Record<number, string> = {};
    private modalForm: ModalForm;

    constructor(userId: number) {
        this.userId = userId;
        this.element = document.createElement('div');
        this.modalForm = new ModalForm();
        this.initialize();
        this.modalForm.mount(document.body);
    }

    private async initialize() {
        await this.loadNanos();
        this.render();
    }

    private async loadNanos() {
        const fetchedNanos = await PlatoChatService.getNanosByLastChat(this.offset, this.userId);
        if (fetchedNanos) {
            const userIds = new Set(fetchedNanos.flatMap(nano => this.getUserIds(nano)));
            const userPromises = Array.from(userIds).map(async id => {
                const user = await AuthService.getUserById(id);
                if (user) {
                    this.userNames[id] = user.name;
                }
            });
            await Promise.all(userPromises);
            
            this.nanos = fetchedNanos;
            this.offset += fetchedNanos.length;
            this.hasMore = fetchedNanos.length === 6;
        }
    }

    private render() {
        this.element.className = 'p-4 mt-6';
        
        if (this.nanos.length === 0) {
            this.element.innerHTML = this.renderNewcomersFAQ();
            this.attachCreateNanoEventListener();
            this.attachTutorialEventListener();
        } else {
            this.element.innerHTML = `
                <div class="text-center mb-6">
                    <h2 class="text-xl font-bold text-white mb-1.5">Nanos</h2>
                    <p class="text-white/60 text-sm">Discuss with matches here</p>
                </div>
                <div class="text-center mb-6">
                    <button id="create-nano-btn" class="px-6 py-3 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg
                        shadow-md hover:shadow-lg transition-all duration-200 text-white text-sm font-semibold
                        flex items-center justify-center mx-auto gap-2">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path>
                        </svg>
                        Create Custom Nano
                    </button>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-4xl mx-auto">
                    ${this.renderNanoBubbles()}
                </div>
                ${this.hasMore ? `
                    <div class="text-center mt-8">
                        <button id="load-more-btn" 
                            class="px-4 py-2 bg-blue-500/20 hover:bg-blue-500/30 
                            rounded-lg border border-blue-400/30 text-white/90 text-sm
                            transition-all duration-300 hover:scale-103">
                            Load More
                        </button>
                    </div>
                ` : ''}
            `;
            
            this.attachEventListeners();
            this.attachCreateNanoEventListener();
        }
    }

    private renderNanoBubbles(): string {
        return this.nanos.map((nano, index) => {
            const defaultStyle = defaultNanoStyling[index % defaultNanoStyling.length];
            const participants = this.formatParticipants(nano);
            
            const backgroundColor = nano.color ? 
                `#${nano.color.slice(2)}` : 
                defaultStyle.color;
            
            const emoji = nano.emoji || defaultStyle.emoji;
            
            return `
                <div class="flex flex-col items-center gap-2 cursor-pointer group" 
                    data-nano-id="${nano.ID}">
                    <div class="w-full max-w-[140px] aspect-square relative mx-auto">
                        <div class="absolute inset-0 rounded-full transition-all duration-300 
                            shadow-md hover:shadow-lg group-hover:scale-103
                            border border-white/20"
                            style="background-color: ${backgroundColor}30">
                            <div class="absolute inset-0 flex items-center justify-center">
                                <span class="text-4xl transform transition-all duration-300 
                                    group-hover:rotate-12 group-hover:scale-105">
                                    ${emoji}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="w-full px-3 text-center">
                        <p class="text-xs text-white/80 truncate">
                            ${participants}
                        </p>
                    </div>
                </div>
            `;
        }).join('');
    }

    private renderNewcomersFAQ(): string {
        return `
            <div class="max-w-2xl mx-auto space-y-6">
                <div class="text-center mb-12">
                    <button id="create-nano-btn" class="px-6 py-3 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg
                        shadow-md hover:shadow-lg transition-all duration-200 text-white text-sm font-semibold
                        flex items-center justify-center mx-auto gap-2">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path>
                        </svg>
                        Create Custom Nano
                    </button>
                </div>

                <div class="text-center mb-8">
                    <button id="tutorial-button" class="px-6 py-3 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg
                        shadow-md hover:shadow-lg transition-all duration-200 text-white text-sm font-semibold
                        flex items-center justify-center mx-auto gap-2">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                        </svg>
                        Quick Tutorial
                    </button>
                </div>

                <h2 class="text-2xl font-bold text-white mb-6">Newcomers' FAQ:</h2>

                ${this.renderFAQItem(
                    "How to get started with PLATO5?",
                    "Press the 'Meet Someone New' button and start meeting new people! Underneath the button are the preference togglers. There you can decide whether you want to meet someone near you, with a similar personality, with similar interests, or someone completely different."
                )}

                ${this.renderFAQItem(
                    "What are Nanos?",
                    "Nanos (short for nano-blogs) are like groupchats but less immediate and more focused on reacting to content from the PLATO5 app. Nanos are designed to keep the conversation going with features like gamification, icebreakers, and discussion responses."
                )}

                ${this.renderFAQItem(
                    "What are the PLATO5 applets?",
                    "PlatoChat (the applet that is selected right now; keeps track of your nanos and conversations as well as the people you've met. This is the main place to communicate on PLATO5), Kommin (interest-tracking discussion board), Nearby (bulletin board for events near me), and Analytix (the applet that keeps track of our users' personality data and integrates that into all of the other systems)."
                )}
            </div>
        `;
    }

    private renderFAQItem(question: string, answer: string): string {
        return `
            <div class="mb-6">
                <h3 class="text-lg font-semibold text-white mb-2">
                    Q: ${question}
                </h3>
                <p class="text-gray-300 text-base leading-relaxed">
                    A: ${answer}
                </p>
            </div>
        `;
    }

    private attachEventListeners() {
        const nanoBubbles = this.element.querySelectorAll('[data-nano-id]');
        nanoBubbles.forEach(bubble => {
            bubble.addEventListener('click', (e) => {
                const nanoId = (e.currentTarget as HTMLElement).dataset.nanoId;
                if (nanoId) this.handleNanoClick(parseInt(nanoId));
            });
        });

        const loadMoreBtn = this.element.querySelector('#load-more-btn');
        if (loadMoreBtn) {
            loadMoreBtn.addEventListener('click', async () => {
                const button = loadMoreBtn as HTMLButtonElement;
                button.disabled = true;
                button.textContent = 'Loading...';
                
                try {
                    const newNanos = await PlatoChatService.getNanosByLastChat(this.offset, this.userId);
                    if (newNanos && newNanos.length > 0) {
                        const userIds = new Set(newNanos.flatMap(nano => this.getUserIds(nano)));
                        const userPromises = Array.from(userIds).map(async id => {
                            const user = await AuthService.getUserById(id);
                            if (user) {
                                this.userNames[id] = user.name;
                            }
                        });
                        await Promise.all(userPromises);
                        
                        this.nanos = [...this.nanos, ...newNanos];
                        this.offset += newNanos.length;
                        this.hasMore = newNanos.length === 6;
                        this.render();
                    } else {
                        this.hasMore = false;
                        this.render();
                    }
                } catch (error) {
                    console.error('Failed to load more nanos:', error);
                    button.textContent = 'Load More';
                    button.disabled = false;
                }
            });
        }
    }

    private async handleNanoClick(nanoId: number) {
        try {
            const nano = await PlatoChatService.getNanoById(nanoId);
            if (nano) {
                const hashedId = Hash.encodeId(nanoId);
                navigate(`/platochat/nanos/${hashedId}`);
            }
        } catch (error) {
            console.error('Failed to open nano:', error);
        }
    }

    private attachTutorialEventListener() {
        const tutorialButton = this.element.querySelector('#tutorial-button');
        const buttonContainer = this.element.querySelector('.text-center');
        let videoContainer: HTMLElement | null = null;
        
        if (tutorialButton && buttonContainer) {
            tutorialButton.addEventListener('click', () => {
                if (videoContainer) {
                    // If video exists, remove it and reset button
                    videoContainer.remove();
                    videoContainer = null;
                    tutorialButton.innerHTML = `
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                        </svg>
                        Quick Tutorial
                    `;
                } else {
                    // Create and insert video
                    videoContainer = document.createElement('div');
                    videoContainer.className = 'mt-8 relative w-full sm:w-[400px] aspect-video rounded-lg overflow-hidden shadow-md mx-auto';
                    videoContainer.innerHTML = `
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/SpwQBoC9EqE"
                            title="PLATO5 Tutorial"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            class="absolute top-0 left-0 w-full h-full"
                        ></iframe>
                    `;
                    
                    buttonContainer.insertAdjacentElement('afterend', videoContainer);
                    tutorialButton.innerHTML = `
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        Hide Video
                    `;
                }
            });
        }
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    private rgbToHex(color: { red: number, green: number, blue: number }): string {
        const r = color.red.toString(16).padStart(2, '0');
        const g = color.green.toString(16).padStart(2, '0');
        const b = color.blue.toString(16).padStart(2, '0');
        return `#${r}${g}${b}`;
    }

    private formatParticipants(nano: NanoResponse): string {
        const participants = [nano.user1_id, nano.user2_id, nano.user3_id, nano.user4_id]
            .filter(id => id !== this.userId)
            .map(id => this.userNames[id] || 'Unknown')
            .join(', ');
        return participants || 'No other participants';
    }

    private getUserIds(nano: NanoResponse): number[] {
        return [nano.user1_id, nano.user2_id, nano.user3_id, nano.user4_id].filter(id => id > 0);
    }

    private attachCreateNanoEventListener() {
        const createNanoBtn = this.element.querySelector('#create-nano-btn');
        if (createNanoBtn) {
            createNanoBtn.addEventListener('click', () => this.showCreateNanoModal());
        }
    }

    private async showCreateNanoModal() {
        const modalContent = document.createElement('div');
        modalContent.className = 'space-y-4';
        modalContent.innerHTML = `
            <h2 class="text-lg font-semibold text-white mb-4">Create a Custom Nano</h2>
            <p class="text-white/70 text-sm mb-4">Select 3 users to create a nano with. You will automatically be added as the fourth participant.</p>
            
            <div class="space-y-2 max-h-[50vh] overflow-y-auto pr-2 scrollbar-thin scrollbar-track-white/10 scrollbar-thumb-white/20">
                <div id="users-list" class="space-y-2">
                    Loading users...
                </div>
            </div>
            
            <div class="flex justify-between items-center mt-4 pt-4 border-t border-white/10">
                <span class="text-white/70 text-sm">Selected: <span id="selected-count">0</span>/3</span>
                <button id="create-btn" disabled
                    class="px-4 py-2 bg-blue-500/20 hover:bg-blue-500/30 rounded-lg
                    text-white/90 text-sm transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed">
                    Create Nano
                </button>
            </div>
        `;

        this.modalForm.append(modalContent);
        this.modalForm.open();

        // Load and display users
        const usersList = modalContent.querySelector('#users-list');
        const selectedUsers = new Set<number>();
        const users = await AuthService.getUsers(0); // Using the new getUsers method

        if (usersList && users) {
            usersList.innerHTML = users
                .filter(user => user.id !== this.userId)
                .map(user => `
                    <div class="flex flex-col p-4 rounded-lg bg-white/5 hover:bg-white/10 transition-colors cursor-pointer space-y-3"
                        data-user-id="${user.id}">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-2">
                                <svg class="w-5 h-5 text-white/60" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <span class="text-white font-medium">${user.name}</span>
                            </div>
                            <div class="w-5 h-5 rounded-full border-2 border-white/30 flex items-center justify-center transition-colors select-checkbox
                                ${selectedUsers.has(user.id) ? 'bg-blue-500 border-blue-500' : ''}">
                                ${selectedUsers.has(user.id) ? '<svg class="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20"><path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path></svg>' : ''}
                            </div>
                        </div>
                        
                        <div class="space-y-2 text-sm">
                            ${user.location ? `
                                <div class="flex items-center gap-2 text-white/60">
                                    <svg class="w-4 h-4 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    </svg>
                                    <span class="line-clamp-1">${user.location}</span>
                                </div>
                            ` : ''}
                            
                            ${user.intro ? `
                                <div class="flex items-start gap-2 text-white/60">
                                    <svg class="w-4 h-4 shrink-0 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                                    </svg>
                                    <span class="line-clamp-2">${user.intro}</span>
                                </div>
                            ` : ''}
                            
                            ${user.instagramLink ? `
                                <div class="flex items-center gap-2 text-white/60">
                                    <svg class="w-4 h-4 shrink-0" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4z"/>
                                    </svg>
                                    <span class="line-clamp-1">${user.instagramLink}</span>
                                </div>
                            ` : ''}
                        </div>
                    </div>
                `).join('');

            // Handle user selection
            const updateUI = () => {
                const countEl = modalContent.querySelector('#selected-count');
                const createBtn = modalContent.querySelector('#create-btn');
                if (countEl) countEl.textContent = selectedUsers.size.toString();
                if (createBtn) (createBtn as HTMLButtonElement).disabled = selectedUsers.size !== 3;
            };

            usersList.addEventListener('click', (e) => {
                const userEl = (e.target as HTMLElement).closest('[data-user-id]');
                if (!userEl) return;

                const userId = parseInt(userEl.getAttribute('data-user-id') || '0');
                if (selectedUsers.has(userId)) {
                    selectedUsers.delete(userId);
                    const checkbox = userEl.querySelector('.select-checkbox');
                    if (checkbox) {
                        checkbox.classList.remove('bg-blue-500', 'border-blue-500');
                        checkbox.innerHTML = '';
                    }
                } else if (selectedUsers.size < 3) {
                    selectedUsers.add(userId);
                    const checkbox = userEl.querySelector('.select-checkbox');
                    if (checkbox) {
                        checkbox.classList.add('bg-blue-500', 'border-blue-500');
                        checkbox.innerHTML = '<svg class="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20"><path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path></svg>';
                    }
                }
                updateUI();
            });

            // Handle create button
            const createBtn = modalContent.querySelector('#create-btn');
            if (createBtn) {
                createBtn.addEventListener('click', async () => {
                    if (selectedUsers.size === 3) {
                        const userIds = Array.from(selectedUsers);
                        const nanoId = await PlatoChatService.createNano(
                            userIds[0],
                            userIds[1],
                            userIds[2],
                            this.userId
                        );
                        
                        this.modalForm.close();
                        
                        if (nanoId) {
                            const hashedNanoId = Hash.encodeId(nanoId);
                            navigate(`/platochat/nanos/${hashedNanoId}`);
                        } else {
                            await this.loadNanos();
                            this.render();
                        }
                    }
                });
            }
        }
    }
}