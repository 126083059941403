export class Button {
    private element: HTMLButtonElement;
  
    constructor(text: string) {
      this.element = document.createElement('button');
      this.element.textContent = text;
      this.element.className = `
        px-4
        py-2
        bg-white/10
        hover:bg-white/20
        dark:bg-black/30
        dark:hover:bg-black/40
        rounded-lg
        border
        border-white/10
        transition-all
        duration-300
        text-white
        font-medium
        shadow-lg
        hover:shadow-xl
        active:scale-95
      `;
    }
  
    public onClick(handler: () => void) {
      this.element.addEventListener('click', handler);
    }
  
    public mount(parent: HTMLElement) {
      parent.appendChild(this.element);
    }

    public getElement(): HTMLButtonElement {
        return this.element;
    }
  }