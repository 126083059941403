import { KomminService } from '../../actions/kommin/api-services';
import { DiscussionResponse } from '../../actions/kommin/models';
import { UINotifications } from '../../utilities/errors';
import { Button } from '../reusable/button';
import { ModalForm } from '../reusable/modal-form';
import { CreateDiscussionContent } from '../reusable/create-discussion-content';
import { Hash } from '../../utilities/hash';
import { navigate } from '../../app-router';

export class DiscussionsContent {
    private discussions: DiscussionResponse[] = [];
    private currentOffset: number = 0;
    private grid!: HTMLElement;
    private modalForm: ModalForm;

    constructor() {
        this.modalForm = new ModalForm(); // Initialize the modal form
        this.modalForm.mount(document.body); // Ensure the modal is part of the DOM
    }

    async mount(parent: HTMLElement) {
        this.currentOffset = 0;

        const content = document.createElement('div');
        content.className = 'discussions-content space-y-4';
        parent.appendChild(content);

        const isLoggedIn = this.checkUserLoggedIn();

        if (isLoggedIn) {
            const startDiscussionButton = new Button('Start a Discussion');
            startDiscussionButton.getElement().style.background = 'darkorange';
            startDiscussionButton.getElement().style.color = 'white';
            startDiscussionButton.getElement().style.display = 'flex';
            startDiscussionButton.getElement().style.alignItems = 'center';
            startDiscussionButton.getElement().style.justifyContent = 'center';
            startDiscussionButton.getElement().style.margin = '0 auto';
            startDiscussionButton.getElement().style.width = 'fit-content';
            startDiscussionButton.getElement().innerHTML = `
                <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                          d="M12 4v16m8-8H4"></path>
                </svg>
                Start a Discussion
            `;
            startDiscussionButton.onClick(async () => {
                console.log('Start a Discussion button clicked'); // Debugging statement
                const createDiscussionContent = new CreateDiscussionContent();
                const contentElement = await createDiscussionContent.createDiscussionForm();
                if (contentElement instanceof HTMLElement) {
                    console.log('Content created successfully'); // Debugging statement
                    this.modalForm.clear(); // Clear any existing content
                    this.modalForm.append(contentElement); // Append the content to the modal
                    this.modalForm.open(); // Open the modal
                } else {
                    console.error('Failed to create content'); // Debugging statement
                }
            });
            startDiscussionButton.mount(content);
        }

        this.grid = document.createElement('div');
        this.grid.className = 'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
        content.appendChild(this.grid);

        UINotifications.showGlobalLoadingIndication();

        try {
            const initialDiscussions = await KomminService.getDiscussionsByRecency(0);
            UINotifications.hideGlobalLoadingIndication();

            if (!initialDiscussions || initialDiscussions.length === 0) {
                const notice = document.createElement('div');
                notice.className = 'col-span-full text-center p-8 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10';
                notice.innerHTML = `
                    <h3 class="text-lg font-medium text-white/90 mb-2">No discussions found.</h3>
                    <p class="text-sm text-white/70 mb-3">Try selecting a different iTag or check back later.</p>
                `;
                this.grid.appendChild(notice);
                return;
            }

            await this.loadMoreDiscussions();
        } catch (error) {
            console.error('Error fetching discussions:', error);
            UINotifications.hideGlobalLoadingIndication();
        }

        const loadMoreButton = new Button('Load More');
        loadMoreButton.getElement().style.width = '100%';
        loadMoreButton.onClick(async () => {
            loadMoreButton.getElement().disabled = true;
            await this.loadMoreDiscussions();
            loadMoreButton.getElement().disabled = false;
        });
        loadMoreButton.mount(content);
    }

    public async loadMostRecentDiscussions() {
        this.currentOffset = 0;
        this.grid.innerHTML = ''; // Clear existing discussions
        UINotifications.showGlobalLoadingIndication();
        try {
            const discussions = await KomminService.getDiscussionsByRecency(0);
            UINotifications.hideGlobalLoadingIndication();
            this.renderDiscussions(discussions);
        } catch (error) {
            console.error('Error loading most recent discussions:', error);
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    public async loadDiscussionsByITag(itag: string) {
        this.grid.innerHTML = ''; // Clear existing discussions
        UINotifications.showGlobalLoadingIndication();
        try {
            const discussions = await KomminService.getDiscussionsByITag(itag);
            UINotifications.hideGlobalLoadingIndication();
            this.renderDiscussions(discussions);
        } catch (error) {
            console.error('Error loading discussions by iTag:', error);
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    private renderDiscussions(discussions: DiscussionResponse[] | null) {
        if (discussions && discussions.length > 0) {
            discussions.forEach(discussion => {
                const tile = this.createDiscussionTile(discussion);
                this.grid.appendChild(tile);
            });
        } else {
            const notice = document.createElement('div');
            notice.className = 'col-span-full text-center p-8 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10';
            notice.innerHTML = `
                <h3 class="text-lg font-medium text-white/90 mb-2">No discussions found.</h3>
                <p class="text-sm text-white/70 mb-3">Try selecting a different iTag or check back later.</p>
            `;
            this.grid.appendChild(notice);
        }
    }

    private async loadMoreDiscussions() {
        UINotifications.showGlobalLoadingIndication();

        const newDiscussions = await KomminService.getDiscussionsByRecency(this.currentOffset);

        UINotifications.hideGlobalLoadingIndication();

        if (newDiscussions && newDiscussions.length > 0) {
            newDiscussions.forEach(discussion => {
                const tile = this.createDiscussionTile(discussion);
                this.grid.appendChild(tile);
            });
            this.currentOffset += newDiscussions.length;
            this.discussions = [...this.discussions, ...newDiscussions];
        }
    }

    private async fetchDiscussions(): Promise<DiscussionResponse[]> {
        const discussions = await KomminService.getDiscussionsByRecency(0);
        return discussions || [];
    }

    private createDiscussionTile(discussion: DiscussionResponse): HTMLElement {
        const tile = document.createElement('div');
        tile.className = `
            relative overflow-hidden
            bg-gradient-to-br from-orange-500/20 via-orange-400/10 to-amber-400/5
            backdrop-blur-md
            rounded-lg border border-orange-400/20
            p-4
            transition-all duration-300 cursor-pointer 
            hover:shadow-[0_0_20px_rgba(255,165,0,0.1)]
            hover:transform hover:scale-[1.01]
            hover:border-orange-400/30
            hover:from-orange-500/30 hover:via-orange-400/20 hover:to-amber-400/10
            group
        `;

        const rating = Math.round(Number(discussion.AverageRating) * 100) / 100;
        const ratingDisplay = !isNaN(rating) 
            ? (rating > 50 ? `+${rating}` : `-${rating}`)
            : 'N/A';
        const ratingColor = !isNaN(rating)
            ? (rating > 50 ? 'text-green-500' : 'text-red-500')
            : 'text-white/50';

        tile.innerHTML = `
            <div class="flex items-start justify-between mb-3">
                <div>
                    <h3 class="font-medium text-white/90 text-sm">${discussion.Author}</h3>
                    <p class="text-xs text-white/60">${discussion.Timestamp}</p>
                </div>
                <div class="flex flex-col items-end">
                    <span class="${ratingColor} font-medium text-sm">${ratingDisplay}</span>
                    <span class="text-xs text-white/40">avg. rating</span>
                </div>
            </div>
            <h2 class="text-base font-semibold mb-2 text-white/90">${this.buildExcerpt(discussion.Body, 150)}</h2>
        `;

        tile.addEventListener('click', () => {
            console.log('Discussion ID:', discussion.ID); // Debug the actual ID
            const hashedId = Hash.encodeId(discussion.ID);
            console.log('Hashed ID:', hashedId); // Debug the hashed value
            navigate(`/kommin/discussion/${hashedId}`);
        });

        return tile;
    }

    private checkUserLoggedIn(): boolean {
        const userId = localStorage.getItem('userId');
        return userId !== null;
    }

    private buildExcerpt(text: string, maxLength: number = 200): string {
        if (text.length <= maxLength) return text;
        
        // Find the last space before maxLength to avoid cutting words
        const lastSpace = text.substring(0, maxLength).lastIndexOf(' ');
        return text.substring(0, lastSpace) + '...';
    }
} 