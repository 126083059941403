export class APIKeyFooter {
    private element: HTMLElement;

    constructor() {
        this.element = document.createElement('footer');
        this.element.className = 'fixed bottom-0 left-0 right-0 border-t border-white/10 translate-y-full opacity-0 transition-all duration-300 ease-out z-40 bg-black/30 backdrop-blur-lg';
        
        this.element.innerHTML = `
            <div class="max-w-7xl mx-auto px-4 py-3">
                <div class="flex justify-between items-center text-xs text-white/50">
                    <div>${new Date().getFullYear()} PLATO5 Social Engines LLC</div>
                    <div class="cursor-not-allowed">Request API Key (Coming Soon)</div>
                </div>
            </div>
        `;

        this.setupScrollBehavior();
    }

    private setupScrollBehavior() {
        const handleScroll = () => {
            const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 10;
            this.element.classList.toggle('translate-y-full', !isAtBottom);
            this.element.classList.toggle('opacity-0', !isAtBottom);
            this.element.classList.toggle('translate-y-0', isAtBottom);
            this.element.classList.toggle('opacity-100', isAtBottom);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        handleScroll();
    }

    public mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }

    public unmount() {
        this.element.remove();
    }
}