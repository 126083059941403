import { AuthService } from '../../actions/auth/api-services';
import { UINotifications } from '../../utilities/errors';

export class GeolocationContent {
    private element: HTMLElement;
    private userId: number;
    private onComplete: (success: boolean) => void;

    constructor(userId: number, onComplete: (success: boolean) => void) {
        this.element = document.createElement('div');
        this.userId = userId;
        this.onComplete = onComplete;
        this.render();
    }

    private render() {
        this.element.innerHTML = `
            <div class="flex flex-col items-center justify-center gap-8 p-8 max-w-lg mx-auto">
                <div class="relative w-24 h-24 mb-4">
                    <div class="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-full animate-pulse"></div>
                    <div class="absolute inset-2 bg-gradient-to-r from-purple-500/40 to-pink-500/40 rounded-full animate-pulse animation-delay-150"></div>
                    <div class="absolute inset-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full flex items-center justify-center">
                        <i class="fas fa-location-dot text-3xl text-white"></i>
                    </div>
                </div>
                
                <h2 class="text-2xl font-bold text-white/90 text-center">
                    Find People Nearby
                </h2>
                
                <div class="space-y-4">
                    <p class="text-white/70 text-center leading-relaxed">
                        PLATO5 uses your location to help you connect with people nearby. This helps build IRL relationships from the matches you make here.
                    </p>
                    
                    <ul class="text-white/60 space-y-2 text-sm">
                        <li class="flex items-center gap-2">
                            <i class="fas fa-check text-green-400"></i>
                            We never store your latitude and longitude coordinates
                        </li>
                        <li class="flex items-center gap-2">
                            <i class="fas fa-check text-green-400"></i>
                            We only store generalized location data called a geohash
                        </li>
                    </ul>
                </div>

                <div class="loading-spinner hidden">
                    <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                </div>

                <button class="get-location-btn group relative overflow-hidden px-8 py-4 bg-gradient-to-r from-purple-500 to-pink-500 
                    rounded-full text-white font-medium transition-all duration-300 hover:scale-105 
                    shadow-lg hover:shadow-purple-500/20">
                    <span class="relative z-10 flex items-center gap-2">
                        <i class="fas fa-location-crosshairs"></i>
                        Enable Location
                    </span>
                    <div class="absolute inset-0 -translate-x-full group-hover:translate-x-0 
                        bg-gradient-to-r from-pink-500 to-purple-500 transition-transform duration-300"></div>
                </button>
            </div>
        `;

        const style = document.createElement('style');
        style.textContent = `
            @keyframes pulse-delay {
                0%, 100% { opacity: 1; }
                50% { opacity: 0.5; }
            }
            .animation-delay-150 {
                animation-delay: 150ms;
            }
        `;
        document.head.appendChild(style);

        const button = this.element.querySelector('.get-location-btn');
        if (button) {
            button.addEventListener('click', () => this.setupGeolocation());
        }
    }

    private async setupGeolocation() {
        if (!navigator.geolocation) {
            UINotifications.shipErrorToUI('Geolocation is not supported by your browser');
            return;
        }

        const spinner = this.element.querySelector('.loading-spinner');
        const button = this.element.querySelector('.get-location-btn');
        
        if (spinner && button) {
            spinner.classList.remove('hidden');
            button.classList.add('hidden');
        }

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                try {
                    const geohash = await AuthService.createGeohash(
                        this.userId,
                        position.coords.latitude,
                        position.coords.longitude
                    );

                    if (geohash) {
                        UINotifications.shipSuccessToUI('Location enabled! You can now find people nearby.', { persist: true });
                        this.onComplete(true);
                    } else {
                        throw new Error('Failed to update location');
                    }
                } catch (error) {
                    console.error('Location update error:', error);
                    UINotifications.shipErrorToUI('Failed to update location');
                    if (spinner && button) {
                        spinner.classList.add('hidden');
                        button.classList.remove('hidden');
                    }
                    this.onComplete(false);
                }
            },
            (error) => {
                let errorMessage = 'Failed to get location';
                if (error.code === error.PERMISSION_DENIED) {
                    // Check if it's the "dismissed several times" case
                    if (error.message.includes('dismissed the permission prompt several times')) {
                        errorMessage = `
                            To enable location:
                            1. Click the 🔒 icon in your address bar
                            2. Click "Site Settings"
                            3. Change location access to "Allow"
                        `;
                    } else {
                        errorMessage = 'Please enable location access in your browser settings';
                    }
                } else if (error.code === error.POSITION_UNAVAILABLE) {
                    errorMessage = 'Location information is unavailable';
                } else if (error.code === error.TIMEOUT) {
                    errorMessage = 'Location request timed out';
                }
                
                UINotifications.shipErrorToUI(errorMessage);
                if (spinner && button) {
                    spinner.classList.add('hidden');
                    button.classList.remove('hidden');
                }
                this.onComplete(false);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            }
        );
    }

    public getElement(): HTMLElement {
        return this.element;
    }
} 