import { KomminService } from '../../../actions/kommin/api-services';
import { DiscussionResponse } from '../../../actions/kommin/models';
import { UINotifications } from '../../../utilities/errors';

export class KomminSelectionContent {
    private element: HTMLElement;
    private grid: HTMLElement;
    private currentOffset: number = 0;
    private discussions: DiscussionResponse[] = [];
    private onSelect: (discussion: DiscussionResponse) => void;

    constructor(onSelect: (discussion: DiscussionResponse) => void) {
        this.onSelect = onSelect;
        this.element = document.createElement('div');
        this.element.className = 'flex flex-col h-[80vh] min-w-[300px]';
        
        this.grid = document.createElement('div');
        this.grid.className = 'grid gap-4 grid-cols-1';
        
        this.render();
    }

    private async render() {
        // Header (fixed at top)
        const header = document.createElement('div');
        header.className = 'flex-shrink-0 mb-6';
        header.innerHTML = `
            <h2 class="text-xl font-bold text-white/90 mb-2">Select Discussion</h2>
            <p class="text-white/70">Choose a discussion to share in this nano:</p>
        `;
        this.element.appendChild(header);
        
        // Scrollable container for grid
        const scrollContainer = document.createElement('div');
        scrollContainer.className = 'flex-1 overflow-y-auto px-1';
        scrollContainer.appendChild(this.grid);
        this.element.appendChild(scrollContainer);
        
        // Load initial discussions
        await this.loadDiscussions();
        
        // Load more button (fixed at bottom)
        const loadMoreButton = document.createElement('button');
        loadMoreButton.className = 'flex-shrink-0 w-full px-4 py-2 mt-6 rounded-xl border border-white/10 text-white/70 hover:bg-white/5';
        loadMoreButton.textContent = 'Load More';
        loadMoreButton.onclick = () => this.loadDiscussions();
        this.element.appendChild(loadMoreButton);
    }

    private async loadDiscussions() {
        UINotifications.showGlobalLoadingIndication();
        
        try {
            const newDiscussions = await KomminService.getDiscussionsByRecency(this.currentOffset);
            
            if (newDiscussions && newDiscussions.length > 0) {
                newDiscussions.forEach(discussion => {
                    const tile = this.createDiscussionTile(discussion);
                    this.grid.appendChild(tile);
                });
                this.currentOffset += newDiscussions.length;
                this.discussions = [...this.discussions, ...newDiscussions];
            } else if (this.currentOffset === 0) {
                this.showEmptyState();
            }
        } catch (error) {
            console.error('Error loading discussions:', error);
            this.showErrorState();
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    private createDiscussionTile(discussion: DiscussionResponse): HTMLElement {
        const tile = document.createElement('div');
        tile.className = `
            bg-white/5 backdrop-blur-sm rounded-lg p-4 
            transition-all duration-200 cursor-pointer 
            hover:bg-white/10 hover:border-white/20
            border border-white/10
        `;

        const excerpt = this.buildExcerpt(discussion.Body, 100);
        
        tile.innerHTML = `
            <div class="flex items-start justify-between mb-2">
                <div>
                    <h3 class="font-medium text-white/90">${discussion.Author}</h3>
                    <p class="text-xs text-white/60">${discussion.Timestamp}</p>
                </div>
            </div>
            <p class="text-sm text-white/80">${excerpt}</p>
        `;

        tile.onclick = () => {
            this.onSelect(discussion);
        };

        return tile;
    }

    private showEmptyState() {
        this.grid.innerHTML = `
            <div class="text-center p-6 bg-white/5 rounded-lg border border-white/10">
                <p class="text-white/70">No discussions found.</p>
            </div>
        `;
    }

    private showErrorState() {
        this.grid.innerHTML = `
            <div class="text-center p-6 bg-white/5 rounded-lg border border-white/10">
                <p class="text-white/70">Unable to load discussions.</p>
                <p class="text-sm text-white/50 mt-2">Please try again later.</p>
            </div>
        `;
    }

    private buildExcerpt(text: string, maxLength: number): string {
        if (text.length <= maxLength) return text;
        const lastSpace = text.substring(0, maxLength).lastIndexOf(' ');
        return text.substring(0, lastSpace) + '...';
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}