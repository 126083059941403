import { ModalForm } from '../pieces/reusable/modal-form';
import { TopBar } from '../pieces/reusable/top-bar';

export class UnknownRouteScreen {
    private element: HTMLElement;
    private modalForm: ModalForm;
    private topBar: TopBar;
  
    constructor() {
        this.modalForm = new ModalForm();
        this.topBar = new TopBar(this.modalForm);
        this.element = document.createElement('div');
        this.render();
    }
  
    private render() {
        this.element.className = 'min-h-[80vh] flex items-center justify-center px-4 mt-24';
        this.element.innerHTML = `
            <div class="max-w-2xl mx-auto">
                <div class="bg-gradient-to-br from-blue-900/30 via-blue-800/20 to-blue-900/30 
                    backdrop-blur-xl rounded-3xl p-8 md:p-12 border border-white/10 shadow-2xl">
                    
                    <div class="space-y-8 text-center">
                        <div class="space-y-2">
                            <h1 class="text-7xl md:text-8xl font-bold text-transparent bg-clip-text 
                                bg-gradient-to-r from-blue-300 to-blue-100 font-montserrat tracking-tight">
                                Oops!
                            </h1>
                            <p class="text-xl md:text-2xl text-blue-200/90 font-light">
                                The page you're looking for doesn't exist
                            </p>
                        </div>

                        <div class="py-6">
                            <div class="w-24 h-24 mx-auto mb-6 mt-4 rounded-full bg-indigo-500/10 
                                flex items-center justify-center border border-indigo-300/20">
                                <span class="text-4xl text-indigo-300/90">404</span>
                            </div>
                            
                            <p class="text-lg text-white/80 leading-relaxed max-w-xl mx-auto">
                                Don't worry! You can easily return to the PLATO5 home page by clicking 
                                the <i class="fas fa-home text-blue-300"></i> icon in the top bar, or 
                                use the button below.
                            </p>
                        </div>

                        <div class="flex flex-col items-center gap-6">
                            <button 
                                class="group px-8 py-3 bg-gradient-to-r from-blue-500/20 to-blue-600/20 
                                    hover:from-blue-500/30 hover:to-blue-600/30 rounded-xl 
                                    border border-blue-500/30 transition-all duration-300 
                                    text-white/90 font-medium">
                                <span class="flex items-center gap-2">
                                    <i class="fas fa-home text-blue-300 group-hover:scale-110 transition-transform duration-300"></i>
                                    Return Home
                                </span>
                            </button>

                            <div class="text-blue-200/50 text-sm flex items-center gap-2">
                                <i class="fas fa-info-circle"></i>
                                Page Not Found
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
  
        // Add button click handler
        const button = this.element.querySelector('button');
        if (button) {
            button.addEventListener('click', () => {
                window.location.href = '/';
            });
        }
    }
  
    public mount(parent: HTMLElement) {
        // First ensure the top-bar element exists
        let topBarElement = document.querySelector('#top-bar') as HTMLElement;
        if (!topBarElement) {
            topBarElement = document.createElement('div');
            topBarElement.id = 'top-bar';
            document.body.insertBefore(topBarElement, document.body.firstChild);
        }
        
        this.topBar.mount(topBarElement);
        parent.innerHTML = '';
        parent.appendChild(this.element);
        this.modalForm.mount(document.body);
    }

    public unmount() {
        this.element.remove();
        this.modalForm.close();
    }
}