import { PlatoChatService } from "../../actions/platochat/api-services";
import { CommibResponse } from "../../actions/platochat/models";
import { UINotifications } from "../../utilities/errors";
import { TextInput } from "../reusable/text-input";
import { sanitize } from "../../utilities/input-sanitization";
import { AuthService } from "../../actions/auth/api-services";

export class DailyCommib {
    private element: HTMLElement;
    private userId: number | null;
    private icebreakers: CommibResponse[] = [];
    private currentIndex: number = 0;
    private textInput: TextInput | null = null;

    constructor(userId: number | null) {
        this.userId = userId;
        this.element = document.createElement('div');
        this.initialize();
    }

    private async initialize() {
        await this.loadIcebreakers();
        this.render();
    }

    private async loadIcebreakers() {
        try {
            const commibs = await PlatoChatService.getCommibsByRecency(0);
            if (commibs && commibs.length > 0) {
                this.icebreakers = commibs;
                commibs.forEach(commib => {
                    PlatoChatService.incrementViewCount(commib.ID);
                });
            }
        } catch (error) {
            console.error('Failed to load icebreakers:', error);
        }
    }

    private render() {
        // Create wrapper div for the entire component
        this.element.className = 'max-w-screen-xl mx-auto space-y-4';
        
        // Create header
        const header = `
            <div class="text-center mb-4">
                <h2 class="mt-6 text-xl font-bold text-white/90 mb-2">Check Out Some Icebreakers</h2>
            </div>
        `;

        // Create scroll container with always-visible buttons on mobile
        const scrollContainer = `
            <div class="relative group">
                <button class="scroll-left-btn absolute -left-2 md:-left-4 top-1/2 -translate-y-1/2 z-10
                    p-2 rounded-full bg-blue-500/20 hover:bg-blue-500/30 border border-blue-400/30
                    text-blue-200 transition-all duration-300 hover:scale-105 
                    opacity-100 md:opacity-0 md:group-hover:opacity-100">
                    <i class="fas fa-chevron-left text-sm"></i>
                </button>
                <button class="scroll-right-btn absolute -right-2 md:-right-4 top-1/2 -translate-y-1/2 z-10
                    p-2 rounded-full bg-blue-500/20 hover:bg-blue-500/30 border border-blue-400/30
                    text-blue-200 transition-all duration-300 hover:scale-105
                    opacity-100 md:opacity-0 md:group-hover:opacity-100">
                    <i class="fas fa-chevron-right text-sm"></i>
                </button>
                <div class="scroll-container overflow-x-auto flex gap-3 p-2 snap-x snap-mandatory
                    scrollbar-thin scrollbar-track-blue-500/10 scrollbar-thumb-blue-500/30">
                    ${this.icebreakers.map(icebreaker => {
                        const ratingColor = icebreaker.avgRating >= 50 ? 'text-green-400' : 'text-red-400';
                        
                        return `
                            <div class="snap-center shrink-0 w-[280px] p-3 space-y-2
                                bg-gradient-to-br from-blue-500/30 via-indigo-400/20 to-purple-400/10
                                border border-blue-400/30 rounded-lg backdrop-blur-sm
                                transform transition-all duration-300 hover:scale-[1.01]">
                                
                                <p class="text-base text-white font-medium leading-relaxed mb-2">
                                    "${icebreaker.content}"
                                </p>
                                
                                <div class="flex flex-col gap-1.5 text-xs">
                                    <div class="text-blue-200">by ${icebreaker.author}</div>
                                    <div class="text-blue-200"><i class="fas fa-eye"></i> ${icebreaker.views} views</div>
                                    <div class="${ratingColor} font-medium">Average Rating: ${icebreaker.avgRating.toFixed(2)}</div>
                                </div>
                                
                                ${this.userId ? `
                                    <div class="mt-3 pt-3 border-t border-blue-400/30">
                                        <div class="flex items-center gap-3 mb-2">
                                            <input type="range" min="1" max="100" step="0.1" value="50"
                                                class="w-full accent-blue-400 bg-blue-400/20 h-1.5 rounded-lg appearance-none cursor-pointer"
                                                data-commib-id="${icebreaker.ID}">
                                            <span class="rating-value text-blue-200 min-w-[4ch] text-xs">50.00</span>
                                        </div>
                                        <button class="rate-btn w-full py-2 bg-blue-400/30 hover:bg-blue-400/40
                                            rounded-lg border border-blue-400/40 text-white text-sm font-medium
                                            transition-all duration-300 hover:scale-[1.01] active:scale-[0.98]"
                                            data-commib-id="${icebreaker.ID}">
                                            Rate This Icebreaker
                                        </button>
                                    </div>
                                ` : `
                                    <div class="mt-3 pt-3 border-t border-blue-400/30">
                                        <div class="flex items-center justify-center gap-1.5 text-white/60">
                                            <i class="fas fa-lock text-xs"></i>
                                            <span class="text-xs">Login to rate this icebreaker</span>
                                        </div>
                                    </div>
                                `}
                            </div>
                        `;
                    }).join('')}
                </div>
            </div>
        `;

        // Create icebreaker input section - more compact version
        const createIcebreaker = this.userId ? `
            <div class="mt-4 max-w-xl mx-auto p-3 bg-black/30 backdrop-blur-sm border border-blue-400/20 rounded-lg">
                <div class="flex gap-2 items-center">
                    <div class="flex-1" id="icebreaker-form">
                        <!-- TextInput will be mounted here -->
                    </div>
                    <button class="create-commib-btn px-3 py-1.5 bg-blue-500/40 hover:bg-blue-500/50
                        rounded-lg border border-blue-400/40 text-white text-sm font-medium whitespace-nowrap
                        transition-all duration-300 hover:scale-[1.01] active:scale-[0.98]
                        h-[32px] flex items-center justify-center">
                        Add
                    </button>
                </div>
            </div>
        ` : '';

        this.element.innerHTML = header + scrollContainer + createIcebreaker;

        // Mount TextInput if user is logged in
        if (this.userId) {
            const form = this.element.querySelector('#icebreaker-form');
            if (form) {
                this.textInput = new TextInput('Write an icebreaker...', 'string');
                this.textInput.getElement().className = `
                    w-full bg-black/10 border border-blue-400/20 rounded-xl px-4 py-3
                    text-white placeholder-white/40 focus:outline-none focus:border-blue-400/40
                    transition-colors
                `;
                form.appendChild(this.textInput.getElement());
            }
        }

        this.attachEventListeners();
        this.setupInfiniteScroll();
        this.setupScrollButtons();
    }

    private setupScrollButtons() {
        const scrollAmount = 450;
        const container = this.element.querySelector('.scroll-container');
        const leftBtn = this.element.querySelector('.scroll-left-btn');
        const rightBtn = this.element.querySelector('.scroll-right-btn');

        if (!container) return;

        leftBtn?.addEventListener('click', () => {
            container.scrollBy({
                left: -scrollAmount,
                behavior: 'smooth'
            });
        });

        rightBtn?.addEventListener('click', () => {
            container.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });
        });
    }

    private setupInfiniteScroll() {
        const container = this.element.querySelector('.scroll-container');
        if (!container) return;

        const observer = new IntersectionObserver(
            async (entries) => {
                const lastEntry = entries[entries.length - 1];
                if (lastEntry.isIntersecting) {
                    try {
                        const moreCommibs = await PlatoChatService.getCommibsByRecency(this.icebreakers.length);
                        if (moreCommibs && moreCommibs.length > 0) {
                            // Store current scroll position
                            const scrollLeft = container.scrollLeft;
                            
                            // Append new icebreakers to array
                            this.icebreakers = [...this.icebreakers, ...moreCommibs];
                            
                            // Generate HTML for new items only
                            const newContent = moreCommibs.map(icebreaker => {
                                const ratingColor = icebreaker.avgRating >= 50 ? 'text-green-400' : 'text-red-400';
                                return `
                                    <div class="snap-center shrink-0 w-[280px] p-3 space-y-2
                                        bg-gradient-to-br from-blue-500/30 via-indigo-400/20 to-purple-400/10
                                        border border-blue-400/30 rounded-lg backdrop-blur-sm
                                        transform transition-all duration-300 hover:scale-[1.01]">
                                        
                                        <p class="text-base text-white font-medium leading-relaxed mb-2">
                                            "${icebreaker.content}"
                                        </p>
                                        
                                        <div class="flex flex-col gap-1.5 text-xs">
                                            <div class="text-blue-200">by ${icebreaker.author}</div>
                                            <div class="text-blue-200"><i class="fas fa-eye"></i> ${icebreaker.views} views</div>
                                            <div class="${ratingColor} font-medium">Average Rating: ${icebreaker.avgRating.toFixed(2)}</div>
                                        </div>
                                        
                                        ${this.userId ? `
                                            <div class="mt-3 pt-3 border-t border-blue-400/30">
                                                <div class="flex items-center gap-3 mb-2">
                                                    <input type="range" min="1" max="100" step="0.1" value="50"
                                                        class="w-full accent-blue-400 bg-blue-400/20 h-1.5 rounded-lg appearance-none cursor-pointer"
                                                        data-commib-id="${icebreaker.ID}">
                                                    <span class="rating-value text-blue-200 min-w-[4ch] text-xs">50.00</span>
                                                </div>
                                                <button class="rate-btn w-full py-2 bg-blue-400/30 hover:bg-blue-400/40
                                                    rounded-lg border border-blue-400/40 text-white text-sm font-medium
                                                    transition-all duration-300 hover:scale-[1.01] active:scale-[0.98]"
                                                    data-commib-id="${icebreaker.ID}">
                                                    Rate This Icebreaker
                                                </button>
                                            </div>
                                        ` : `
                                            <div class="mt-3 pt-3 border-t border-blue-400/30">
                                                <div class="flex items-center justify-center gap-1.5 text-white/60">
                                                    <i class="fas fa-lock text-xs"></i>
                                                    <span class="text-xs">Login to rate this icebreaker</span>
                                                </div>
                                            </div>
                                        `}
                                    </div>
                                `;
                            }).join('');
                            
                            // Append new content
                            container.insertAdjacentHTML('beforeend', newContent);
                            
                            // Restore scroll position
                            container.scrollLeft = scrollLeft;
                            
                            // Setup new observers and event listeners for new content
                            this.attachEventListeners();
                            
                            // Observe the new last card
                            const newLastCard = container.lastElementChild;
                            if (newLastCard) {
                                observer.observe(newLastCard);
                            }

                            // Increment view counts for new items
                            moreCommibs.forEach(commib => {
                                PlatoChatService.incrementViewCount(commib.ID);
                            });
                        }
                    } catch (error) {
                        console.error('Failed to load more icebreakers:', error);
                    }
                }
            },
            { threshold: 0.5 }
        );

        const lastCard = container.lastElementChild;
        if (lastCard) observer.observe(lastCard);
    }

    private attachEventListeners() {
        if (this.userId) {
            this.element.querySelectorAll('input[type="range"]').forEach(input => {
                input.addEventListener('input', (e) => {
                    const target = e.target as HTMLInputElement;
                    const valueDisplay = target.parentElement?.querySelector('.rating-value');
                    if (valueDisplay) {
                        valueDisplay.textContent = parseFloat(target.value).toFixed(2);
                    }
                });
            });

            this.element.querySelectorAll('.rate-btn').forEach(button => {
                button.addEventListener('click', async (e) => {
                    const target = e.target as HTMLButtonElement;
                    const commibId = target.dataset.commibId;
                    const ratingInput = target.parentElement?.querySelector('input[type="range"]') as HTMLInputElement;
                    
                    if (commibId && ratingInput) {
                        await this.handleIcebreakerRating(
                            parseFloat(ratingInput.value),
                            parseInt(commibId)
                        );
                    }
                });
            });

            // Add event listener for creating new icebreakers
            const createButton = this.element.querySelector('.create-commib-btn');
            
            if (createButton && this.textInput) {
                createButton.addEventListener('click', async () => {
                    if (!this.textInput!.getValue()) {
                        UINotifications.shipHintToUI('Please write something...');
                        return;
                    }

                    const button = createButton as HTMLButtonElement;
                    button.disabled = true;
                    button.textContent = 'Submitting...';

                    try {
                        const userData = await AuthService.getUserById(this.userId!);
                        if (!userData) {
                            UINotifications.shipErrorToUI('Failed to get user data');
                            return;
                        }

                        const response = await PlatoChatService.createCommib(
                            userData.name,
                            this.userId!,
                            this.textInput!.getValue()
                        );

                        if (response) {
                            UINotifications.shipSuccessToUI('Icebreaker submitted successfully');
                            this.textInput!.setValue('');
                            await this.loadIcebreakers();
                            this.render();
                        } else {
                            UINotifications.shipErrorToUI('Failed to submit icebreaker');
                        }
                    } catch (error) {
                        console.error('Failed to submit icebreaker:', error);
                        UINotifications.shipErrorToUI('Failed to submit icebreaker');
                    } finally {
                        button.disabled = false;
                        button.textContent = 'Add';
                    }
                });
            }
        }
    }

    private async handleIcebreakerRating(rating: number, commibId: number) {
        try {
            if (isNaN(rating) || rating < 0 || rating > 100) {
                console.error('Invalid rating value');
                return;
            }

            const button = this.element.querySelector(`button[data-commib-id="${commibId}"]`) as HTMLButtonElement;
            if (button) {
                button.disabled = true; // Disable button during request
            }

            const response = await PlatoChatService.updateRating(this.userId!, rating, commibId);
            if (response) {
                UINotifications.shipSuccessToUI('Rating submitted successfully');
                await this.loadIcebreakers();
                this.render();
            } else {
                if (button) {
                    button.disabled = true;
                    button.textContent = 'Already Rated';
                }
                UINotifications.shipHintToUI('You can only rate an icebreaker once', { persist: true });
            }
        } catch (error) {
            console.error('Failed to submit rating:', error);
            UINotifications.shipHintToUI('Failed to submit rating', { persist: true });
        }
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }
}