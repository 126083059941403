import { UserData } from '../../actions/auth/models';
import { Button } from '../reusable/button';
import { AuthService } from '../../actions/auth/api-services';
import { UINotifications } from '../../utilities/errors';
import { countryList } from '../../utilities/country-list';

export class UpdateUserContent {
    private element: HTMLElement;
    private onUpdateSuccess?: () => void;

    constructor(userData: UserData | null, onUpdateSuccess?: () => void) {
        this.element = document.createElement('div');
        this.onUpdateSuccess = onUpdateSuccess;
        this.render(userData);
    }

    private render(userData: UserData | null): void {
        this.element.className = 'p-6 space-y-6 max-w-lg mx-auto';
        this.element.innerHTML = `
            <h3 class="text-xl font-bold text-white/90 mb-6">Update Profile Details</h3>
            <div class="space-y-4">
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Intro</label>
                    <textarea id="intro" class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50" rows="3">${userData?.intro || ''}</textarea>
                </div>
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Country</label>
                    <select id="country" class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50">
                        ${countryList.map(country => `
                            <option value="${country}" ${country === userData?.country ? 'selected' : ''}>
                                ${country}
                            </option>
                        `).join('')}
                    </select>
                </div>
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Location</label>
                    <input type="text" id="location" value="${userData?.location || ''}" class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50">
                </div>
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Instagram</label>
                    <input type="text" id="instagramLink" value="${userData?.instagramLink || ''}" class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50">
                </div>
            </div>
        `;

        const saveButton = new Button('Save Changes');
        saveButton.getElement().className = 'mt-6 w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg text-white font-medium hover:from-blue-600 hover:to-blue-700 transition-all duration-300';
        
        saveButton.getElement().addEventListener('click', async () => {
            const userId = parseInt(localStorage.getItem('userId') || '0');
            if (!userId) {
                UINotifications.shipErrorToUI('Please log in to update details');
                return;
            }

            const updateData = {
                intro: (this.element.querySelector('#intro') as HTMLTextAreaElement).value,
                country: (this.element.querySelector('#country') as HTMLSelectElement).value,
                location: (this.element.querySelector('#location') as HTMLInputElement).value,
                instagramLink: (this.element.querySelector('#instagramLink') as HTMLInputElement).value
            };

            const success = await AuthService.updateUser(userId, updateData);
            if (success) {
                const userData = { ...JSON.parse(localStorage.getItem('userData') || '{}'), ...updateData };
                localStorage.setItem('userData', JSON.stringify(userData));
                this.onUpdateSuccess?.();
            }
        });

        this.element.appendChild(saveButton.getElement());
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}
