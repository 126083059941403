import { AuthService } from '../auth/api-services';
import { PlatoChatService } from '../platochat/api-services';
import { AnalytixData, QuestionList, AnalytixRequest } from './models';

const ANALYTIX_BASE_URL = import.meta.env.VITE_ANALYTIX_API_URL;
const API_KEY = import.meta.env.VITE_API_KEY;

export class AnalytixService {
    private static getHeaders(): Record<string, string> {
        const baseHeaders = {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY || ''
        };

        const sessionData = AuthService.getSessionData();
        if (sessionData) {
            return {
                ...baseHeaders,
                'X-Session-ID': sessionData.session_id,
                'X-Public-Key': sessionData.public_key,
                'X-User-ID': sessionData.userId?.toString() || ''
            };
        }

        return baseHeaders;
    }

    static async getAnalytixByUserId(userId: number): Promise<AnalytixData | null> {
        try {
            const response = await fetch(`${ANALYTIX_BASE_URL}/get-analytix-by-user-id?userId=${userId}`, {
                headers: this.getHeaders()
            });

            if (!response.ok) return null;
            return await response.json();
        } catch (error) {
            return null;
        }
    }

    static async calculateAnalytixScores(
        request: AnalytixRequest
    ): Promise<Record<string, number> | null> {
        try {
            const response = await fetch(`${ANALYTIX_BASE_URL}/calculate-analytix-scores`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(request)
            });

            if (!response.ok) return null;
            
            const data = await response.json();
            return {
                openness: data.openness,
                conscientiousness: data.conscientiousness,
                extraversion: data.extraversion,
                agreeableness: data.agreeableness,
                neuroticism: data.neuroticism
            };
        } catch (error) {
            return null;
        }
    }

    static async getQuestions(): Promise<QuestionList> {
        try {
            const response = await fetch(`${ANALYTIX_BASE_URL}/get-all-questions`, {
                headers: this.getHeaders()
            });

            if (!response.ok) return { questions: [] };
            
            const data = await response.json();
            return {
                questions: data.map((q: any) => ({
                    id: q.ID,
                    question: q.Question,
                    trait: q.Trait,
                    agreementInsight: q.AgreementInsight,
                    disagreementInsight: q.DisagreementInsight
                }))
            };
        } catch (error) {
            return { questions: [] };
        }
    }

    static async getShortAssessmentQuestions(): Promise<QuestionList> {
        try {
            const response = await fetch(`${ANALYTIX_BASE_URL}/get-short-assessment-questions`, {
                headers: this.getHeaders()
            });

            if (!response.ok) return { questions: [] };
            
            const data = await response.json();
            return {
                questions: data.map((q: any) => ({
                    id: q.ID,
                    question: q.Question,
                    trait: q.Trait,
                    agreementInsight: q.AgreementInsight,
                    disagreementInsight: q.DisagreementInsight
                }))
            };
        } catch (error) {
            return { questions: [] };
        }
    }

    static async saveAnalytix(
        userId: number,
        location: string,
        tOpenness: number,
        tConscientiousness: number,
        tExtraversion: number,
        tAgreeableness: number,
        tNeuroticism: number
    ): Promise<boolean> {
        try {
            const response = await fetch(`${ANALYTIX_BASE_URL}/save-analytix`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    user_id: userId,
                    location,
                    t_openness: tOpenness,
                    t_conscientiousness: tConscientiousness,
                    t_extraversion: tExtraversion,
                    t_agreeableness: tAgreeableness,
                    t_neuroticism: tNeuroticism,
                })
            });

            PlatoChatService.addToBalance(userId, 2500);
            return response.ok;
        } catch (error) {
            return false;
        }
    }

    static async getAnalytixById(analytixId: number): Promise<AnalytixData | null> {
        try {
            const response = await fetch(`${ANALYTIX_BASE_URL}/get-analytix-by-analytix-id?analytix_id=${analytixId}`, {
                headers: this.getHeaders()
            });

            if (!response.ok) return null;
            
            const data = await response.json();
            return {
                analytixId: data.ID,
                userId: data.UserID,
                tOpenness: data.TOpenness,
                tConscientiousness: data.TConscientiousness,
                tExtraversion: data.TExtraversion,
                tAgreeableness: data.TAgreeableness,
                tNeuroticism: data.TNeuroticism
            };
        } catch (error) {
            console.error('Failed to fetch analytix:', error);
            return null;
        }
    }
}