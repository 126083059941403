export const howSocialEnginesCanSolveTheLonelinessEpidemic = `5mark:
    implicit: Hello world! This is a message to the 5mark developer reading this. 5mark is a highly-stylized markup language being developed by PLATO5. Over time, this project will get better and better and we're excited to share this with the world. The is the first full markup of an article in 5mark and hopefully, you like the way it looks. The idea is to make styling and article writing easier anywhere you do it. Anyway, check out plato5.us, we're doing great stuff there too. -implicit
    template: plato5 article -template
        title: How Social Engines Can Solve the Loneliness Epidemic -title
        author: Nick Goldstein -author
        pos: Founder of PLATO5 -pos
        date: August 3rd, 2024 -date
            para: Social Media has fundamentally changed how we socialize on a day-to-day basis. Some may find this change to be wholly positive, but with soaring levels of loneliness in the USA, it’s worth considering whether or not this powerful technology is responsible for some of this social isolation. This issue likely isn’t going away on its own and innovative ideas will likely be the solution. PLATO5 is our attempt at providing a tool for people to conveniently connect with others with similar personality traits and interests, giving users a utility that can expand their social circle with limited obstacles. -para
            para: We view PLATO5 as a tool in its simplest form, not necessarily a platform or a vehicle for content creation. For this reason, I make a distinction between traditional Social Media and PLATO5. A Social Engine is a much more apt way of describing the type of company we\’re trying to build and the service that we\’re trying to provide to users. Facebook and other Social Media sites take measures to keep people scrolling on their site and make money off of how many eyeballs they can attract, gluing people to screens until they finally tire of doom-scrolling. PLATO5 will likely use advertising in the future to make a profit as well, however, our fundamental goal is to have users close their laptops, shut off their phones, and meet IRL. -para
            para: In an advisory from the United States Department of Health and Human Services entitled, Our Epidemic of Loneliness and Isolation, Dr. Vivek H. Murthy 19th and 21st Surgeon General of the United States describes the loneliness issue in detail, 
                quote: “In recent years, about one-in-two adults in America reported experiencing loneliness. And that was before the COVID-19 pandemic cut off so many of us from friends, loved ones, and support systems, exacerbating loneliness and isolation” -quote
                    *drop 
                        He continued laying out the health consequences of this social isolation, 
                    *drop
                quote: “Loneliness is far more than just a bad feeling—it harms both individual and societal health. It is associated with a greater risk of cardiovascular disease, dementia, stroke, depression, anxiety, and premature death. The mortality impact of being socially disconnected is similar to that caused by smoking up to 15 cigarettes a day, and even greater than that associated with obesity and physical inactivity.” -quote 
                    *drop
                        This is why a tool for connecting people in a reliable manner is so important. Clearly our society is lacking the social adhesive that it once had. We can speculate on why that is and blame Social Media sites that seek consumer’s constant attention or more and more services that neglect the traditional social components that made people talk to each other, but regardless of your interpretation of the issue, this problem can be mitigated by using the power of Social Media in a different way. PLATO5 seeks to be the solution to this issue and at the very least offer a way forward. 
            -para            
            para: Social Engines, like PLATO5, don&apos;t seek to get 100% of their user's attention for a quick buck from advertisers. We're a utility and we want to create a positive feedback loop built on the high-quality matches that we supply our users with using PIMS (Personality Interest Match System). We want users to meet friends in their area and fundamentally improve their lives and health and then come back to the app because they had such a great result and the content on the app is engaging.-para 
            para: Source: 
                @link: Our Epidemic of Loneliness and Isolation *to: https://www.hhs.gov/sites/default/files/surgeon-general-social-connection-advisory.pdf -link
            -para
-5mark`

export const gamifiedConversationsAndNanoBlogging = `5mark:
    template: plato5 article -template
    title: Gamified Conversations and Nano-Blogging -title
    author: Nick Goldstein -author
    pos: Founder of PLATO5 -pos
    date: August 3rd, 2024 -date
        para: Nano-blogging and Gamified Conversation are two sides of the same coin in our PlatoChat microservice. Nano-blogging is PLATO5’s more insular version of micro-blogging. While micro-blogging sites like X/Twitter allow users to send out thoughts into the abyss, a Nano-blog creates rooms of 4 users (daises) where users can start a more private and close-knit connection. Like micro-blogging sites/apps, nano-blogs will allow users to react to content and share interesting thoughts with other users, but the audience is much smaller, allowing users to focus on a handful of people and really create a positive connection. -para
        para: Gamified Conversation is another innovative method that PLATO5 uses to encourage users to socialize on the app. Certain features on the app require a user to expend points that can be gained from taking actions on the app that are known to drive engagement; for instance, posting a discussion or creating a CommIB (a Community-generated Icebreaker). These actions make PLATO5 a better community, which is why we want to encourage these choices. -para
        para: Keeping the conversation going despite an individual user’s lack of social acumen or discomfort with people is an essential part of PlatoChat and PLATO5 at large. We accomplish this by pushing users to react to content from the site frequently. It’s hard to start a conversation with nothing to talk about, which is why including discussions from our Kommin discussion board in PlatoChat allows users to talk about a wide variety of topics with ease. The integration of these services is paramount to getting users to talk, even when it’s difficult. Another way we help users keep the conversation going is by using CommIBs (colloquially called “Commibs” or “Icebreakers”), as mentioned previously. Users create and then vote on the best CommIBs on the app. When a user runs out of things to talk about, the CommIB button is always at the bottom of the screen. -para
        para: PlatoChat fully integrates these elements by pushing relevant content to users for them to react to and by using a points system to bolster engagement and encourage positive interactions between users. Some people may not necessarily be attracted to the idea of a points system being attached to their interactions, so this point system doesn't directly interfere with the standard and necessary processes of PlatoChat. However, for users that like gamification, the points system can encourage them to engage more frequently. By merging nano-blogging and gamified conversations, PlatoChat offers a unique and engaging experience for its users. This dual approach ensures that users can form close-knit connections while also being motivated to participate actively through a rewarding points system. Whether users seek a more intimate social experience or thrive on gamified interaction, PlatoChat caters to a variety of social needs, fostering a dynamic and supportive online community. -para
-5mark`

export const exploreThePlato5SuiteKomminPlatochatAndMore = `5mark:
    template: plato5 article -template
    title: Explore The PLATO5 Application Suite -title
    author: Nick Goldstein -author
    pos: Founder of PLATO5 -pos
    date: August 3rd, 2024 -date
        para: The PLATO5 application suite facilitates Platonic Relationships based on the Big 5 Personality Traits, location, and mutual interests. To accomplish this aim, the service includes a discussion board called Kommin, a nano-blogging/chat microservice called PlatoChat, the Analytix microservice that is the core of our proprietary personality analytics that stores and controls all personality data, and our authentication system. -para
        para: Below I go in-depth on these microservices and their capabilities: Our Nano-blogging service. Learn more about Nano-blogging and Gamified Conversation @link: here *to:https://plato5.us/about/gamified-conversations-and-nano-blogging-link.-para
        para: 
            bg=blue: PlatoChat -bg is the crux of the PLATO5 application. It's where people meet and start talking to each other. Instead of standard direct messaging, PlatoChat is an innovative means of communicating with others that leans heavily on reacting to community-generated content and provides a myriad of options for users that may have run out of things to talk about. PlatoChat keeps the conversation going and helps users talk about interesting topics that matter to them. -para
        para:
            bg=orange: Kommin -bg is our discussion board. This system allows users to interact more broadly within the community, while PlatoChat is a much narrower form of communication. Kommin uses iTags (interest tags) to track users' interests and apply it to PIMS (Personality Interest Match System) and create better matches over time. Kommin is also a great source of reaction content for users that are communicating in PlatoChat. These discussions are a great source of inspiration for conversations everywhere on the app. In addition, Kommin helps boost engagement and SEO. -para
        para: 
            bg=yellow: Analytix -bg holds the proprietary personality analytics data captured by PLATO5 and is primarily used to make high-quality matches using PIMS. It is also used to help users learn about themselves and their personalities and even share this info with others on the platform. -para
        para: 
            bg=purple: User Authentication -bg is a microservice that captures pertinent user data and allows us to keep track of our users' identities while using PLATO5. Data from this microservice feeds into practically every other microservice in this app. The identity model saved in this system is used to hold your personal data, like email, DOB, location, etc., and the ID is used in our other microservices to tie your discussions, your chats, your ratings, and much more to your profile. -para
        para: In conclusion, the PLATO5 suite, with its integrated microservices like PlatoChat, Kommin, and Analytix, offers a unique and engaging platform for fostering meaningful connections based on the Big 5 Personality Traits, location, and shared interests. By leveraging advanced personality analytics and innovative communication tools, PLATO5 not only facilitates interactions but also enriches them, ensuring users can connect on deeper, more personal levels. Our robust authentication system underpins the entire ecosystem, maintaining the integrity and security of user data. As we continue to evolve, PLATO5 remains committed to enhancing user experience and building a community where genuine relationships can thrive. -para
-5mark`
  
export const safetySuggestionsForOnlineMeetups = `5mark:
    template: plato5 article -template
    title: Safety Suggestions for Online Meetups -title
    author: The PLATO5 Team -author
    date: August 3rd, 2024 -date
    para: When connecting with new people online, it's important to prioritize safety to ensure a positive and secure experience. Here are some friendly tips to help you navigate online meetups with confidence: -para
    para:
        expr: Meet in Public Places -expr
        expd: Arrange initial meetups in public places and inform a friend or family member of your plans. Meeting in public places ensures safety and having someone know your plans adds an extra layer of security. -expd
    -para
    para: 
        expr: Trust Your Instincts -expr
        expd: Trust your instincts; if something feels off, don't hesitate to end the conversation or meeting. Your intuition is a powerful tool. If you feel uncomfortable, it's okay to leave. -expd 
    -para 
    para:
        expr: Report Suspicious Behavior -expr
        expd: Report any suspicious behavior to the platform's support team at support@plato5.us. Reporting helps keep the community safe and allows the platform to take necessary actions. -expd
    -para
    para:
        expr: Avoid Sharing Personal Information -expr
        expd: Avoid sharing personal information such as your address, phone number, or financial details. Keeping personal information private protects you from potential misuse. -expd
    -para
    para:
        expr: Set Boundaries and Communicate Them Clearly -expr
        expd: Let the other person know what you're comfortable with and respect their boundaries as well. Clear communication helps build mutual respect and understanding. -expd
    -para
    para:
        expr: Be Cautious About Links and Attachments -expr
        expd: Don’t click on unfamiliar links or open attachments from people you don't know well. Unfamiliar links and attachments can contain malware or phishing attempts. -expd
    -para
    para:
        expr: Educate Yourself About Common Scams -expr
        expd: Being aware of common online scams can help you recognize and avoid potential fraud. Knowledge about scams helps you stay vigilant and avoid falling victim to fraud. -expd
    -para
    para:
        expr: Keep Records of Interactions -expr
        expd: Save impoFrtant conversations or details in case you need to reference them later or report inappropriate behavior. Keeping records can be useful for reporting issues or recalling important details. -expd
    -para
    *drop
    para: By following these safety suggestions, you can enjoy the benefits of online meetups while prioritizing your well-being and security. Do additional research on online meetups and safety independently as well. These are not declarative statements, just helpful suggestions. Remember, your safety is important, and taking precautions can help you have a positive and enjoyable experience. -para
-5mark`