import { KomminService } from '../../actions/kommin/api-services';
import { ITagResponse } from '../../actions/kommin/models';
import { UINotifications } from '../../utilities/errors';
import { DiscussionsContent } from './discussions-content';

export class ITagContent {
    private element: HTMLDivElement;
    private discussionsContent: DiscussionsContent;

    constructor(discussionsContent: DiscussionsContent) {
        this.element = document.createElement('div');
        this.element.className = 'flex gap-2 overflow-x-auto pb-4 px-1 no-scrollbar cursor-grab active:cursor-grabbing';
        this.discussionsContent = discussionsContent;

        this.setupScrollBehavior();
        this.populateITags();
    }

    private setupScrollBehavior() {
        let isDown = false;
        let startX: number;
        let scrollLeft: number;
        let velocity = 0;
        let lastX: number;
        let timestamp: number;
        let rafId: number;

        this.element.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX;
            lastX = e.pageX;
            timestamp = Date.now();
            scrollLeft = this.element.scrollLeft;
            this.element.style.scrollBehavior = 'auto';
            cancelAnimationFrame(rafId);
        });

        this.element.addEventListener('mouseleave', () => {
            if (isDown) {
                isDown = false;
                startMomentumScroll();
            }
        });

        this.element.addEventListener('mouseup', () => {
            isDown = false;
            startMomentumScroll();
        });

        this.element.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            
            const dx = e.pageX - lastX;
            const dt = Date.now() - timestamp;
            velocity = dx / (dt || 1) * 20;
            
            this.element.scrollLeft -= dx;
            
            lastX = e.pageX;
            timestamp = Date.now();
        });

        const startMomentumScroll = () => {
            if (Math.abs(velocity) < 0.5) return;
            
            this.element.scrollLeft -= velocity;
            velocity *= 0.97;
            rafId = requestAnimationFrame(startMomentumScroll);
        };
    }

    private async populateITags() {
        try {
            const tags: ITagResponse[] | null = await KomminService.getDefaultTags();
            UINotifications.hideGlobalLoadingIndication();

            const mostRecentButton = this.createTagButton('Most Recent', 'black', () => {
                this.discussionsContent.loadMostRecentDiscussions();
                this.highlightTag(mostRecentButton);
            });
            this.element.appendChild(mostRecentButton);

            if (tags) {
                tags.forEach(tag => {
                    const tagEl = this.createTagButton(tag.Tag, this.getVibrantColor(), () => {
                        this.discussionsContent.loadDiscussionsByITag(tag.Tag);
                        this.highlightTag(tagEl);
                    });
                    this.element.appendChild(tagEl);
                });
            } else {
                console.error('Failed to load iTags');
            }
        } catch (error) {
            console.error('Error loading iTags:', error);
            UINotifications.showGlobalLoadingIndication();
            const errorMessage = document.createElement('div');
            errorMessage.textContent = 'Failed to load tags';
            this.element.appendChild(errorMessage);
        }
    }

    private createTagButton(text: string, color: string, onClick: () => void): HTMLButtonElement {
        const button = document.createElement('button');
        button.className = `
            shrink-0 px-4 py-1.5
            rounded-full
            font-medium
            text-sm
            text-white
            shadow-md
            transition-all duration-300
            hover:scale-103 active:scale-97
            snap-start
        `;
        button.textContent = text;
        button.style.backgroundColor = color;
        button.onclick = onClick;
        return button;
    }

    private highlightTag(selectedButton: HTMLButtonElement) {
        Array.from(this.element.children).forEach(child => {
            if (child instanceof HTMLButtonElement) {
                child.style.border = '';
            }
        });
        selectedButton.style.border = '1.5px solid white';
    }

    private getVibrantColor(): string {
        const colors = [
            '#FF4B6E', // Deep pink
            '#7C3AED', // Vibrant purple
            '#2563EB', // Rich blue
            '#059669', // Deep emerald
            '#DC2626', // Vivid red
            '#0891B2', // Teal blue
            '#BE123C', // Crimson
            '#16A34A', // Forest green
            '#6D28D9', // Royal purple
            '#EA580C', // Burnt orange
            '#0369A1', // Ocean blue
            '#B91C1C', // Ruby red
            '#15803D', // Pine green
            '#9333EA', // Electric purple
            '#0D9488'  // Dark teal
        ];
        return colors[Math.floor(Math.random() * colors.length)];
    }

    public mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }
}