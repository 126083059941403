import { AuthService } from "../../actions/auth/api-services";
import { UserData } from "../../actions/auth/models";

const welcomeMessages = [
    "Welcome,",
    "Salutations,",
    "Howdy,",
    "Hola,",
    "Hey,",
    "Hi there,",
    "Greetings,",
    "Wilkommen,",
    "Loading Pleasantries...",
    "Good to see you,",
    "G'day,",
    "Bonjour,",
    "Oh hi,",
    "Well hello,",
    "Sup,"
];

export class WelcomeText {
    private element: HTMLDivElement;

    constructor(userId: number) {
        this.element = document.createElement('div');
        this.element.className = `
            flex
            flex-col
            items-center
            justify-center
            gap-8
            text-center
            mt-16
            mb-4
            px-4
            w-full 
        `;

        const text = document.createElement('p');
        text.textContent = 'Loading...';
        text.className = `
            text-transparent bg-clip-text
            bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400
            text-2xl md:text-3xl lg:text-4xl
            font-extrabold
            font-['Inter']
            leading-relaxed
            max-w-3xl
            animate-gradient
            tracking-tight
            animate-fade-in
        `;

        this.element.appendChild(text);

        AuthService.getUserById(userId).then((userData: UserData | null) => {
            if (userData) {
                const greeting = welcomeMessages[Math.floor(Math.random() * welcomeMessages.length)];
                text.textContent = `${greeting} ${userData.name}`;
            } else {
                text.textContent = 'Welcome to P5!';
            }
        });
    }

    mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}