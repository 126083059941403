export class EmojiPicker {
    private element: HTMLElement;
    private onSelect: (emoji: string) => void;

    private readonly emojis = [
        '😁', '😅', '😂', '🥹', '🥲',
        '😇', '🥰', '😉', '😎', '🤓', 
        '🥳', '😜', '😖', '😳', '🤬',
        '😱', '😓', '🫣', '🤡', '🤢',
        '🥴', '🫵', '🤏', '✌️', '💪',
        '🫶', '🦴', '👀', '👅', '🙏',
        '👁', '💂‍♀️', '👮‍♂️', '👩‍💻', '👩‍🏫',
        '👨‍✈️', '🥷', '👩‍🔧', '🎅', '🧛‍♂️',
        '🙋‍♂️', '🙋‍♀️', '🤦‍♀️', '🤦‍♂️', '🤷‍♀️',
        '🤷‍♂️', '🕶', '☂️', '🪖', '💍',
        '🧣', '👟', '👕', '🍫', '☕️',
        '🍪', '🍥', '🥯', '🍓', '🍎',
        '🥒', '🥦', '🥬', '🍳', '🍾',
        '🎱', '🧗', '🎲', '🚁', '🚀',
        '🚦', '🏔', '🛝', '🏕', '🏝',
        '📱', '☎️', '⏳', '🧨', '🪤',
        '🪩', '🎉', '✅', '💯', '🏁',
        '🔊', '🔴', '🔘'
    ];

    constructor(onSelect: (emoji: string) => void) {
        this.element = document.createElement('div');
        this.onSelect = onSelect;
        this.render();
    }

    private render() {
        this.element.innerHTML = `
            <div class="p-6">
                <h3 class="text-lg font-bold mb-4 text-white">Choose an Emoji</h3>
                <div class="grid grid-cols-5 gap-3 mb-4 max-h-[300px] overflow-y-auto p-2">
                    ${this.emojis.map(emoji => `
                        <button 
                            class="w-12 h-12 flex items-center justify-center text-2xl
                            bg-white/5 hover:bg-white/10 rounded-xl transition-colors
                            hover:scale-110 active:scale-95"
                            data-emoji="${emoji}"
                        >
                            ${emoji}
                        </button>
                    `).join('')}
                </div>
            </div>
        `;

        // Add click handlers for emoji buttons
        const buttons = this.element.querySelectorAll('button');
        buttons.forEach(button => {
            button.addEventListener('click', () => {
                const emoji = button.dataset.emoji;
                if (emoji) {
                    this.onSelect(emoji);
                }
            });
        });
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}