import { UserData } from '../../actions/auth/models';
import { Button } from '../reusable/button';
import { ModalForm } from '../reusable/modal-form';
import { AuthContent } from './auth-content';
import { AnalytixService } from '../../actions/analytix/api-services';
import { AnalytixCollector } from '../analytix/analytix-collector';
import { UINotifications } from '../../utilities/errors';
import { AuthService } from '../../actions/auth/api-services';
import { UpdateUserContent } from './update-user-content';
import { GeolocationContent } from './geolocation-content';

export class ProfileContent {
    private element: HTMLElement;
    private authContent: AuthContent;
    private modalForm: ModalForm;
    private analytixCollector?: AnalytixCollector;
    private analytixData: Record<string, number> = {};

    private readonly traitColors: { [K in 'Openness' | 'Conscientiousness' | 'Extraversion' | 'Agreeableness' | 'Neuroticism']: string[] } = {
        'Openness': ['from-purple-500', 'to-pink-500'],
        'Conscientiousness': ['from-orange-500', 'to-yellow-300'],
        'Extraversion': ['from-blue-500', 'to-cyan-300'],
        'Agreeableness': ['from-green-500', 'to-emerald-300'],
        'Neuroticism': ['from-red-500', 'to-rose-300']
    };

    private readonly traitDescriptions = {
        'Openness': `Openness describes receptiveness to new ideas and experiences. High scorers are typically artistic, curious, and adventurous (think innovative entrepreneurs or creative artists), while low scorers prefer routine and familiar settings (like someone who eats the same breakfast daily and vacations at the same spot yearly).`,
        'Conscientiousness': `Conscientiousness relates to organization and responsibility. High scorers are methodical planners who maintain detailed calendars and clean spaces (like a detail-oriented accountant), while low scorers tend to be spontaneous and flexible but sometimes scattered (like a free-spirited artist who works in creative bursts).`,
        'Extraversion': `Extraversion measures energy derived from social interaction. High scorers thrive in group settings and seek excitement (picture an energetic party host), while low scorers prefer solitude and quiet reflection (like a focused researcher).`,
        'Agreeableness': `Agreeableness reflects concern for others' wellbeing. High scorers are empathetic and cooperative (think dedicated nurses or mediators), while low scorers tend to be competitive and direct (like tough negotiators or critics).`,
        'Neuroticism': `Neuroticism indicates emotional sensitivity and stress response. High scorers experience intense emotions and worry frequently (like someone who thoroughly plans for every contingency), while low scorers remain calm under pressure and recover quickly from setbacks (like a steady emergency responder).`
    };

    constructor(modalForm: ModalForm) {
        this.element = document.createElement('div');
        this.element.className = 'p-4 space-y-4';
        this.modalForm = modalForm;
        this.authContent = new AuthContent();
    }

    private async loadAnalytixData(): Promise<void> {
        try {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                this.renderAssessmentPrompt();
                return;
            }

            let analytixData = await AnalytixService.getAnalytixByUserId(parseInt(userId));
            
            if (!analytixData || 
                typeof analytixData.tOpenness !== 'number' ||
                typeof analytixData.tConscientiousness !== 'number' ||
                typeof analytixData.tExtraversion !== 'number' ||
                typeof analytixData.tAgreeableness !== 'number' ||
                typeof analytixData.tNeuroticism !== 'number') {
                
                this.renderAssessmentPrompt();
                return;
            }

            this.analytixData = {
                't_openness': analytixData.tOpenness / 100,
                't_conscientiousness': analytixData.tConscientiousness / 100,
                't_extraversion': analytixData.tExtraversion / 100,
                't_agreeableness': analytixData.tAgreeableness / 100,
                't_neuroticism': analytixData.tNeuroticism / 100
            };

            this.renderGraph();
        } catch (error) {
            this.renderAssessmentPrompt();
        }
    }

    private renderAssessmentPrompt(): void {
        const graphContainer = this.element.querySelector('#personality-graph');
        if (!graphContainer) return;

        graphContainer.innerHTML = `
            <div class="flex flex-col items-center justify-center gap-6 py-8">
                <h2 class="text-2xl font-bold text-white/90 text-center">
                    Personality Assessment
                </h2>
                <p class="text-white/70 text-center max-w-md">
                    You can take a quick or detailed version. This will enable you to filter by personality in our matching system.
                </p>
            </div>
        `;

        const button = new Button('Take Our Personality Assessment');
        button.getElement().className = 'px-6 py-3 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full text-white font-medium hover:from-purple-600 hover:to-pink-600 transition-all duration-300 hover:scale-105 shadow-lg hover:shadow-purple-500/20';
        
        button.getElement().addEventListener('click', () => {
            const userId = parseInt(localStorage.getItem('userId') || '0');
            if (!userId) {
                UINotifications.shipErrorToUI('Please log in to take the assessment');
                return;
            }

            this.analytixCollector = new AnalytixCollector(userId, (success: boolean, result: any) => {
                if (success && result) {
                    UINotifications.shipSuccessToUI('Assessment completed successfully!', { persist: true });
                    this.modalForm.close();
                    this.analytixData = {
                        't_openness': result.openness / 100,
                        't_conscientiousness': result.conscientiousness / 100,
                        't_extraversion': result.extraversion / 100,
                        't_agreeableness': result.agreeableness / 100,
                        't_neuroticism': result.neuroticism / 100
                    };
                    this.renderGraph();
                } else {
                    UINotifications.shipErrorToUI('Failed to complete assessment');
                }
            });

            this.modalForm.clear();
            this.modalForm.append(this.analytixCollector.getElement());
            this.modalForm.open();
        });
        
        button.mount(graphContainer.querySelector('div')!);
    }

    private renderGraph(): void {
        const graphContainer = this.element.querySelector('#personality-graph');
        if (!graphContainer) return;
        
        graphContainer.innerHTML = '';
        
        const title = document.createElement('h2');
        title.className = 'text-xl font-bold text-white/90 mb-6 text-center pb-6 pt-4';
        title.textContent = 'Personality Traits';
        graphContainer.appendChild(title);
        
        const barContainer = document.createElement('div');
        barContainer.className = 'relative h-60 md:h-80 min-h-[200px] flex items-end gap-[2px] mb-8';

        // Create bars for each trait
        const traits = {
            'Openness': this.analytixData.t_openness,
            'Conscientiousness': this.analytixData.t_conscientiousness,
            'Extraversion': this.analytixData.t_extraversion,
            'Agreeableness': this.analytixData.t_agreeableness,
            'Neuroticism': this.analytixData.t_neuroticism
        };

        Object.entries(traits).forEach(([trait, score]) => {
            const barWrapper = document.createElement('div');
            barWrapper.className = 'relative flex flex-col items-center gap-2 h-full flex-1';

            const bar = document.createElement('div');
            const height = Math.max(5, score * 100);
            bar.className = `absolute bottom-0 w-full bg-gradient-to-t ${this.traitColors[trait as keyof typeof this.traitColors].join(' ')} transition-all duration-500 cursor-pointer hover:opacity-90`;
            bar.style.height = `${height}%`;

            // Add click handler for trait description
            bar.addEventListener('click', () => {
                this.modalForm.clear();
                const content = document.createElement('div');
                content.className = 'p-6 max-w-lg mx-auto';
                content.innerHTML = `
                    <h3 class="text-xl font-bold text-white/90 mb-4">${trait}</h3>
                    <p class="text-white/70 leading-relaxed">
                        ${this.traitDescriptions[trait as keyof typeof this.traitDescriptions]}
                    </p>
                    <p class="text-white/90 mt-4 font-medium">
                        Your score: ${(score * 100).toFixed(2)}%
                    </p>
                `;
                this.modalForm.append(content);
                this.modalForm.open();
            });

            const label = document.createElement('div');
            label.className = 'absolute bottom-0 transform translate-y-full mt-2 text-white/70 text-xs md:text-sm text-center';
            label.innerHTML = `
                <span class="hidden md:inline">${trait}</span>
                <span class="inline md:hidden">${trait[0]}</span>
            `;

            const scoreLabel = document.createElement('div');
            scoreLabel.className = 'absolute top-0 transform -translate-y-full mb-2 text-white/90 text-xs md:text-sm font-medium py-1';
            scoreLabel.textContent = `${(score * 100).toFixed(2)}%`;

            barWrapper.appendChild(bar);
            barWrapper.appendChild(scoreLabel);
            barWrapper.appendChild(label);
            barContainer.appendChild(barWrapper);
        });

        graphContainer.appendChild(barContainer);

        // Add retake button with smaller size and more padding
        const button = new Button('Retake Personality Assessment');
        button.getElement().className = 'px-4 py-2 text-sm bg-gradient-to-r from-purple-500 to-pink-500 rounded-full text-white font-medium hover:from-purple-600 hover:to-pink-600 transition-all duration-300 hover:scale-105 shadow-lg hover:shadow-purple-500/20 mx-auto block';
        
        button.getElement().addEventListener('click', () => {
            const userId = parseInt(localStorage.getItem('userId') || '0');
            if (!userId) {
                UINotifications.shipErrorToUI('Please log in to take the assessment');
                return;
            }

            this.analytixCollector = new AnalytixCollector(userId, (success: boolean, result: any) => {
                if (success && result) {
                    UINotifications.shipSuccessToUI('Assessment completed successfully!', { persist: true });
                    this.modalForm.close();
                    this.analytixData = {
                        't_openness': result.openness / 100,
                        't_conscientiousness': result.conscientiousness / 100,
                        't_extraversion': result.extraversion / 100,
                        't_agreeableness': result.agreeableness / 100,
                        't_neuroticism': result.neuroticism / 100
                    };
                    this.renderGraph();
                } else {
                    UINotifications.shipErrorToUI('Failed to complete assessment');
                }
            });

            this.modalForm.clear();
            this.modalForm.append(this.analytixCollector.getElement());
            this.modalForm.open();
        });
        
        const buttonContainer = document.createElement('div');
        buttonContainer.className = 'text-center mt-8 mb-4';
        buttonContainer.appendChild(button.getElement());
        graphContainer.appendChild(buttonContainer);
    }

    private showUpdateForm(userData: UserData | null): void {
        this.modalForm.clear();
        const updateContent = new UpdateUserContent(userData, () => {
            this.modalForm.close();
            this.updateContent(JSON.parse(localStorage.getItem('userData') || '{}'));
            window.location.reload();
        });
        this.modalForm.append(updateContent.getElement());
        this.modalForm.open();
    }

    // Helper function to check if date is default
    private isDefaultDate(dateStr: string): boolean {
        const date = new Date(dateStr);
        return date.getFullYear() === 1 || date.getFullYear() === 0;
    }

    public async updateContent(userData: UserData | null) {
        const cards = [];

        if (!userData) {
            this.element.innerHTML = `
                <div class="flex flex-col items-center justify-center gap-8 py-12">
                    <p class="text-xl text-white/70 text-center">
                        Please login or signup to view your profile
                    </p>
                </div>
            `;

            const actionButton = new Button('Login or Signup');
            actionButton.getElement().onclick = () => {
                this.modalForm.append(this.authContent.getElement());
                this.modalForm.open();
            };
            actionButton.getElement().className = `
                px-8 py-3
                text-lg
                bg-purple-900
                hover:bg-purple-800
                rounded-full
                transition-all duration-300
                font-medium
                text-white/90
                shadow-lg
                hover:shadow-purple-500/20
                hover:scale-105
                animate-fade-in
            `;

            const buttonContainer = document.createElement('div');
            buttonContainer.className = 'flex justify-center';
            buttonContainer.appendChild(actionButton.getElement());
            this.element.appendChild(buttonContainer);
            
            return;
        }

        // Format date strings
        const lastLoginDate = !this.isDefaultDate(userData.lastLogin) 
            ? new Date(userData.lastLogin).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            })
            : null;
        
        const birthDate = !this.isDefaultDate(userData.dob)
            ? new Date(userData.dob).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
            : null;

        // Name is always shown, outside of cards
        this.element.innerHTML = `
            <div class="flex flex-col gap-3 mb-6 pt-2">
                <h1 class="text-xl font-bold text-white">${userData.name}</h1>
                <button class="w-fit px-3 py-1.5 bg-gradient-to-r from-blue-500 to-blue-600 rounded-full text-sm text-white font-medium hover:from-blue-600 hover:to-blue-700 transition-all duration-300">
                    Update Details
                </button>
            </div>
            <div class="mb-8" id="personality-graph"></div>
        `;

        // Load analytix data if user is logged in
        if (userData) {
            await this.loadAnalytixData();
        }

        // Intro gets its own card
        if (userData.intro) {
            cards.push(`
                <div class="bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5">
                    <div class="flex items-center gap-3">
                        <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                            <svg class="w-4 h-4 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                            </svg>
                        </div>
                        <div>
                            <div class="text-sm text-white/50">Intro</div>
                            <div class="text-white">${userData.intro}</div>
                        </div>
                    </div>
                </div>
            `);
        }

        if (userData.email) {
            cards.push(`
                <div class="bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5">
                    <div class="flex items-center gap-3">
                        <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                            <svg class="w-4 h-4 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                            </svg>
                        </div>
                        <div>
                            <div class="text-sm text-white/50">Email</div>
                            <div class="text-white">${userData.email}</div>
                        </div>
                    </div>
                </div>
            `);
        }

        if (userData.dob && birthDate) {
            cards.push(`
                <div class="bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5">
                    <div class="flex items-center gap-3">
                        <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                            <svg class="w-4 h-4 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                            </svg>
                        </div>
                        <div>
                            <div class="text-sm text-white/50">Birth Date</div>
                            <div class="text-white">${birthDate}</div>
                        </div>
                    </div>
                </div>
            `);
        }

        if (userData.location) {
            cards.push(`
                <div class="bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5">
                    <div class="flex items-center gap-3">
                        <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                            <svg class="w-4 h-4 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                        </div>
                        <div>
                            <div class="text-sm text-white/50">Location</div>
                            ${userData.location ? `<div class="text-white">${userData.location}</div>` : ''}
                        </div>
                    </div>
                </div>
            `);
        }

        if (userData.country) {
            cards.push(`
                <div class="bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5">
                    <div class="flex items-center gap-3">
                        <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                            <svg class="w-4 h-4 text-indigo-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"/>
                            </svg>
                        </div>
                        <div>
                            <div class="text-sm text-white/50">Country</div>
                            ${userData.country ? `<div class="text-white">${userData.country}</div>` : ''}
                        </div>
                    </div>
                </div>
            `);
        }

        if (userData.instagramLink) {
            cards.push(`
                <div class="bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5">
                    <div class="flex items-center gap-3">
                        <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                            <svg class="w-4 h-4 text-pink-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"/>
                            </svg>
                        </div>
                        <div>
                            <div class="text-sm text-white/50">Instagram</div>
                            <div class="text-white">${userData.instagramLink}</div>
                        </div>
                    </div>
                </div>
            `);
        }

        if (userData.referralCode) {
            cards.push(`
                <div class="bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5">
                    <div class="flex items-center gap-3">
                        <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                            <svg class="w-4 h-4 text-indigo-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"/>
                            </svg>
                        </div>
                        <div>
                            <div class="text-sm text-white/50">Referral Code</div>
                            <div class="text-white font-mono">${userData.referralCode}</div>
                        </div>
                    </div>
                </div>
            `);
        }

        if (userData.lastLogin && lastLoginDate) {
            cards.push(`
                <div class="bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5">
                    <div class="flex items-center gap-3">
                        <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                            <svg class="w-4 h-4 text-orange-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        </div>
                        <div>
                            <div class="text-sm text-white/50">Last Login</div>
                            <div class="text-white">${lastLoginDate}</div>
                        </div>
                    </div>
                </div>
            `);
        }

        if (cards.length === 0) {
            this.element.innerHTML = `
                <div class="flex flex-col items-center justify-center gap-8 py-12">
                    <p class="text-xl text-white/70 text-center">
                        Please login or signup to view your profile
                    </p>
                </div>
            `;

            const actionButton = new Button('Login or Signup');
            actionButton.getElement().onclick = () => {
                this.modalForm.append(this.authContent.getElement());
                this.modalForm.open();
            };
            actionButton.getElement().className = `
                px-8 py-3 text-lg bg-purple-900 hover:bg-purple-800 rounded-full
                transition-all duration-300 font-medium text-white/90 shadow-lg
                hover:shadow-purple-500/20 hover:scale-105 animate-fade-in
            `;

            const buttonContainer = document.createElement('div');
            buttonContainer.className = 'flex justify-center';
            buttonContainer.appendChild(actionButton.getElement());
            this.element.appendChild(buttonContainer);
            return;
        }

        // Append cards to element
        this.element.innerHTML += `
            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                ${cards.join('')}
            </div>
        `;

        // After setting the personality-graph div
        await this.loadAnalytixData();

        // Add click handler for Update Details button
        const updateButton = this.element.querySelector('button');
        if (updateButton) {
            updateButton.addEventListener('click', () => this.showUpdateForm(userData));
        }

        // After appending the cards, add the location button section
        const locationSection = document.createElement('div');
        locationSection.className = 'mt-6 bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5 flex items-center justify-between';

        const locationTitle = document.createElement('div');
        locationTitle.className = 'text-sm text-white/90';
        locationTitle.textContent = userData.geohash 
            ? 'Location Enabled' 
            : 'Setup Location For Better Matches';

        const locationButton = new Button(userData.geohash ? 'Update Location' : 'Activate Location');
        locationButton.getElement().className = userData.geohash
            ? `px-3 py-1.5 text-sm bg-gradient-to-r from-green-500 to-emerald-500 
               rounded-full text-white font-medium hover:from-green-600 
               hover:to-emerald-600 transition-all duration-300 hover:scale-[1.02] 
               shadow-md hover:shadow-green-500/20`
            : `px-3 py-1.5 text-sm bg-gradient-to-r from-red-500 to-rose-500 
               rounded-full text-white font-medium hover:from-red-600 
               hover:to-rose-600 transition-all duration-300 hover:scale-[1.02] 
               shadow-md hover:shadow-red-500/20`;

        locationButton.getElement().addEventListener('click', () => {
            const userId = parseInt(localStorage.getItem('userId') || '0');
            if (!userId) {
                UINotifications.shipErrorToUI('Please log in to update location');
                return;
            }

            const geolocationContent = new GeolocationContent(userId, (success: boolean) => {
                if (success) {
                    this.modalForm.close();
                    // Refresh the profile content
                    const updatedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
                    this.updateContent(updatedUserData);
                }
            });

            this.modalForm.clear();
            this.modalForm.append(geolocationContent.getElement());
            this.modalForm.open();
        });

        locationSection.appendChild(locationTitle);
        locationSection.appendChild(locationButton.getElement());
        this.element.appendChild(locationSection);
    }

    public mount(parent: HTMLElement) {
        parent.appendChild(this.element);
        this.modalForm.mount(document.body);
    }

    public unmount() {
        this.element.remove();
        this.modalForm.close();
    }
}