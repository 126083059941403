// utilities/5mark-parser.ts
import { DiscussionResponse } from "../actions/kommin/models";
import { UINotifications } from "./errors";
import '../styles/5mark.css';

/**
 * Formats and color codes the raw 5mark content.
 * @param rawContent - The raw 5mark string.
 * @returns The formatted and color-coded HTML string.
 */
function formatRaw5mark(rawContent: string): string {
    return rawContent
        .replace(/5mark:/g, '<span style="color: #c084fc;">5mark:</span>\n')
        .replace(/-5mark/g, '\n<span style="color: #c084fc;">-5mark</span>\n')
        .replace(/title:(.*?)-title/g, '<span style="color: #818cf8;">title:</span> $1 <span style="color: #818cf8;">-title</span>\n')
        .replace(/author:(.*?)-author/g, '<span style="color: #fbbf24;">author:</span> $1 <span style="color: #fbbf24;">-author</span>\n')
        .replace(/pos:(.*?)-pos/g, '<span style="color: #fbbf24;">pos:</span> $1 <span style="color: #fbbf24;">-pos</span>\n')
        .replace(/date:(.*?)-date/g, '<span style="color: #fbbf24;">date:</span> $1 <span style="color: #fbbf24;">-date</span>\n')
        .replace(/para( clean)?:\s*(.*?)-para/g, '<span style="color: #38bdf8;">para$1:</span> $2 <span style="color: #38bdf8;">-para</span>\n')
        .replace(/-para/g, '<span style="color: #38bdf8;">-para</span>\n')
        .replace(/quote:(.*?)-quote/g, '<span style="color: #f59e0b;">quote:</span> $1 <span style="color: #f59e0b;">-quote</span>\n')
        .replace(/\*drop/g, '<span style="color: #ff6347;">*drop</span>\n')
        .replace(/link:(.*?)-link/g, '<span style="color: #ec4899;">link:</span> $1 <span style="color: #ec4899;">-link</span>\n')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, '&nbsp;');
}

/**
 * Parses 5mark content into HTML.
 * @param entry - The 5mark formatted string.
 * @returns The HTML string.
 */
export function parse5mark(entry: string): HTMLElement {
    console.log('Parsing 5mark content:', entry); // Debug log

    const original5markContent = entry;
    const errors: string[] = [];

    // Check for required tags
    if (!/5mark:/.test(entry)) {
        errors.push("Oops! It looks like the 5mark tag is missing. Please ensure your markup starts correctly.");
    }
    if (!/-5mark/.test(entry)) {
        errors.push("Oops! It looks like the -5mark tag is missing. Please make sure to close your markup file properly.");
    }

    // Validate template
    const templateError = validateTemplate(entry);
    if (templateError) {
        errors.push(templateError);
    }

    // Get template configuration
    const templateMatch = entry.match(/template:(.*?)-template/);
    const template = templateMatch ? templateMatch[1].trim() as TemplateType : 'plato5 article';
    const config = templateConfigs[template];

    // Check for duplicate tags
    const checkDuplicates = (tag: string, name: string) => {
        const matches = entry.match(new RegExp(tag, 'g'));
        if (matches && matches.length > 1) {
            errors.push(`Multiple ${name} tags found. Only one ${name} tag is allowed.`);
        }
    };

    // Check for required single-instance tags
    checkDuplicates('title:.*?-title', 'title');
    checkDuplicates('author:.*?-author', 'author');
    checkDuplicates('date:.*?-date', 'date');
    checkDuplicates('rating:.*?-rating', 'rating');
    checkDuplicates('\\*slider', 'slider');

    if (errors.length > 0) {
        const errorContainer = document.createElement('div');
        errorContainer.innerHTML = `
            <div class="mt-8 bg-red-500/20 border border-red-500/50 text-red-700 p-4 rounded-lg backdrop-blur-md">
                <h2 class="text-2xl font-bold mb-2">5mark Parsing Error</h2>
                <p class="text-sm text-red-700 mb-2">5mark is a stylized markup language used to format content for the web.</p>
                <p class="text-sm text-red-700 mb-4">Please ensure your 5mark content includes the necessary tags and correct any issues listed below.</p>
                <ul class="list-disc pl-5">
                    ${errors.map(error => `<li>${error}</li>`).join('')}
                </ul>
            </div>
        `;
        return errorContainer;
    }

    const container = document.createElement('div');
    container.className = `five-mark-container ${template.replace(' ', '-')}`;

    // Remove 5mark and template tags
    entry = entry
        .replace(/5mark:/g, '')
        .replace(/-5mark/g, '')
        .replace(/template:.*?-template\s*/gs, '');

    // First, remove template content completely (not just the tags)
    entry = entry.replace(/template:.*?-template/g, '');

    // Then modify the metadata replacements to escape any nested styling tags
    entry = entry.replace(/title:(.*?)-title/g, (match, content) => {
        // Escape any styling tags within the title
        const cleanContent = content.replace(/[bi]:(.*?)-[bi]/g, '$1');
        return `<h1 class="text-3xl md:text-4xl font-bold mb-3 five-mark-title">${cleanContent}</h1>`;
    });

    // Do the same for author, position, and date
    entry = entry.replace(/author:(.*?)-author/g, (match, content) => {
        const cleanContent = content.replace(/[biu]:(.*?)-[biu]/g, '$1');
        return `<span class="italic text-base font-semibold five-mark-meta">${cleanContent}</span>`;
    });

    entry = entry.replace(/pos:(.*?)-pos/g, (match, content) => {
        const cleanContent = content.replace(/[biu]:(.*?)-[biu]/g, '$1');
        return `<span class="text-base five-mark-meta">${cleanContent}</span>`;
    });

    entry = entry.replace(/date:(.*?)-date/g, (match, content) => {
        const cleanContent = content.replace(/[biu]:(.*?)-[biu]/g, '$1');
        return `<span class="text-base five-mark-meta">${cleanContent}</span>`;
    });

    // Replace paragraphs with template-specific styling
    let firstParaProcessed = false;
    entry = entry.replace(/para( clean)?:\s*(.*?)-para/gs, (match, clean, content) => {
        if (!firstParaProcessed) {
            firstParaProcessed = true;
            const firstChar = content.charAt(0);
            const restOfContent = content.slice(1);
            return `
                <p class="five-mark-paragraph">
                    <span class="five-mark-first-character">
                        ${firstChar}
                    </span>
                    ${restOfContent}
                </p>
            `;
        } else {
            return `<p class="five-mark-paragraph">${content}</p>`;
        }
    });

    // Handle *drop self-closing tag for line break
    entry = entry.replace(/\*drop/g, '<br/><br/>');

    // Define metaClass based on template
    const metaClass = template === 'newsprint' ? 'text-black/60' : 'text-white/60';

    // Combine author, position, and date with " | "
    entry = entry.replace(/<\/span>\s*<span/g, '</span> | <span');

    // Replace link with styled <a> tags
    entry = entry.replace(/@link:(.*?)\s*\*to:(.*?)\s*-link/g, '<a href="$2" class="text-blue-400 hover:underline">$1</a>');

    // Handle [IMPLICIT] and other custom tags as needed
    entry = entry.replace(/implicit:(.*?)-implicit/g, '<div class="hidden implicit-content">$1</div>');

    // Replace [EXPR] with a clickable header for the expander
    entry = entry.replace(/expr:(.*?)-expr/g, `
        <div class="expander-header flex justify-between items-center cursor-pointer five-mark-expander p-4 ${template !== 'newsprint' ? 'rounded-lg' : ''} shadow-lg text-white font-semibold">
            <span>$1</span>
            <span class="collapsible-icon">+</span>
        </div>
    `);

    // Replace [EXPD] with the content of the expander
    entry = entry.replace(/expd:(.*?)-expd/g, `
        <div class="expander-content hidden bg-black/20 backdrop-blur-md p-4 rounded-lg shadow-inner text-white transition-max-height duration-300 ease-in-out overflow-hidden max-h-0">
            $1
        </div>
    `);

    // Replace [QUOTE] with styled blockquote
    entry = entry.replace(/quote:(.*?)-quote/g, `
        <blockquote class="ml-4 mr-4 quote p-4 rounded-lg text-white italic">
            $1
        </blockquote>
    `);

    // Replace [BG=BLUE] with Tailwind classes for glassmorphism
    entry = entry.replace(/bg=blue:(.*?)-bg/g, `
        <div class="bg-gradient-to-r from-blue-900/60 to-blue-500/60 backdrop-blur-lg p-2 rounded-xl shadow-lg text-white inline-block">
            $1
        </div>
    `);

    // Replace [BG=YELLOW] with Tailwind classes for glassmorphism
    entry = entry.replace(/bg=yellow:(.*?)-bg/g, `
        <div class="bg-gradient-to-r from-yellow-600/60 to-yellow-400/60 backdrop-blur-lg p-2 rounded-xl shadow-lg text-white inline-block">
            $1
        </div>
    `);

    // Replace [BG=ORANGE] with Tailwind classes for glassmorphism
    entry = entry.replace(/bg=orange:(.*?)-bg/g, `
            <div class="bg-gradient-to-r from-orange-600/60 to-orange-400/60 backdrop-blur-lg p-2 rounded-xl shadow-lg text-white inline-block">
            $1
        </div>
    `);

    // Replace [BG=PURPLE] with Tailwind classes for glassmorphism
    entry = entry.replace(/bg=purple:(.*?)-bg/g, `
        <div class="bg-gradient-to-r from-purple-800/60 to-purple-600/60 backdrop-blur-lg p-2 rounded-xl shadow-lg text-white inline-block">
            $1
        </div>
    `);

        // Add styled button
        entry = entry.replace(/@button:(.*?)\s*\*action:(.*?)\s*-button/g, `
            <button 
                class="five-mark-button ${template !== 'newsprint' ? 'rounded-lg' : ''} shadow-lg transition-all duration-300 hover:scale-105"
                onclick="$2"
            >
                $1
            </button>
        `);

                        // Handle rating tag
    entry = entry.replace(/rating:(.*?)-rating/g, (match, rating) => {
        const numRating = parseFloat(rating);
        const isPositive = numRating > 50;
        const displayRating = isPositive ? `+${numRating}` : `-${numRating}`;
        const colorClass = template === 'newsprint' 
            ? (isPositive ? 'text-green-800' : 'text-red-800')
            : (isPositive ? 'text-green-500' : 'text-red-500');
        
        return `
            <div class="flex items-center justify-between p-4 ${template === 'newsprint' ? 'border border-black/10' : 'bg-black/20'} ${template !== 'newsprint' ? 'rounded-lg' : ''} mb-4">
                <span class="${metaClass}">Rating</span>
                <span class="text-2xl font-bold ${colorClass}">${displayRating}</span>
            </div>
        `;
    });

        // Add text styling tags
        entry = entry.replace(/b:(.*?)-b/g, '<span class="font-bold">$1</span>');
        entry = entry.replace(/i:(.*?)-i/g, '<span class="italic">$1</span>');
        entry = entry.replace(/u:(.*?)-u/g, '<span class="underline">$1</span>');
    

    // Add a button for showing raw 5mark content in a modal
    const button = document.createElement('button');
    button.id = 'show-raw-5mark';
    button.className = 'fixed bottom-4 right-4 bg-blue-500 text-white px-2 py-1 rounded text-sm';
    button.textContent = 'Raw 5mark';
    button.addEventListener('click', () => {
        const modal = new SimpleModal();
        modal.open(original5markContent); // Pass the original content
    });

    container.innerHTML = entry;
    container.appendChild(button);

    // Add event listener for expanders
    container.addEventListener('click', (event) => {
        const target = event.target as HTMLElement;
        if (target.closest('.expander-header')) {
            const content = target.closest('.expander-header')!.nextElementSibling as HTMLElement;
            if (content) {
                content.classList.toggle('hidden');
                content.style.maxHeight = content.classList.contains('hidden') ? '0' : `${content.scrollHeight}px`;
                const icon = target.querySelector('.collapsible-icon') as HTMLElement;
                if (icon) {
                    icon.textContent = content.classList.contains('hidden') ? '+' : '−';
                }
            }
        }
    });

    return container;
}

/**
 * Extracts an excerpt from the content within [PARA] tags.
 * @param entry - The 5mark formatted string.
 * @param characterLength - The desired length of the excerpt.
 * @returns The excerpt string.
 */
export function build5MarkExcerpt(entry: string, characterLength: number): string {
    // Use a regular expression to capture content within [PARA] tags
    const paraMatches = entry.match(/\para:(.*?)-para/gs);

    if (!paraMatches) {
        return ''; // Return an empty string if no [PARA] tags are found
    }

    // Concatenate all matched [PARA] content with a space between paragraphs
    const fullText = paraMatches.map(match => {
        const contentMatch = match.match(/\para:(.*?)-para/s);
        return contentMatch ? contentMatch[1].trim() : '';
    }).join(' ');

    // Find the last space within the specified character length
    const excerpt = fullText.substring(0, characterLength);
    const lastSpaceIndex = excerpt.lastIndexOf(' ');

    // If a space is found, truncate at the last space; otherwise, use the full excerpt
    const truncatedExcerpt = lastSpaceIndex > 0 ? excerpt.substring(0, lastSpaceIndex) : excerpt;

    // Add ellipsis if the excerpt is shorter than the full text
    return truncatedExcerpt.length < fullText.length ? truncatedExcerpt + '...' : truncatedExcerpt;
}

class SimpleModal {
    private overlay: HTMLDivElement;
    private modal: HTMLDivElement;

    constructor() {
        // Create overlay
        this.overlay = document.createElement('div');
        this.overlay.className = `
            fixed inset-0 bg-black/50 backdrop-blur-sm opacity-0 transition-opacity duration-300 hidden z-[9998]
        `;

        // Create modal
        this.modal = document.createElement('div');
        this.modal.className = `
            fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 backdrop-blur-lg bg-white/10 dark:bg-black/30
            rounded-xl border border-white/10 shadow-2xl p-8 scale-95 opacity-0 transition-all duration-300 hidden
            w-[60%] h-[80%] z-[9999] flex flex-col
        `;

        // Add close button
        const closeButton = document.createElement('button');
        closeButton.innerHTML = '×';
        closeButton.className = `
            absolute top-2 right-2 text-red-500 hover:text-red-400 text-3xl w-8 h-8 flex items-center justify-center cursor-pointer transition-colors font-bold
        `;
        closeButton.addEventListener('click', () => this.close());
        this.modal.appendChild(closeButton);

        // Close on overlay click
        this.overlay.addEventListener('click', () => this.close());
    }

    public open(content: string) {
        this.modal.innerHTML = ''; // Clear previous content

        // Add title and subheading
        const title = document.createElement('h2');
        title.textContent = 'Raw 5mark';
        title.className = 'text-2xl font-bold mb-2 text-white';
        this.modal.appendChild(title);

        const subheading = document.createElement('p');
        subheading.innerHTML = `5mark is <a href="https://plato5.us" class="text-blue-400 hover:underline">PLATO5</a>'s custom markup language to allow for easy, manageable, and stylized article building.`;
        subheading.className = 'text-sm text-white/70 mb-4';
        this.modal.appendChild(subheading);

        // Add code element
        const codeContainer = document.createElement('div');
        codeContainer.className = 'flex-grow overflow-auto mb-4'; // Make it grow and scrollable
        const codeElement = document.createElement('pre');
        codeElement.innerHTML = formatRaw5mark(content); // Use formatted HTML for display
        codeElement.className = 'p-4 bg-gray-900 text-white rounded-lg font-mono text-sm';
        codeContainer.appendChild(codeElement);
        this.modal.appendChild(codeContainer);

        // Add copy icon
        const copyIcon = document.createElement('button');
        copyIcon.innerHTML = '<i class="fas fa-copy"></i>'; // Font Awesome icon
        copyIcon.className = 'bg-black text-white p-5 rounded-full text-sm absolute bottom-4 right-4';
        copyIcon.addEventListener('click', () => {
            navigator.clipboard.writeText(content); // Copy raw 5mark content
            UINotifications.shipHintToUI('Copied to clipboard!');
        });
        this.modal.appendChild(copyIcon);

        this.modal.appendChild(this.createCloseButton());

        document.body.appendChild(this.overlay);
        document.body.appendChild(this.modal);

        this.overlay.classList.remove('hidden');
        this.modal.classList.remove('hidden');
        setTimeout(() => {
            this.overlay.classList.remove('opacity-0');
            this.modal.classList.remove('opacity-0', 'scale-95');
        }, 10);
    }

    public close() {
        this.overlay.classList.add('opacity-0');
        this.modal.classList.add('opacity-0', 'scale-95');
        setTimeout(() => {
            this.overlay.classList.add('hidden');
            this.modal.classList.add('hidden');
            document.body.removeChild(this.overlay);
            document.body.removeChild(this.modal);
        }, 300);
    }

    private createCloseButton(): HTMLButtonElement {
        const closeButton = document.createElement('button');
        closeButton.innerHTML = '×';
        closeButton.className = `
            absolute top-2 right-2 text-red-500 hover:text-red-400 text-3xl w-8 h-8 flex items-center justify-center cursor-pointer transition-colors font-bold
        `;
        closeButton.addEventListener('click', () => this.close());
        return closeButton;
    }
}

export function build5markFromDiscussion(discussion: DiscussionResponse): string {
    const truncatedTitle = discussion.Body.substring(0, 20) + (discussion.Body.length > 20 ? '...' : '');
    const paragraphs = discussion.Body.split('\n').filter(p => p.trim().length > 0);
    const paraTags = paragraphs.map(p => `para:${p.trim()}-para`).join('\n');
    
    return `
        5mark:
            template:kommin discussion-template
            implicit:Interaction Count: ${discussion.InteractionCount}, Average Rating: ${discussion.AverageRating}-implicit
                title:${truncatedTitle}-title
                author:${discussion.Author}-author
                date:${discussion.Timestamp}-date
                    ${paraTags}
            *drop
            rating:${discussion.AverageRating}-rating
            *drop
        -5mark
    `
}

// First, let's define our template types and their configurations
type TemplateType = 'kommin discussion' | 'newsprint' | 'plato5 article';

interface TemplateConfig {
    gradientColors: string[];
    buttonClass: string;
    expanderClass: string;
    quoteClass: string;
}

const templateConfigs: Record<TemplateType, TemplateConfig> = {
    'plato5 article': {
        gradientColors: ['#c084fc', '#818cf8', '#38bdf8', '#818cf8', '#c084fc'],
        buttonClass: 'bg-purple-500/60 text-white px-4 py-2',
        expanderClass: 'bg-purple-900/60',
        quoteClass: 'border-l-4 border-purple-500 pl-4 my-4 italic'
    },
    'kommin discussion': {
        gradientColors: ['#f97316', '#fbbf24', '#f97316'],
        buttonClass: 'bg-orange-500/60 text-white px-4 py-2',
        expanderClass: 'bg-orange-900/60',
        quoteClass: 'border-l-4 border-orange-500 pl-4 my-4 italic'
    },
    'newsprint': {
        gradientColors: ['#000000'],
        buttonClass: 'bg-gray-900 text-white px-4 py-2',
        expanderClass: 'bg-gray-900',
        quoteClass: 'border-l-4 border-gray-900 pl-4 my-4 italic'
    }
};

// Add template validation to the error checks
function validateTemplate(entry: string): string | null {
    const templateMatch = entry.match(/template:(.*?)-template/);
    if (!templateMatch) {
        return "Template tag is missing. Please specify a template (kommin discussion, newsprint, or plato5 article).";
    }
    
    const template = templateMatch[1].trim() as TemplateType;
    if (!templateConfigs[template]) {
        return "Invalid template specified. Available templates: kommin discussion, newsprint, plato5 article";
    }
    
    return null;
}

