import { PlatoChatService } from '../../../actions/platochat/api-services';
import { ChatResponse } from '../../../actions/platochat/models';

export class ReplySelectionContent {
    private element: HTMLElement;
    private onSelectCallback: (chat: ChatResponse) => void;
    private nanoId: number;

    constructor(nanoId: number, onSelect: (chat: ChatResponse) => void) {
        this.nanoId = nanoId;
        this.onSelectCallback = onSelect;
        this.element = document.createElement('div');
        this.element.className = 'p-6 max-h-[60vh] overflow-y-auto';
        this.initialize();
    }

    private async initialize() {
        this.element.innerHTML = '<div class="animate-pulse text-white/60">Loading messages...</div>';
        
        try {
            const chats = await PlatoChatService.getChats(this.nanoId, 0);
            if (!chats || chats.length === 0) {
                this.element.innerHTML = '<div class="text-white/60">No messages to reply to</div>';
                return;
            }

            this.element.innerHTML = `
                <div class="text-lg text-white/90 mb-4">Select a message to reply to</div>
                <div class="flex flex-col gap-3">
                    ${chats.map(chat => `
                        <button class="text-left p-4 rounded-xl bg-white/5 border border-white/10 
                            hover:bg-white/10 transition-colors group"
                            data-chat-id="${chat.ID}">
                            <div class="flex items-center gap-2 mb-2">
                                <span class="text-sm text-white/90">${chat.author}</span>
                                <span class="text-xs text-white/50">
                                    ${new Date(chat.timestamp).toLocaleTimeString()}
                                </span>
                            </div>
                            <div class="text-sm text-white/80">
                                ${chat.content.length > 100 ? chat.content.substring(0, 100) + '...' : chat.content}
                            </div>
                            <div class="mt-2 text-xs text-white/40 group-hover:text-white/60">
                                Click to reply
                            </div>
                        </button>
                    `).join('')}
                </div>
            `;

            // Add click handlers
            this.element.querySelectorAll('button').forEach(button => {
                button.addEventListener('click', () => {
                    const chatId = button.getAttribute('data-chat-id');
                    const selectedChat = chats.find(c => c.ID.toString() === chatId);
                    if (selectedChat) {
                        this.onSelectCallback(selectedChat);
                    }
                });
            });

        } catch (error) {
            console.error('Failed to load chats:', error);
            this.element.innerHTML = '<div class="text-red-400">Failed to load messages</div>';
        }
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}