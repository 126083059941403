type NotificationType = 'error' | 'success' | 'hint';

interface NotificationOptions {
    duration?: number;
    position?: 'top' | 'bottom';
    showIcon?: boolean;
    action?: {
        text: string;
        onClick: () => void;
    };
}

interface StoredMessage {
    message: string;
    type: NotificationType;
}

export class UINotifications {
    private static readonly DEFAULT_DURATION = 5000; // 5 seconds
    private static container: HTMLDivElement | null = null;
    private static loadingBar: HTMLDivElement | null = null;
    private static loadingText: HTMLDivElement | null = null;

    private static createContainer() {
        if (this.container) return;

        this.container = document.createElement('div');
        this.container.className = `
            fixed right-0 top-0 m-4 
            z-[99999] flex flex-col gap-2 
            items-end pointer-events-none
        `;
        document.body.appendChild(this.container);
    }

    private static createNotification(message: string, type: NotificationType, options: NotificationOptions = {}) {
        this.createContainer();
        
        const notification = document.createElement('div');
        notification.className = `
            flex items-center gap-3 px-4 py-3 
            rounded-lg shadow-lg backdrop-blur-md 
            animate-slide-in pointer-events-auto
            ${this.getTypeStyles(type)}
        `;

        // Icon
        if (options.showIcon !== false) {
            const icon = document.createElement('span');
            icon.className = this.getIconClass(type);
            notification.appendChild(icon);
        }

        // Message
        const messageEl = document.createElement('p');
        messageEl.className = 'text-sm font-medium';
        messageEl.textContent = message;
        notification.appendChild(messageEl);

        // Action button
        if (options.action) {
            const button = document.createElement('button');
            button.className = 'ml-4 text-sm font-semibold hover:opacity-80 transition-opacity';
            button.textContent = options.action.text;
            button.onclick = options.action.onClick;
            notification.appendChild(button);
        }

        this.container?.appendChild(notification);

        // Remove after duration
        setTimeout(() => {
            notification.classList.add('animate-slide-out');
            setTimeout(() => notification.remove(), 300);
        }, options.duration || this.DEFAULT_DURATION);
    }

    private static getTypeStyles(type: NotificationType): string {
        switch (type) {
            case 'error':
                return 'bg-red-500/10 border border-red-500/20 text-red-500';
            case 'success':
                return 'bg-green-500/10 border border-green-500/20 text-green-500';
            case 'hint':
                return 'bg-blue-500/10 border border-blue-500/20 text-blue-500';
        }
    }

    private static getIconClass(type: NotificationType): string {
        const baseClass = 'text-lg';
        switch (type) {
            case 'error':
                return `${baseClass} fas fa-exclamation-circle`;
            case 'success':
                return `${baseClass} fas fa-check-circle`;
            case 'hint':
                return `${baseClass} fas fa-info-circle`;
        }
    }

    static shipErrorToUI(message: string, options?: NotificationOptions & { persist?: boolean }) {
        if (options?.persist) {
            sessionStorage.setItem('ui_message', JSON.stringify({
                message,
                type: 'error'
            }));
            window.location.reload();
        } else {
            this.createNotification(message, 'error', options);
        }
    }

    static shipSuccessToUI(message: string, options?: NotificationOptions & { persist?: boolean }) {
        if (options?.persist) {
            sessionStorage.setItem('ui_message', JSON.stringify({
                message,
                type: 'success'
            }));
            window.location.reload();
        } else {
            this.createNotification(message, 'success', options);
        }
    }

    static shipHintToUI(message: string, options?: NotificationOptions & { persist?: boolean }) {
        if (options?.persist) {
            sessionStorage.setItem('ui_message', JSON.stringify({
                message,
                type: 'hint'
            }));
            window.location.reload();
        } else {
            this.createNotification(message, 'hint', options);
        }
    }

    static showGlobalLoadingIndication() {
        if (this.loadingBar) return;

        this.loadingBar = document.createElement('div');
        this.loadingBar.className = `
            fixed bottom-0 left-0 right-0 h-0.5
            bg-gradient-to-r from-purple-400 
            via-pink-500 to-purple-400 
            animate-gradient-x z-[99999]
        `;

        this.loadingText = document.createElement('div');
        this.loadingText.className = `
            fixed bottom-2 left-1/2 -translate-x-1/2
            px-3 py-1 rounded-md bg-black/10
            backdrop-blur-sm text-xs font-medium
            text-white/70 animate-pulse z-[99999]
        `;
        this.loadingText.textContent = 'Loading...';

        document.body.appendChild(this.loadingBar);
        document.body.appendChild(this.loadingText);
    }

    static hideGlobalLoadingIndication() {
        this.loadingBar?.remove();
        this.loadingBar = null;
        this.loadingText?.remove();
        this.loadingText = null;
    }

    static refreshWithMessage(message: string, type: NotificationType) {
        // Store message in sessionStorage (cleared after browser tab closes)
        sessionStorage.setItem('ui_message', JSON.stringify({
            message,
            type
        }));
        
        // Refresh the page
        window.location.reload();
    }

    static checkForRefreshMessage() {
        const stored = sessionStorage.getItem('ui_message');
        if (stored) {
            try {
                const { message, type } = JSON.parse(stored) as StoredMessage;
                sessionStorage.removeItem('ui_message');
                this.createNotification(message, type);
            } catch (error) {
                console.error('Invalid stored message format');
                sessionStorage.removeItem('ui_message');
            }
        }
    }
    
}

// New Loading Indicator Class
export class GradientLineWave {
    private element: HTMLDivElement;

    constructor() {
        this.element = document.createElement('div');
        this.element.style.width = '100%';
        this.element.style.height = '100%';
        this.element.innerHTML = `
            <svg width="100%" height="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
                <path d="M0,5 Q10,0 20,5 T40,5 T60,5 T80,5 T100,5" fill="none" stroke="url(#gradient)" stroke-width="1">
                    <animate attributeName="d" dur="2s" repeatCount="indefinite"
                        values="
                            M0,5 Q10,0 20,5 T40,5 T60,5 T80,5 T100,5;
                            M0,5 Q10,10 20,5 T40,5 T60,5 T80,5 T100,5;
                            M0,5 Q10,0 20,5 T40,5 T60,5 T80,5 T100,5
                        " />
                </path>
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style="stop-color:purple;stop-opacity:1" />
                        <stop offset="100%" style="stop-color:pink;stop-opacity:1" />
                    </linearGradient>
                </defs>
            </svg>
        `;
    }

    public mount(parent: HTMLElement) {
        parent.style.position = 'relative'; // Ensure the parent can contain the loader
        this.element.style.position = 'absolute'; // Position the loader absolutely within the parent
        this.element.style.top = '0';
        this.element.style.left = '0';
        this.element.style.right = '0';
        this.element.style.bottom = '0';
        parent.appendChild(this.element);
    }

    public unmount() {
        this.element.remove();
    }
}