import { articles } from './5mark-articles';
import { navigate } from '../../app-router';
import { SocialLinks } from '../reusable/social-links';
import { parse5mark, build5MarkExcerpt } from '../../utilities/5mark-parser';

export class ArticleRolodex {
    private element: HTMLElement;
    private socialLinks: SocialLinks;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = `
            w-full
            border-t
            border-white/10
            mt-16
            pt-12
            pb-16
            bg-[#242424]
            relative
        `;
        
        this.socialLinks = new SocialLinks();
        this.initialize();
    }

    private initialize(): void {
        const container = document.createElement('div');
        container.className = 'container mx-auto px-4';

        // Smaller header
        const header = document.createElement('div');
        header.className = 'text-center mb-8';
        header.innerHTML = `
            <h2 class="text-2xl font-bold text-white/90 mb-2">Learn More About PLATO5</h2>
            <p class="text-base text-white/70">Explore our articles to understand how we're revolutionizing social connection</p>
        `;
        container.appendChild(header);

        // Create wrapper for scroll container and buttons
        const wrapper = document.createElement('div');
        wrapper.className = 'relative';

        // Add scroll buttons
        const leftButton = document.createElement('button');
        leftButton.className = `
            absolute
            left-0
            top-1/2
            -translate-y-1/2
            z-10
            bg-gradient-to-r
            from-black/50
            to-transparent
            p-4
            rounded-l-xl
            text-white/70
            hover:text-white/90
            transition-colors
            hidden
            md:block
        `;
        leftButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
        `;

        const rightButton = document.createElement('button');
        rightButton.className = `
            absolute
            right-0
            top-1/2
            -translate-y-1/2
            z-10
            bg-gradient-to-l
            from-black/50
            to-transparent
            p-4
            rounded-r-xl
            text-white/70
            hover:text-white/90
            transition-colors
            hidden
            md:block
        `;
        rightButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        `;

        // Create scroll container
        const scrollContainer = document.createElement('div');
        scrollContainer.className = 'overflow-x-auto scrollbar-hide';
        scrollContainer.style.cssText = 'scroll-behavior: smooth;';

        // Create flex container for cards
        const cardContainer = document.createElement('div');
        cardContainer.className = 'flex gap-3 md:gap-4 px-3 md:px-8 py-6';

        // Add article cards
        const cards = articles.map((article, index) => {
            const card = document.createElement('div');
            card.className = `
                group
                cursor-pointer
                p-3
                md:p-4
                rounded-xl
                border
                border-white/10
                backdrop-blur-md
                transition-all
                duration-300
                hover:border-white/20
                bg-gradient-to-br
                from-blue-500/5
                via-purple-500/5
                to-blue-400/5
                flex-shrink-0
                w-[240px]
                md:w-[320px]
            `;

            // Format date
            const date = new Date(article.publishDate);
            const formattedDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });

            // Use build5MarkExcerpt to get a short excerpt
            const excerpt = build5MarkExcerpt(article.fiveMark, 150);

            card.innerHTML = `
                <div class="flex flex-col h-full">
                    <div class="text-sm text-purple-300/70 mb-3">${formattedDate}</div>
                    <h3 class="text-lg md:text-xl font-bold mb-3 text-white/90 group-hover:text-white/100 transition-colors">
                        ${article.title}
                    </h3>
                    <p class="text-white/70 text-sm line-clamp-3 mb-4 flex-grow">${excerpt}</p>
                    <div class="flex justify-between items-center mt-auto">
                        <div class="text-sm text-purple-300/70">
                            ${article.author}
                            ${article.position ? `<span class="opacity-50 hidden md:inline"> · ${article.position}</span>` : ''}
                        </div>
                        <div class="flex items-center text-purple-300/70 text-sm group-hover:text-purple-300/90 transition-colors">
                            Read 
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                            </svg>
                        </div>
                    </div>
                </div>
            `;

            card.addEventListener('click', () => navigate(article.route));
            return card;
        });

        // Function to update card scales based on scroll position
        const updateCardScales = () => {
            const containerRect = scrollContainer.getBoundingClientRect();
            const containerCenter = containerRect.left + containerRect.width / 2;

            requestAnimationFrame(() => {
                cards.forEach((card) => {
                    const cardRect = card.getBoundingClientRect();
                    const cardCenter = cardRect.left + cardRect.width / 2;
                    const distance = Math.abs(containerCenter - cardCenter);
                    const maxDistance = containerRect.width / 2;
                    
                    // Calculate scale based on distance from center
                    const scale = Math.max(0.9, 1.1 - (distance / maxDistance) * 0.2);
                    const opacity = Math.max(0.7, 1 - (distance / maxDistance) * 0.3);
                    
                    card.style.transform = `scale(${scale})`;
                    card.style.opacity = String(opacity);
                    card.style.zIndex = scale >= 1 ? '10' : '0';
                });
            });
        };

        // Add cards to container with immediate scaling
        cards.forEach(card => {
            cardContainer.appendChild(card);
            card.style.transition = 'transform 0.3s ease, opacity 0.3s ease';
        });

        // Add scroll event listener with passive flag for better performance
        scrollContainer.addEventListener('scroll', updateCardScales, { passive: true });
        window.addEventListener('resize', updateCardScales, { passive: true });

        // Add scroll button handlers
        leftButton.addEventListener('click', () => {
            scrollContainer.scrollBy({ 
                left: -window.innerWidth > 768 ? -400 : -280, 
                behavior: 'smooth' 
            });
        });

        rightButton.addEventListener('click', () => {
            scrollContainer.scrollBy({ 
                left: window.innerWidth > 768 ? 400 : 280, 
                behavior: 'smooth' 
            });
        });

        // Assemble everything
        scrollContainer.appendChild(cardContainer);
        wrapper.appendChild(leftButton);
        wrapper.appendChild(scrollContainer);
        wrapper.appendChild(rightButton);
        container.appendChild(wrapper);
        this.element.appendChild(container);

        // Mount social links at the bottom of the container
        this.socialLinks.mount(this.element);

        // Remove setTimeout and call updateCardScales immediately
        updateCardScales();

        // Add scrollbar hiding styles
        const style = document.createElement('style');
        style.textContent = `
            .scrollbar-hide::-webkit-scrollbar {
                display: none;
            }
            .scrollbar-hide {
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
        `;
        document.head.appendChild(style);
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }
}