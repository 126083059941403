import { ModalForm } from '../pieces/reusable/modal-form'
import { TopBar } from '../pieces/reusable/top-bar'
import { Blurb } from '../pieces/general/blurb'
import { MeetSomeoneNewButton } from '../pieces/pims/meet-someone-new-button'
import { SocialLinks } from '../pieces/reusable/social-links'
import { Spacer } from '../pieces/reusable/spacer'
import { GlassmorphismBox } from '../pieces/reusable/glassmorphism-box'
import { DiscussionsContent } from '../pieces/kommin/discussions-content'
import { ITagContent } from '../pieces/kommin/itag-content'
import { AppletSelector } from '../pieces/general/applet-selector'
import { ContentTitle } from '../pieces/reusable/content-title'
import { EmptySpace } from '../pieces/reusable/empty-space'
import { WelcomeText } from '../pieces/auth/welcome-text'
import { UINotifications } from '../utilities/errors'
import { ProfileContent } from '../pieces/auth/profile-content'
import { AuthService } from '../actions/auth/api-services'
import { NearbyContent } from '../pieces/nearby/nearby-content';
import { SettingsContent } from '../pieces/auth/settings-content';
import { PlatoChatContent } from '../pieces/platochat/platochat-content';
import { APIKeyFooter } from '../pieces/reusable/api-key-footer'

export class DashboardScreen {
  private container: HTMLElement | null = null
  private contentBox: GlassmorphismBox | null = null
  private discussionsContent: DiscussionsContent | null = null
  private tagContent: ITagContent | null = null
  private profileContent: ProfileContent | null = null
  private nearbyContent: NearbyContent | null = null;
  private settingsContent: SettingsContent | null = null;
  private platoChatContent: PlatoChatContent | null = null;
  private chosenApplet: string
  private apiKeyFooter: APIKeyFooter | null = null;

  constructor() {
    // Get saved applet preference or default to 'kommin'
    const savedApplet = localStorage.getItem('chosenApplet');
    const validApplets = ['kommin', 'profile', 'nearby', 'platochat', 'settings'];
    this.chosenApplet = savedApplet && validApplets.includes(savedApplet) 
        ? savedApplet 
        : 'profile';
    
    // Ensure localStorage is synced with our fallback
    localStorage.setItem('chosenApplet', this.chosenApplet);
  }

  public mount(container: HTMLElement) {
    this.container = container
    this.container.innerHTML = '' // Clear existing content
    this.container.className = 'min-h-screen flex flex-col'; // Add this line for footer positioning
    
    // Check for messages after container is ready
    UINotifications.checkForRefreshMessage();
    
    // User state
    const isUser: number | null = parseInt(localStorage.getItem('userId') || 'null')

    // Initialize components
    const modalForm = new ModalForm()
    const topBar = new TopBar(modalForm)
    const blurb = new Blurb(modalForm)
    const socialLinks = new SocialLinks()
    const spacer = new Spacer()
    const meetSomeoneNewButton = new MeetSomeoneNewButton()
    const appletSelector = new AppletSelector()
    const emptySpace = new EmptySpace()
    appletSelector.onSelect((applet) => {
      const validApplets = ['kommin', 'profile', 'nearby', 'platochat', 'settings'];
      const validApplet = validApplets.includes(applet) ? applet : 'kommin';
      this.switchContent(validApplet);
      localStorage.setItem('chosenApplet', validApplet);
      this.chosenApplet = validApplet;
    })

    // Initialize all content components but only show current
    this.contentBox = new GlassmorphismBox()
    this.discussionsContent = new DiscussionsContent()
    this.tagContent = new ITagContent(this.discussionsContent)
    this.profileContent = new ProfileContent(modalForm)
    this.nearbyContent = new NearbyContent()
    this.settingsContent = new SettingsContent()
    this.platoChatContent = new PlatoChatContent()

    // Initialize footer
    this.apiKeyFooter = new APIKeyFooter();

    // Mount components
    topBar.mount(container)

    if (!isUser) {
      // Guest view
      blurb.mount(container)
      socialLinks.mount(container)
      spacer.mount(container)
    } else {
      // User view
      const welcomeText = new WelcomeText(isUser)
      welcomeText.mount(container)
    }

    modalForm.mount(container)
    meetSomeoneNewButton.mount(container)
    emptySpace.mount(container)
    this.contentBox.mount(container)
    this.switchContent(this.chosenApplet)
    appletSelector.mount(container)
    emptySpace.mount(container)

    // Mount footer last
    this.apiKeyFooter.mount(container);
  }

  private async switchContent(applet: string) {
    if (!this.contentBox) return;

    const contentElement = this.contentBox.getElement();
    contentElement.innerHTML = ''; // Clear current content

    switch (applet) {
      case 'kommin':
        const komminTitle = new ContentTitle('Kommin Discussions', 'kommin');
        komminTitle.mount(contentElement);
        this.tagContent?.mount(contentElement);
        this.discussionsContent?.mount(contentElement);
        break;
      case 'profile':
        const profileTitle = new ContentTitle('Your Profile', 'profile');
        profileTitle.mount(contentElement);
        const userId = localStorage.getItem('userId');
        if (userId) {
          const userData = await AuthService.getUserById(parseInt(userId));
          if (this.profileContent) {
            this.profileContent.mount(contentElement);
            this.profileContent.updateContent(userData);
          }
        } else {
          this.profileContent?.mount(contentElement);
          this.profileContent?.updateContent(null);
        }
        break;
      case 'nearby':
        const nearbyTitle = new ContentTitle('Nearby', 'nearby');
        nearbyTitle.mount(contentElement);
        this.nearbyContent?.mount(contentElement);
        break;
      case 'platochat':
        const platoChatTitle = new ContentTitle('PlatoChat', 'platochat');
        platoChatTitle.mount(contentElement);
        this.platoChatContent?.mount(contentElement);
        break;
      case 'settings':
        const settingsTitle = new ContentTitle('Settings', 'settings');
        settingsTitle.mount(contentElement);
        this.settingsContent?.mount(contentElement);
        break;
      // Add other cases as needed
    }
  }

  public unmount() {
    if (this.container) {
      this.container.innerHTML = ''
    }
    this.apiKeyFooter = null;
  }
}