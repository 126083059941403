import { TopBar } from '../pieces/reusable/top-bar';
import { ModalForm } from '../pieces/reusable/modal-form';
import { ArticleRolodex } from '../pieces/tutorial/article-rolodex';
import { parse5mark } from '../utilities/5mark-parser';

interface Article {
    title: string;
    author: string;
    position: string;
    publishDate: string;
    fiveMark: string;
}

export class ArticleScreen {
    private element: HTMLElement;
    private article: Article;
    private modalForm: ModalForm;
    private topBar: TopBar;
    private articleRolodex: ArticleRolodex;

    constructor(article: Article) {
        this.article = article;
        this.modalForm = new ModalForm();
        this.topBar = new TopBar(this.modalForm);
        this.articleRolodex = new ArticleRolodex();
        this.element = document.createElement('div');
        this.element.className = 'min-h-screen';
        
        this.modalForm.mount(this.element);
        this.initialize();
    }

    private initialize(): void {
        // Add TopBar first
        this.topBar.mount(this.element);

        // Parse and add article content
        const content = parse5mark(this.article.fiveMark);
        this.element.appendChild(content);

        // Create a wrapper for the rolodex to ensure consistent context
        const rolodexWrapper = document.createElement('div');
        rolodexWrapper.className = 'relative z-10 isolate'; // Add isolate to create new stacking context
        this.element.appendChild(rolodexWrapper);

        // Add article rolodex at the bottom
        this.articleRolodex.mount(rolodexWrapper);
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        if (this.element.parentElement) {
            this.element.parentElement.removeChild(this.element);
        }
    }
}