import { AnalytixService } from '../../actions/analytix/api-services';
import { AuthService } from '../../actions/auth/api-services';
import { ModalForm } from '../../pieces/reusable/modal-form';
import { AnalytixCollector } from '../analytix/analytix-collector';
import { UINotifications } from '../../utilities/errors';
import { GeolocationContent } from '../auth/geolocation-content';
import { ProfileBuilder } from '../auth/profile-builder';
import { PIMSLoadingContent } from '../pims/pims-loading';
import { SimpleMatchLoadingContent } from '../pims/simple-match-loading';
import { PlatoChatService } from '../../actions/platochat/api-services';

export class MeetSomeoneNewButton {
    private element: HTMLElement;
    private modalForm: ModalForm;
    private toggleStates: { [key: string]: boolean } = {
        location: false,
        personality: false,
        interests: false
    };
    private isLoggedIn: boolean;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'meet-someone-new-button mb-8';
        this.isLoggedIn = localStorage.getItem('userId') !== null;
        this.modalForm = new ModalForm();
        
        // Load saved preferences from localStorage
        this.loadSavedPreferences();
        
        // If logged in, validate backend requirements
        if (this.isLoggedIn) {
            this.validateBackendRequirements();
        }
        
        this.render();
    }

    private loadSavedPreferences() {
        const savedPrefs = localStorage.getItem('matchingPreferences');
        if (savedPrefs) {
            this.toggleStates = { ...this.toggleStates, ...JSON.parse(savedPrefs) };
        }
    }

    private async validateBackendRequirements() {
        const userId = parseInt(localStorage.getItem('userId') || '0');
        
        // Check personality requirements
        if (this.toggleStates.personality) {
            const analytixData = await AnalytixService.getAnalytixByUserId(userId);
            if (!analytixData || 
                typeof analytixData.tOpenness !== 'number' ||
                typeof analytixData.tConscientiousness !== 'number' ||
                typeof analytixData.tExtraversion !== 'number' ||
                typeof analytixData.tAgreeableness !== 'number' ||
                typeof analytixData.tNeuroticism !== 'number') {
                this.toggleStates.personality = false;
            }
        }

        // Check location requirements
        if (this.toggleStates.location) {
            const userData = await AuthService.getUserById(userId);
            if (!userData?.geohash) {
                this.toggleStates.location = false;
            }
        }

        // Save validated preferences
        this.savePreferences();
    }

    private savePreferences() {
        localStorage.setItem('matchingPreferences', JSON.stringify(this.toggleStates));
    }

    private async togglePreference(type: string) {
        if (!this.isLoggedIn) return;
        
        // For personality, check requirements before allowing toggle on
        if (type === 'personality' && !this.toggleStates.personality) {
            const userId = parseInt(localStorage.getItem('userId') || '0');
            const analytixData = await AnalytixService.getAnalytixByUserId(userId);
            
            if (!analytixData || 
                typeof analytixData.tOpenness !== 'number' ||
                typeof analytixData.tConscientiousness !== 'number' ||
                typeof analytixData.tExtraversion !== 'number' ||
                typeof analytixData.tAgreeableness !== 'number' ||
                typeof analytixData.tNeuroticism !== 'number') {

                UINotifications.shipHintToUI('Let\'s setup your personality profile!');
                
                // Open modal with analytix collector
                const analytixCollector = new AnalytixCollector(userId, (success: boolean, result: any) => {
                    if (success && result) {
                        UINotifications.shipSuccessToUI('Assessment completed successfully!');
                        this.toggleStates.personality = true;
                        this.savePreferences();
                        this.render();
                        this.modalForm.close();
                        window.location.reload();
                    }
                });

                this.modalForm.clear();
                this.modalForm.append(analytixCollector.getElement());
                this.modalForm.open();
            } else {
                // If analytix data exists, just toggle
                this.toggleStates[type] = !this.toggleStates[type];
                this.savePreferences();
                this.render();
            }
        } else if (type === 'location' && !this.toggleStates.location) {
            const userId = parseInt(localStorage.getItem('userId') || '0');
            const userData = await AuthService.getUserById(userId);
            
            if (!userData?.geohash) {
                UINotifications.shipHintToUI('Let\'s setup your location preferences!');
                
                const geolocationContent = new GeolocationContent(userId, (success: boolean) => {
                    if (success) {
                        UINotifications.shipSuccessToUI('Location updated successfully!');
                        this.toggleStates.location = true;
                        this.savePreferences();
                        this.render();
                        this.modalForm.close();
                        window.location.reload();
                    }
                });

                this.modalForm.clear();
                this.modalForm.append(geolocationContent.getElement());
                this.modalForm.open();
            } else {
                // If geohash exists, just toggle
                this.toggleStates[type] = !this.toggleStates[type];
                this.savePreferences();
                this.render();
            }
        } else {
            // For interests toggle or turning off other toggles
            this.toggleStates[type] = !this.toggleStates[type];
            this.savePreferences();
            this.render();
        }
    }

    private render() {
        this.element.innerHTML = `
            <div class="meet-someone-new-button-container relative">
                <button class="meet-new-btn" ${!this.isLoggedIn ? 'disabled' : ''}>
                    <span class="btn-text">Meet Someone New</span>
                    <div class="btn-glow"></div>
                </button>
                
                <div class="preference-togglers">
                    <div class="toggler-item">
                        <button class="toggler-btn transition-all duration-300 ${this.toggleStates.location ? 'active' : ''}" 
                                ${!this.isLoggedIn ? 'disabled' : ''}>
                            <i class="fas fa-location-dot text-sm"></i>
                        </button>
                        <span class="toggler-label text-xs transition-colors duration-300 ${this.toggleStates.location ? 'text-white' : ''}">Location</span>
                    </div>
                    <div class="toggler-item">
                        <button class="toggler-btn transition-all duration-300 ${this.toggleStates.personality ? 'active' : ''}"
                                ${!this.isLoggedIn ? 'disabled' : ''}>
                            <i class="fas fa-head-side-virus text-sm"></i>
                        </button>
                        <span class="toggler-label text-xs transition-colors duration-300 ${this.toggleStates.personality ? 'text-white' : ''}">Personality</span>
                    </div>
                    <div class="toggler-item">
                        <button class="toggler-btn transition-all duration-300 ${this.toggleStates.interests ? 'active' : ''}"
                                ${!this.isLoggedIn ? 'disabled' : ''}>
                            <i class="fas fa-palette text-sm"></i>
                        </button>
                        <span class="toggler-label text-xs transition-colors duration-300 ${this.toggleStates.interests ? 'text-white' : ''}">Interests</span>
                    </div>
                </div>

                ${!this.isLoggedIn ? `
                    <div class="absolute inset-0 bg-black/30 backdrop-blur-[2px] rounded-xl flex items-center justify-center">
                        <span class="text-white text-xs font-medium">Join or Login to Access Our Matching System</span>
                    </div>
                ` : ''}
            </div>
        `;

        // Update the styles
        const style = document.createElement('style');
        style.textContent = `
            .meet-new-btn {
                position: relative;
                padding: 0.5rem 1.5rem;  /* Reduced padding */
                font-size: 0.875rem;     /* Smaller font (14px) */
                font-weight: 500;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 8px;      /* Smaller radius */
                cursor: pointer;
                transition: all 0.3s ease;
                backdrop-filter: blur(10px);
            }

            .btn-text {
                color: white;
            }

            .meet-new-btn:hover:not(:disabled) {
                transform: translateY(-1px);  /* Smaller hover lift */
            }

            .meet-new-btn:active:not(:disabled) {
                transform: translateY(0);
            }

            .meet-new-btn:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            .toggler-btn.active {
                background: linear-gradient(135deg, #9333EA 0%, #4F46E5 100%);
                color: white;
                padding: 0.375rem;  /* Smaller padding */
            }
            
            .toggler-btn {
                cursor: pointer;
                padding: 0.375rem;  /* Smaller padding */
            }
            
            .toggler-btn:hover:not(.active) {
                background: rgba(147, 51, 234, 0.1);
            }

            .toggler-btn:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        `;
        document.head.appendChild(style);

        // Add click handlers only if logged in
        if (this.isLoggedIn) {
            const togglers = this.element.querySelectorAll('.toggler-btn');
            togglers.forEach(toggler => {
                const type = toggler.querySelector('i')?.className.includes('location') ? 'location' 
                          : toggler.querySelector('i')?.className.includes('head') ? 'personality'
                          : 'interests';
                
                toggler.addEventListener('click', () => this.togglePreference(type));
            });
        }

        // Add click handler for the main button
        const mainButton = this.element.querySelector('.meet-new-btn');
        mainButton?.addEventListener('click', async () => {
            if (!this.isLoggedIn) return;

            const userId = parseInt(localStorage.getItem('userId') || '0');
            
            // Check gamification status and points
            const canProceed = await PlatoChatService.checkGamificationAndPoints(userId, 2000);
            if (!canProceed) return;

            const userData = await AuthService.getUserById(userId);
            
            // Check that ALL required fields are present and valid
            if (!userData?.dob || !userData?.intro || !userData?.country || 
                userData.dob === '' || userData.intro === '' || userData.country === '' ||
                userData.dob === '0001-01-01 00:00:00+00' || userData.dob === '0001-01-01T00:00:00Z') {
                
                const profileBuilder = new ProfileBuilder(userData, () => {
                    this.modalForm.close();
                    window.location.reload();
                });

                this.modalForm.clear();
                this.modalForm.append(profileBuilder.getElement());
                this.modalForm.open();
                return;
            }

            // Use SimpleMatchLoadingContent if personality filtering is off
            if (!this.toggleStates.personality) {
                const simpleMatchContent = new SimpleMatchLoadingContent(
                    userId,
                    this.toggleStates.location,
                    this.toggleStates.interests,
                    () => this.modalForm.close()
                );

                this.modalForm.clear();
                this.modalForm.append(simpleMatchContent.getElement());
                this.modalForm.open();
                return;
            }

            // Use PIMSLoadingContent if personality filtering is on
            const analytixData = await AnalytixService.getAnalytixByUserId(userId);
            const analytixId = analytixData?.analytixId || null;

            const pimsContent = new PIMSLoadingContent(
                userId,
                analytixId,
                this.toggleStates.location,
                this.toggleStates.personality,
                this.toggleStates.interests,
                () => this.modalForm.close()
            );

            this.modalForm.clear();
            this.modalForm.append(pimsContent.getElement());
            this.modalForm.open();
        });
    }

    mount(parent: HTMLElement) {
        parent.appendChild(this.element);
        this.modalForm.mount(document.body);
    }
}