// import { UINotifications } from "./utilities/errors";
// import { FeaturesScreen } from "./screens/features-screen";
import { ArticleScreen } from "./screens/article-screen";
import { UnknownRouteScreen } from "./screens/unknown-route";
import { articles } from "./pieces/tutorial/5mark-articles";
import { DashboardScreen } from "./screens/dashboard-screen";
import { FeatureScreen } from "./screens/features-screen";
import { KomminScreen } from "./screens/kommin-screen";
import { Hash } from "./utilities/hash";
import { NanoScreen } from "./screens/nano-screen";
import { updatePageMetadata, updateDiscussionMetadata } from './utilities/metadata';

export function initializeRouter() {
  window.addEventListener('popstate', handleRoute)
  window.addEventListener('load', handleRoute)
  handleBackNavigation()
}

export function navigate(route: string): void {
  // Clear any existing content
  const root = document.getElementById('root');
  const app = document.querySelector('#app');
  
  // Clear both root and app containers
  if (root) {
    root.innerHTML = '';
  }
  if (app) {
    app.innerHTML = '';
  }

  // Unmount the current screen if it exists
  if (currentScreen && typeof currentScreen.unmount === 'function') {
    currentScreen.unmount();
  }

  // Reset scroll position
  window.scrollTo(0, 0);

  history.pushState({}, '', route);
  handleRoute();
}

// Variable to keep track of the current screen
let currentScreen: any = null;

// Renamed utility function
export function bounceToTop(): void {
  requestAnimationFrame(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
}

function handleRoute() {
  const path = window.location.pathname

  // Add this line to update metadata for all routes
  updatePageMetadata(path);

  // Add regex pattern to match nano routes
  const nanoMatch = path.match(/^\/platochat\/nanos\/([a-zA-Z0-9]+)$/);
  
  // Add regex pattern to match discussion routes
  const discussionMatch = path.match(/^\/kommin\/discussion\/([a-zA-Z0-9]+)$/);
  
  if (nanoMatch) {
    showNano(nanoMatch[1]);
    return;
  }
  
  if (discussionMatch) {
    showDiscussion(discussionMatch[1]);
    return;
  }

  switch (path) {
    case '/':
      showDashboard()
      break
    case '/features':
      showFeatures()
      break
    case '/about/how-social-engines-can-solve-the-loneliness-epidemic':
      showArticle(0)
      break
    case '/about/gamified-conversations-and-nano-blogging':
      showArticle(1)
      break
    case '/about/explore-the-plato5-suite-kommin-platochat-and-more':
      showArticle(2)
      break
    case '/about/safety-suggestions-for-online-meetups':
      showArticle(3)
      break
    case '/unknown-route':
      showUnknownRoute()
      break
    default:
      // Redirect to 404 page instead of showing it directly
      history.replaceState({}, '', '/unknown-route');
      showUnknownRoute()
  }
}

function showDashboard() {
  const screen = new DashboardScreen()
  screen.mount(document.querySelector('#app')!)
}

function showArticle(index: number) {
  const screen = new ArticleScreen(articles[index])
  screen.mount(document.querySelector('#app')!)
}

function showUnknownRoute() {
  const screen = new UnknownRouteScreen()
  screen.mount(document.querySelector('#app')!)
}

function showFeatures() {
  const screen = new FeatureScreen()
  screen.mount(document.querySelector('#app')!)
}

function handleBackNavigation() {
    window.addEventListener('popstate', () => {
        const root = document.getElementById('root');
        const app = document.querySelector('#app');
        
        // Clear both root and app containers
        if (root) {
            root.innerHTML = '';
        }
        if (app) {
            app.innerHTML = '';
        }

        // Unmount the current screen if it exists
        if (currentScreen && typeof currentScreen.unmount === 'function') {
            currentScreen.unmount();
        }

        // Reset scroll position
        window.scrollTo(0, 0);

        // Handle the route based on the current path
        handleRoute();
    });
}

// Add new function to show discussion
async function showDiscussion(hashedId: string) {
  try {
    const discussionId = Hash.decodeId(hashedId);
    
    // Get discussion data BEFORE creating screen
    const screen = new KomminScreen(discussionId);
    const discussionData = await screen.getDiscussionData();
    
    // Update metadata BEFORE mounting screen
    await updateDiscussionMetadata(hashedId, {
      title: discussionData.title,
      description: discussionData.description,
      author: discussionData.author,
      created: discussionData.created
    });
    
    // Mount screen after metadata is set
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
    
  } catch (error) {
    console.error('Failed to handle discussion:', error);
    history.replaceState({}, '', '/unknown-route');
    showUnknownRoute();
  }
}

// Add new function to show nano
function showNano(hashedId: string) {
  const nanoId = Hash.decodeId(hashedId);
  const screen = new NanoScreen(nanoId);
  
  // Add robots meta tag to prevent indexing of nano pages
  const robotsMeta = document.createElement('meta');
  robotsMeta.setAttribute('name', 'robots');
  robotsMeta.setAttribute('content', 'noindex, nofollow');
  document.head.appendChild(robotsMeta);
  
  screen.mount(document.querySelector('#app')!);
  currentScreen = screen;
} 