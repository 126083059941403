export class ContentTitle {
    private element: HTMLElement;
    private iconElement: HTMLElement;
    private container: HTMLElement;

    constructor(text: string, type: 'kommin' | 'profile' | 'nearby' | 'platochat' | 'settings' = 'kommin') {
        this.container = document.createElement('div');
        this.container.className = 'flex items-center gap-3 mb-4';

        this.iconElement = document.createElement('div');
        this.iconElement.className = 'w-10 h-10 flex items-center justify-center rounded-full text-white backdrop-blur-md bg-opacity-30 shadow-lg';
        
        switch (type) {
            case 'kommin':
                this.iconElement.className += ' bg-orange-500/30';
                this.iconElement.innerHTML = '<span class="text-base font-bold">K</span>';
                break;
            case 'profile':
                this.iconElement.className += ' bg-yellow-300/30';
                this.iconElement.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>';
                break;
            case 'nearby':
                this.iconElement.className += ' bg-purple-500/30';
                this.iconElement.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>';
                break;
            case 'platochat':
                this.iconElement.className += ' bg-blue-400/30';
                this.iconElement.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>';
                break;
            case 'settings':
                this.iconElement.className += ' bg-slate-400/30';
                this.iconElement.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>';
                break;
        }

        this.element = document.createElement('h1');
        this.element.textContent = text;
        this.element.className = 'text-2xl font-bold bg-clip-text text-transparent';
        
        switch (type) {
            case 'kommin':
                this.element.className += ' bg-gradient-to-r from-orange-500 to-amber-400';
                break;
            case 'profile':
                this.element.className += ' bg-gradient-to-r from-yellow-300 to-amber-500';
                break;
            case 'nearby':
                this.element.className += ' bg-gradient-to-r from-purple-500 to-red-400';
                break;
            case 'platochat':
                this.element.className += ' bg-gradient-to-r from-blue-400 to-indigo-600';
                break;
            case 'settings':
                this.element.className += ' bg-gradient-to-r from-slate-400 to-zinc-500';
                break;
        }

        this.container.appendChild(this.iconElement);
        this.container.appendChild(this.element);
    }

    mount(parent: HTMLElement) {
        parent.appendChild(this.container);
    }

    public getContainer(): HTMLElement {
        return this.container;
    }
} 