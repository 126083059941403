export class EmptySpace {
    private element: HTMLElement;

    constructor(height: string = '200px', margin: string = '40px 0') { // Increased default height and added margin
        this.element = document.createElement('div');
        this.element.style.height = height;
        this.element.style.margin = margin;
        this.element.className = 'w-full';
    }

    mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }
}