import { TextInput } from '../reusable/text-input';
import { Button } from '../reusable/button';
import { AuthService } from '../../actions/auth/api-services';
import { UINotifications } from '../../utilities/errors';

export class AuthContent {
  private element: HTMLDivElement;
  private tabsContainer: HTMLDivElement;
  private contentContainer: HTMLDivElement;
  private activeTab: 'login' | 'signup' | 'verify' = 'login';
  private signupData?: {
    name: string;
    email: string;
    password: string;
    accepted_tos: boolean;
    newsletter: boolean;
    stay_logged_in: boolean;
    regCode: string;
  };

  public getElement(): HTMLElement {
    return this.element;
  }

  constructor() {
    this.element = document.createElement('div');
    this.element.className = 'space-y-6';

    // Tabs
    this.tabsContainer = document.createElement('div');
    this.tabsContainer.className = 'flex space-x-4 mb-6';
    
    const loginTab = this.createTab('Login');
    const signupTab = this.createTab('Signup');
    this.tabsContainer.append(loginTab, signupTab);

    // Content Container
    this.contentContainer = document.createElement('div');
    this.contentContainer.className = 'space-y-4';
    
    this.element.append(this.tabsContainer, this.contentContainer);
    
    // Check if user is logged in
    const userId = localStorage.getItem('userId');
    if (userId) {
        this.showLoggedInState();
    } else {
        this.showLogin();
    }
  }

  private createTab(text: string): HTMLButtonElement {
    const tab = document.createElement('button');
    tab.textContent = text;
    tab.className = `
      px-4 
      py-2 
      rounded-lg 
      transition-all
      duration-300
      hover:bg-white/10
    `;

    tab.addEventListener('click', () => {
      if (text === 'Login') {
        this.showLogin();
      } else {
        this.showSignup();
      }
    });

    return tab;
  }

  private updateTabs() {
    const tabs = this.tabsContainer.children;
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i] as HTMLButtonElement;
      if (
        (this.activeTab === 'login' && i === 0) || 
        (this.activeTab === 'signup' && i === 1)
      ) {
        tab.classList.add('bg-white/10', 'text-white');
      } else {
        tab.classList.remove('bg-white/10', 'text-white');
        tab.classList.add('text-white/70');
      }
    }
  }


private showLogin() {
    this.activeTab = 'login';
    this.updateTabs();
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'flex';

    const form = document.createElement('form');
    form.className = 'space-y-4';
    this.contentContainer.appendChild(form);

    const emailInput = new TextInput('email@example.com', 'email');
    const passwordInput = new TextInput('password', 'password');
    const stayLoggedInCheckbox = this.createCheckbox('Keep me logged in for up to 60 days of inactivity');
    const proceedButton = new Button('Proceed');
    const forgotPasswordButton = new Button('Forgot Password?');

    passwordInput['element'].type = 'password';
    
    forgotPasswordButton.getElement().className = `
        text-white/50 
        text-sm 
        hover:text-white 
        transition-colors
        duration-300
    `;

    emailInput.mount(form);
    passwordInput.mount(form);
    form.appendChild(stayLoggedInCheckbox);
    proceedButton.mount(form);
    forgotPasswordButton.mount(form);

    const handleSubmit = async (e?: Event) => {
        if (e) e.preventDefault();
        const email = emailInput.getValue();
        const password = passwordInput.getValue();
        const stay_logged_in = (stayLoggedInCheckbox.querySelector('input') as HTMLInputElement).checked;
        
        const response = await AuthService.login({
            email,
            password,
            device_type: "PLATO5 WebApp | https://plato5.us",
            stay_logged_in
        });
        if (response) {
            localStorage.setItem('userId', response.user.ID.toString());
            window.location.reload();
        }
    };

    form.addEventListener('submit', handleSubmit);
    proceedButton.getElement().addEventListener('click', handleSubmit);

    forgotPasswordButton.getElement().addEventListener('click', () => {
        this.showForgotPassword();
    });
}

private showSignup() {
    this.activeTab = 'signup';
    this.updateTabs();
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'flex';

    const form = document.createElement('form');
    form.className = 'space-y-4';
    this.contentContainer.appendChild(form);

    const nameInput = new TextInput('full name', 'username');
    const emailInput = new TextInput('email@example.com', 'email');
    const passwordInput = new TextInput('password', 'password');
    const tosCheckbox = this.createCheckbox(`I accept the <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=258cd87c-9f29-4484-a8a3-ba477ba41496" target="_blank" rel="noopener">Terms of Service</a> & <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=2791893c-8d65-4498-95ff-ec98723912e7" target="_blank" rel="noopener">Privacy Policy</a>`);
    const newsletterCheckbox = this.createCheckbox('I want to receive newsletters and updates');
    const stayLoggedInCheckbox = this.createCheckbox('Keep me logged in for up to 60 days of inactivity');
    const proceedButton = new Button('Get Started');

    passwordInput['element'].type = 'password';
    
    nameInput.mount(form);
    emailInput.mount(form);
    passwordInput.mount(form);
    form.appendChild(tosCheckbox);
    form.appendChild(newsletterCheckbox);
    form.appendChild(stayLoggedInCheckbox);
    proceedButton.mount(form);

    const handleSubmit = async (e?: Event) => {
        if (e) e.preventDefault();
        const name = nameInput.getValue();
        const email = emailInput.getValue();
        const password = passwordInput.getValue();
        const accepted_tos = (tosCheckbox.querySelector('input') as HTMLInputElement).checked;
        const newsletter = (newsletterCheckbox.querySelector('input') as HTMLInputElement).checked;
        const stay_logged_in = (stayLoggedInCheckbox.querySelector('input') as HTMLInputElement).checked;

        if (!accepted_tos) {
            UINotifications.shipErrorToUI('Please accept the Terms of Service');
            return;
        }

        if (!email.includes('@')) {
            UINotifications.shipErrorToUI('Please enter a valid email address');
            return;
        }

        if (password.length < 8) {
            UINotifications.shipErrorToUI('Password must be at least 8 characters long');
            return;
        }

        if (name.length < 2) {
            UINotifications.shipErrorToUI('Please enter your full name');
            return;
        }

        const emailExists = await AuthService.checkEmailExists(email);
        if (emailExists) {
            UINotifications.shipErrorToUI('This email is already registered. Please login instead.');
            this.showLogin();
            return;
        }

        this.showVerification(
            name, 
            email, 
            password, 
            accepted_tos, 
            newsletter,
            stay_logged_in
        );
    };

    form.addEventListener('submit', handleSubmit);
    proceedButton.getElement().addEventListener('click', handleSubmit);
}

private showVerification(
    name: string, 
    email: string, 
    password: string, 
    accepted_tos: boolean, 
    newsletter: boolean,
    stay_logged_in: boolean
) {
    console.log('Starting verification process for:', email);
    
    this.activeTab = 'verify';
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'none';

    const backButton = document.createElement('button');
    backButton.className = `
        flex items-center gap-2 text-white/70 hover:text-white 
        transition-colors duration-300 mb-6
    `;
    backButton.innerHTML = `
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        <span>Back to Signup</span>
    `;
    backButton.onclick = this.backToSignup;

    const verificationContent = document.createElement('div');
    verificationContent.className = 'space-y-6';
    verificationContent.innerHTML = `
        <div class="text-center">
            <h2 class="text-2xl font-bold text-white mb-2">Verify Your Email</h2>
            <p class="text-white/70 text-sm">
                A verification code has been sent to ${email}.<br>
                Please check your inbox (and spam folder).
            </p>
            <p class="text-white/50 text-xs mt-2">
                Having trouble? Send us an email at <a href="mailto:support@plato5.us" class="text-white/70 hover:text-white transition-colors duration-300">support@plato5.us</a> and we'll get you an account.
            </p>
        </div>
        <input type="text" 
            class="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3
            text-white text-center text-2xl tracking-[0.5em] uppercase
            focus:outline-none focus:border-white/30 transition-colors"
            maxlength="5"
            placeholder="XXXXX"
        />
    `;

    const verifyButton = new Button('Verify Email');
    verifyButton.getElement().className = `
        w-full px-6 py-3.5 text-base font-medium
        bg-gradient-to-r from-blue-500 to-indigo-500
        hover:from-blue-600 hover:to-indigo-600
        text-white rounded-xl transition-all duration-300
        transform hover:scale-[1.02] active:scale-[0.98]
    `;

    this.contentContainer.appendChild(backButton);
    this.contentContainer.appendChild(verificationContent);
    verifyButton.mount(this.contentContainer);

    const input = verificationContent.querySelector('input')!;
    input.addEventListener('input', (e: Event) => {
        const target = e.target as HTMLInputElement;
        target.value = target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    });

    if (this.signupData?.email === email) {
        console.log('Verification already in progress for:', email);
        return;
    }

    console.log('Sending registration code to:', email);
    AuthService.sendRegCode(email, name).then(regCode => {
        if (regCode) {
            this.signupData = {
                name,
                email,
                password,
                accepted_tos,
                newsletter,
                stay_logged_in,
                regCode
            };
        } else {
            UINotifications.shipErrorToUI('Failed to send verification code');
            this.showSignup();
        }
    });

    verifyButton.getElement().addEventListener('click', async () => {
        if (!this.signupData) return;
        
        const userInput = input.value.trim();
        if (userInput === this.signupData.regCode) {
            const response = await AuthService.signup({
                name: this.signupData.name,
                email: this.signupData.email,
                password: this.signupData.password,
                accepted_tos: this.signupData.accepted_tos,
                newsletter: this.signupData.newsletter,
                device_type: "PLATO5 WebApp | https://plato5.us",
                stay_logged_in: this.signupData.stay_logged_in
            });

            if (response) {
                localStorage.setItem('userId', response.id.toString());
                UINotifications.shipSuccessToUI('Welcome to PLATO5!');
                window.location.reload();
            }
        } else {
            UINotifications.shipErrorToUI('Invalid verification code. Send a new one.');
            this.showSignup();
        }
    });
}

private createCheckbox(label: string): HTMLDivElement {
    const container = document.createElement('div');
    container.className = 'flex items-center space-x-2';

    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.className = 'form-checkbox rounded bg-transparent border-white/30';

    const labelElement = document.createElement('label');
    labelElement.innerHTML = label;
    labelElement.className = 'text-sm text-white/70';

    container.append(checkbox, labelElement);
    return container;
}

private showLoggedInState() {
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'none';
    
    const logoutButton = new Button('Logout');
    logoutButton.getElement().className = `
      w-full
      px-6 
      py-3.5
      text-base
      font-medium
      bg-gradient-to-br
      from-red-500
      to-pink-600
      hover:from-red-600
      hover:to-pink-700
      text-white
      rounded-xl
      transition-all
      duration-300
      shadow-lg
      hover:shadow-red-500/25
      hover:scale-[1.02]
      active:scale-[0.98]
      transform
      backdrop-blur-sm
      border
      border-white/10
    `;

    logoutButton.getElement().addEventListener('click', async () => {
      await AuthService.logout();
      localStorage.removeItem('userId');
      this.showLogin();
    });

    logoutButton.mount(this.contentContainer);
}

  public mount(parent: HTMLElement): void {
    parent.appendChild(this.element);
  }

  private backToSignup = () => {
    this.tabsContainer.style.display = 'flex';
    this.showSignup();
  };

  private showForgotPassword() {
    this.activeTab = 'login';
    this.updateTabs();
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'none';

    const backButton = document.createElement('button');
    backButton.className = `
        flex items-center gap-2 text-white/70 hover:text-white 
        transition-colors duration-300 mb-6
    `;
    backButton.innerHTML = `
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        <span>Back to Login</span>
    `;
    backButton.onclick = () => this.showLogin();

    const form = document.createElement('form');
    form.className = 'space-y-6';

    const emailInput = new TextInput('email@example.com', 'email');
    const proceedButton = new Button('Send Reset Code');

    const handleSubmit = async (e?: Event) => {
        if (e) e.preventDefault();
        const email = emailInput.getValue();

        if (!email.includes('@')) {
            UINotifications.shipErrorToUI('Please enter a valid email address');
            return;
        }

        const response = await AuthService.forgotPassword(email);
        if (response) {
            this.showResetPassword(email, response.randomString, response.userId);
        }
    };

    form.addEventListener('submit', handleSubmit);
    proceedButton.getElement().addEventListener('click', handleSubmit);

    emailInput.mount(form);
    proceedButton.mount(form);

    this.contentContainer.appendChild(backButton);
    this.contentContainer.appendChild(form);
  }

  private showResetPassword(email: string, code: string, userId: number) {
    this.contentContainer.innerHTML = '';

    const form = document.createElement('form');
    form.className = 'space-y-6';

    const verificationContent = document.createElement('div');
    verificationContent.className = 'space-y-6 mb-6';
    verificationContent.innerHTML = `
        <div class="text-center">
            <h2 class="text-2xl font-bold text-white mb-2">Reset Your Password</h2>
            <p class="text-white/70 text-sm">
                Enter the code sent to ${email}
            </p>
        </div>
        <input type="text" 
            class="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3
            text-white text-center text-2xl tracking-[0.5em] uppercase
            focus:outline-none focus:border-white/30 transition-colors"
            maxlength="5"
            placeholder="XXXXX"
        />
    `;

    const passwordInput = new TextInput('new password', 'password');
    passwordInput['element'].type = 'password';
    
    const resetButton = new Button('Reset Password');

    form.appendChild(verificationContent);
    passwordInput.mount(form);
    resetButton.mount(form);

    const input = verificationContent.querySelector('input')!;
    input.addEventListener('input', (e: Event) => {
        const target = e.target as HTMLInputElement;
        target.value = target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    });

    const handleSubmit = async (e?: Event) => {
        if (e) e.preventDefault();
        
        const userInput = input.value.trim();
        const newPassword = passwordInput.getValue();

        if (newPassword.length < 8) {
            UINotifications.shipErrorToUI('Password must be at least 8 characters long');
            return;
        }

        if (userInput === code) {
            const response = await AuthService.resetPassword(userId, newPassword, email);
            if (response) {
                UINotifications.shipSuccessToUI('Password reset successfully');
                this.showLogin();
            }
        } else {
            UINotifications.shipErrorToUI('Invalid verification code');
        }
    };

    form.addEventListener('submit', handleSubmit);
    resetButton.getElement().addEventListener('click', handleSubmit);

    this.contentContainer.appendChild(form);
  }
}