import { PlatoChatService } from "../../actions/platochat/api-services";
import { AuthService } from "../../actions/auth/api-services";
import { ModalForm } from "../reusable/modal-form";

export class Header {
    private element: HTMLElement;
    private userId: number;
    private walletBalance: number = 0;
    private gamificationEnabled: boolean = true;

    constructor(userId: number) {
        this.userId = userId;
        this.element = document.createElement('div');
        this.initialize();
    }

    private async initialize() {
        await this.loadWalletBalance();
        this.render();
    }

    private async loadWalletBalance() {
        const userData = await AuthService.getUserById(this.userId);
        this.gamificationEnabled = userData?.gamification ?? true;

        if (this.gamificationEnabled) {
            const balance = await PlatoChatService.getWalletBalance(this.userId);
            if (balance === null) {
                await PlatoChatService.createWallet(this.userId);
                const newBalance = await PlatoChatService.getWalletBalance(this.userId);
                if (newBalance !== null) {
                    this.walletBalance = newBalance;
                }
            } else {
                this.walletBalance = balance;
            }
        }
    }

    private render() {
        this.element.className = 'flex flex-col sm:flex-row sm:justify-between sm:items-center mb-3 gap-2';
        this.element.innerHTML = `
            <div class="flex gap-2">
                <button class="icon-button story-btn px-3 py-1.5 bg-blue-500/10 hover:bg-blue-500/20 
                    border border-blue-500/20 rounded-lg flex items-center gap-1.5 transition-all duration-300">
                    <i class="fas fa-camera"></i>
                    <span class="text-white/90">Story</span>
                </button>
                <button class="icon-button status-btn px-3 py-1.5 bg-blue-500/10 hover:bg-blue-500/20 
                    border border-blue-500/20 rounded-lg flex items-center gap-1.5 transition-all duration-300">
                    <i class="fas fa-comment-dots"></i>
                    <span class="text-white/90">Status</span>
                </button>
            </div>
            <div class="points-display inline-flex items-center gap-3 px-4 py-2 bg-blue-500/10 
                border border-blue-500/20 rounded-xl text-blue-300 w-fit">
                <i class="fas fa-wallet"></i>
                <span class="font-medium">
                    ${this.gamificationEnabled 
                        ? `${this.walletBalance} pts` 
                        : '<span class="hidden sm:inline">Gamification </span>Disabled'}
                </span>
                <i class="fas fa-question-circle cursor-pointer hover:text-blue-200 transition-colors duration-300"></i>
            </div>
        `;

        this.attachEventListeners();
    }

    private attachEventListeners() {
        const statusBtn = this.element.querySelector('.status-btn');
        statusBtn?.addEventListener('click', () => this.showStatusDialog());

        const helpIcon = this.element.querySelector('.fa-question-circle');
        helpIcon?.addEventListener('click', () => this.showPointsExplanation());

        const storyBtn = this.element.querySelector('.story-btn');
        storyBtn?.addEventListener('click', () => this.showFeatureComingSoon());
    }

    private showStatusDialog() {
        const modal = new ModalForm();
        const content = document.createElement('div');
        content.className = 'w-[400px] max-w-full';
        content.innerHTML = `
            <div class="p-4">
                <div class="flex items-center gap-2 mb-4">
                    <div class="w-8 h-8 rounded-lg bg-blue-500/20 flex items-center justify-center">
                        <i class="fas fa-comment-dots text-blue-300"></i>
                    </div>
                    <h3 class="text-lg font-bold text-white/90">Update Status</h3>
                </div>
                <p class="text-white/60 text-sm mb-4">
                    Your status will be shared with your 6 most recently interacted nanos and marked as a status update.
                </p>
                <textarea 
                    class="w-full bg-white/5 border border-blue-500/20 rounded-xl p-4 text-white/90 
                        placeholder-white/40 focus:outline-none focus:border-blue-500/40 transition-colors
                        duration-300 min-h-[120px] resize-none" 
                    placeholder="Share an update with your recent nanos..."
                ></textarea>
                <div class="flex justify-end mt-4">
                    <button class="submit-btn px-6 py-2.5 bg-blue-500/20 hover:bg-blue-500/30 
                        rounded-xl border border-blue-500/30 text-white/90 font-medium
                        transition-all duration-300 hover:scale-105">
                        Share Status
                    </button>
                </div>
            </div>
        `;

        content.querySelector('.submit-btn')?.addEventListener('click', async () => {
            const status = (content.querySelector('textarea') as HTMLTextAreaElement).value;
            await this.handleStatusUpdate(status);
            modal.close();
        });

        modal.mount(document.body);
        modal.append(content);
        modal.open();
    }

    private showPointsExplanation() {
        const modal = new ModalForm();
        const content = document.createElement('div');
        content.className = 'w-[400px] max-w-full';
        content.innerHTML = `
            <div class="p-4">
                <div class="flex items-center gap-2 mb-4">
                    <div class="w-8 h-8 rounded-lg bg-blue-500/20 flex items-center justify-center">
                        <i class="fas fa-star text-blue-300"></i>
                    </div>
                    <h3 class="text-lg font-bold text-white/90">Points System</h3>
                </div>
                <div class="space-y-4">
                    ${this.gamificationEnabled ? `
                        <p class="text-white/80 leading-relaxed">
                            PLATO5's points system is designed to enhance your experience by encouraging 
                            meaningful interactions and promoting a well-rounded presence on the platform.
                        </p>
                        <p class="text-white/80 leading-relaxed">
                            Participate in various activities to earn points and unlock special features. 
                            If you prefer a more casual experience, you can disable the points system 
                            in your settings.
                        </p>
                        <div class="mt-6 p-4 bg-blue-500/10 rounded-xl border border-blue-500/20">
                            <p class="text-blue-300/90 text-sm">
                                <i class="fas fa-info-circle mr-2"></i>
                                Your current balance: ${this.walletBalance} points
                            </p>
                        </div>
                    ` : `
                        <p class="text-white/80 leading-relaxed">
                            You currently have gamification features disabled. You can enable the points system 
                            and other game-like features in your settings if you'd like to participate.
                        </p>
                    `}
                </div>
            </div>
        `;

        modal.mount(document.body);
        modal.append(content);
        modal.open();
    }

    private showFeatureComingSoon() {
        const modal = new ModalForm();
        const content = document.createElement('div');
        content.className = 'w-[400px] max-w-full';
        content.innerHTML = `
            <div class="p-6">
                <h3 class="text-xl font-bold text-white/90 mb-4">Coming Soon</h3>
                <p class="text-white/80">
                    Story functionality will be available in a future update. Stay tuned!
                </p>
            </div>
        `;

        modal.mount(document.body);
        modal.append(content);
        modal.open();
    }

    private async handleStatusUpdate(status: string) {
        try {
            const recentNanos = await PlatoChatService.getNanosByLastChat(0, this.userId);
            const userData = await AuthService.getUserById(this.userId);
            
            if (recentNanos) {
                const promises = recentNanos.map(nano => 
                    PlatoChatService.createChat({
                        author: 'Status from ' + userData?.name,
                        userId: this.userId,
                        nanoId: nano.ID,
                        content: status,
                        timestamp: new Date().toISOString()
                    })
                );
                
                await Promise.all(promises);
            }
        } catch (error) {
            console.error('Failed to update status:', error);
        }
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }
}