export class NearbySelectionContent {
    public onSelect?: (event: any) => void;
    private element: HTMLElement;

    constructor() {
        this.element = document.createElement('div');
        this.render();
    }

    private render() {
        this.element.className = 'space-y-6 min-w-[300px]';
        this.element.innerHTML = `
            <div class="text-center p-8">
                <div class="mb-6">
                    <i class="fas fa-tools text-4xl text-white/50"></i>
                </div>
                <h2 class="text-xl font-bold text-white/90 mb-4">
                    Nearby Events Coming Soon
                </h2>
                <p class="text-white/70 mb-4">
                    We're building something special! The Nearby Events feature 
                    is currently under construction.
                </p>
                <p class="text-sm text-white/50">
                    Check back soon for local events and meetups in your area.
                </p>
            </div>
        `;
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}