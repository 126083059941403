import { PlatoChatService } from '../actions/platochat/api-services';
import { ChatResponse, NanoResponse } from '../actions/platochat/models';
import { UserData } from '../actions/auth/models';
import { AuthService } from '../actions/auth/api-services';
import { navigate } from '../app-router';
import { ModalForm } from '../pieces/reusable/modal-form';
import { NearbySelectionContent } from '../pieces/platochat/selection/nearby-selection-content';
import { KomminSelectionContent } from '../pieces/platochat/selection/kommin-selection-content';
import { ColorPicker } from '../pieces/platochat/selection/color-picker';
import { EmojiPicker } from '../pieces/platochat/selection/emoji-picker';
import { Hash } from '../utilities/hash';
import { AnalytixSelectionContent } from '../pieces/platochat/selection/analytix-selection-content';
import { ReplySelectionContent } from '../pieces/platochat/selection/reply-selection-content';
import { AnalytixService } from '../actions/analytix/api-services';
import { KomminService } from '../actions/kommin/api-services';
import { UINotifications } from '../utilities/errors';

type AttachmentName = 'Kommin' | 'Nearby' | 'Commib' | 'Analytix' | 'Reply';

type ChatParams = {
    author: string;
    userId: number;
    nanoId: number;
    content: string;
    timestamp: string;
    analytixId?: number;
    commibId?: number;
    postId?: number;
    eventId?: number;
    replyId?: number;
};

async function rateCommib(commibId: number, rating: number) {
    try {
        const userId = Number(localStorage.getItem('userId'));
        const success = await PlatoChatService.updateRating(userId, rating, commibId);
        if (!success) {
            alert('You can only rate once');
        }
    } catch (error) {
        console.error('Rating failed:', error);
        alert('Rating failed');
    }
}

export class NanoScreen {
    private element: HTMLElement;
    private nanoId: number;
    private nano: NanoResponse | null = null;
    private modal: ModalForm;
    private users: UserData[] = [];
    private currentAttachmentId: number = 0;
    private currentAttachmentType: AttachmentName | 'none' = 'none';

    private adjectives = [
        'exciting', 'powerful', 'fascinating', 'exhilarating', 'thoughtful',
        'transformative', 'wholesome', 'funny', 'clever', 'witty', 'novel',
        'creative', 'inspiring', 'a little stupid', 'to foster community',
        'relatable', 'useful', 'experimental'
    ];

    private userBackgrounds = [
        'bg-yellow-500/30',
        'bg-purple-500/30',
        'bg-pink-500/30',
        'bg-green-500/30'
    ];

    private getRandomAdjective(): string {
        return this.adjectives[Math.floor(Math.random() * this.adjectives.length)];
    }

    constructor(nanoId: number) {
        this.nanoId = nanoId;
        this.element = document.createElement('div');
        this.modal = new ModalForm();
        
        // Make rateDiscussion available globally
        (window as any).rateDiscussion = async (discussionId: number, rating: number) => {
            const userId = Number(localStorage.getItem('userId'));
            if (!userId) return;

            const success = await KomminService.postRateDiscussion(userId, discussionId, Number(rating));
            if (success) {
                this.loadChats(); // Refresh to show updated rating
            } else {
                UINotifications.shipErrorToUI('You can only rate a discussion once', {persist: true});
            }
        };

        // Add this global function
        (window as any).rateCommib = async (commibId: number, rating: number) => {
            const userId = Number(localStorage.getItem('userId'));
            if (!userId) return;
            
            try {
                const success = await PlatoChatService.updateRating(userId, rating, commibId);
                if (success) {
                    UINotifications.shipSuccessToUI('Rating updated successfully');
                    this.loadChats(); // Refresh to show updated rating
                } else {
                    UINotifications.shipErrorToUI('You can only rate once', {persist: true});
                }
            } catch (error) {
                console.error('Failed to rate commib:', error);
                UINotifications.shipErrorToUI('Failed to rate', {persist: true});
            }
        };

        this.initialize();
        UINotifications.hideGlobalLoadingIndication();
    }

    private async initialize() {
        try {
            this.nano = await PlatoChatService.getNanoById(this.nanoId);
            if (!this.nano) {
                navigate('/unknown-route');
                return;
            }

            // Check if current user is part of this nano
            const currentUserId = Number(localStorage.getItem('userId'));
            const userIds = [
                this.nano.user1_id,
                this.nano.user2_id,
                this.nano.user3_id,
                this.nano.user4_id
            ].filter(id => id !== 0); // Filter out empty slots
            
            if (!currentUserId || !userIds.includes(currentUserId)) {
                navigate('/unknown-route');
                return;
            }

            // Fetch all user data
            try {
                const userPromises = userIds.map(id => AuthService.getUserById(id));
                const users = await Promise.all(userPromises);
                this.users = users.filter((user): user is UserData => user !== null);
            } catch (error) {
                console.error('Failed to load user data:', error);
            }

            this.render();
        } catch (error) {
            console.error('Failed to load nano:', error);
            navigate('/unknown-route');
        }
    }

    private render() {
        if (!this.nano) return;

        this.element.innerHTML = `
            <div class="h-screen text-white flex flex-col relative">
                <!-- Header -->
                <div class="flex flex-col sm:flex-row sm:items-center justify-between px-6 py-4 bg-background border-b border-white/10">
                    <!-- Top section with buttons -->
                    <div class="flex items-center gap-2 mb-3 sm:mb-0">
                        <a href="/" 
                            class="px-3 py-1.5 rounded-xl cursor-pointer 
                            bg-gradient-to-br from-blue-500/20 to-indigo-500/20
                            border border-white/10 
                            hover:border-white/20 hover:from-blue-500/30 hover:to-indigo-500/30
                            transition-all duration-300 
                            flex items-center gap-2 group">
                            <i class="fas fa-home text-white/80 group-hover:text-white"></i>
                            <span class="text-sm text-white/80 group-hover:text-white hidden sm:inline">Home</span>
                        </a>
                        <button style="background-color: #${this.nano.color?.slice(2)}"
                            class="w-8 h-8 rounded-full cursor-pointer transition-transform hover:scale-110"
                            id="color-button">
                        </button>
                        <button class="w-8 h-8 rounded-full cursor-pointer transition-transform hover:scale-110
                            bg-white/5 flex items-center justify-center"
                            id="emoji-button">
                            ${this.nano.emoji ?? '🥹'}
                        </button>
                    </div>

                    <!-- User names section -->
                    <div class="flex gap-2 flex-wrap sm:flex-nowrap">
                        ${this.users
                            .filter(user => user.id !== Number(localStorage.getItem('userId')))
                            .map((user, index) => `
                                <div class="px-2 py-0.5 border border-white/10 text-xs text-white
                                    ${this.userBackgrounds[index]} whitespace-nowrap">
                                    ${user.name.length > 10 ? user.name.substring(0, 10) + '...' : user.name}
                                </div>
                            `).join('')}
                        <div class="px-2 py-0.5 border border-white/10 text-xs text-white bg-purple-500/30 whitespace-nowrap">
                            You
                        </div>
                    </div>
                </div>

                <!-- Scrollable content area -->
                <div class="flex-1 overflow-y-auto" id="nano-content-zone">
                    <div class="h-full">
                        <!-- Chat area with drag target -->
                        <div class="min-h-full rounded-none p-8 pb-48
                            bg-background
                            transition-colors duration-200"
                             id="chat-drop-zone">
                            <div class="mb-6 text-center">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Fixed footer -->
                <div class="fixed bottom-0 left-0 right-0 bg-background shadow-lg">
                    <!-- Staging Area -->
                    <div id="staging-area" class="hidden absolute bottom-full left-1/2 -translate-x-1/2 w-[60vw] mb-4">
                        <div class="bg-[#0F0F0F] px-6 py-5 rounded-2xl border border-white/10 shadow-lg">
                            <div class="relative">
                                <button id="close-staging" 
                                    class="absolute -top-3 -right-3 w-8 h-8 rounded-full bg-background 
                                    border border-white/10 hover:bg-white/10 transition-colors 
                                    flex items-center justify-center shadow-lg">
                                    <i class="fas fa-times text-white/80"></i>
                                </button>
                                <div id="staging-content" class="pr-6"></div>
                            </div>
                        </div>
                    </div>

                    <!-- Draggable Attachments -->
                    <div class="px-4 py-4">
                        <div class="flex gap-4 overflow-x-auto pb-2 justify-center" id="draggables-container">
                            ${this.renderDraggableItem('Kommin', 'K', 'Attach Kommin Discussion')}
                            ${this.renderDraggableItem('Nearby', '<i class="fas fa-calendar"></i>', 'Attach Nearby Event (Coming Soon)')}
                            ${this.renderDraggableItem('Commib', '<i class="fas fa-snowflake"></i>', 'Attach Icebreaker')}
                            ${this.renderDraggableItem('Analytix', '<i class="fas fa-chart-bar"></i>', 'Attach Personality Analytix')}
                            ${this.renderDraggableItem('Reply', '@', 'Attach Reply')}
                        </div>
                    </div>

                    <!-- Text Input -->
                    <div class="p-4 flex justify-center">
                        <div class="flex gap-3 w-full max-w-3xl">
                            <input type="text" 
                                class="flex-1 text-white rounded-xl px-4 py-3 
                                border border-white/10 
                                focus:border-white/20 focus:outline-none
                                placeholder-white/40"
                                placeholder="Send something ${this.getRandomAdjective()}...">
                            <button class="px-4 py-2 rounded-xl border border-white/10 
                                text-white text-2xl font-bold
                                transition-all duration-300 hover:scale-105 active:scale-95">
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        `;

        this.modal.mount(this.element);

        // Add drag and drop listeners
        const dropZone = this.element.querySelector('#chat-drop-zone');
        const draggablesContainer = this.element.querySelector('#draggables-container');

        if (dropZone && draggablesContainer) {
            let draggedElement: HTMLElement | null = null;
            let offsetX: number = 0;
            let offsetY: number = 0;

            // Prevent multiple drags and setup custom drag
            draggablesContainer.addEventListener('dragstart', (event: Event) => {
                const dragEvent = event as DragEvent;
                const target = dragEvent.target as HTMLElement;
                
                // Prevent ghost image
                if (dragEvent.dataTransfer) {
                    const emptyImg = new Image();
                    dragEvent.dataTransfer.setDragImage(emptyImg, 0, 0);
                }

                // Setup custom drag
                draggedElement = target.cloneNode(true) as HTMLElement;
                document.body.appendChild(draggedElement);
                draggedElement.style.position = 'fixed';
                draggedElement.style.pointerEvents = 'none';
                draggedElement.style.zIndex = '1000';
                
                // Calculate offset from mouse to element center
                const rect = target.getBoundingClientRect();
                offsetX = dragEvent.clientX - (rect.left + rect.width / 2);
                offsetY = dragEvent.clientY - (rect.top + rect.height / 2);

                // Hide other draggables
                const draggables = draggablesContainer.querySelectorAll('[draggable="true"]');
                draggables.forEach(draggable => {
                    if (draggable !== target) {
                        draggable.setAttribute('draggable', 'false');
                    }
                });

                // Add this line to store the attachment type
                dragEvent.dataTransfer?.setData('attachment', target.dataset.attachment || '');
            });

            document.addEventListener('dragover', (event: Event) => {
                const dragEvent = event as DragEvent;
                if (draggedElement) {
                    dragEvent.preventDefault();
                    draggedElement.style.left = `${dragEvent.clientX - offsetX}px`;
                    draggedElement.style.top = `${dragEvent.clientY - offsetY}px`;
                }
            });

            draggablesContainer.addEventListener('dragend', () => {
                if (draggedElement) {
                    draggedElement.remove();
                    draggedElement = null;
                }
                
                const draggables = draggablesContainer.querySelectorAll('[draggable="false"]');
                draggables.forEach(draggable => {
                    draggable.setAttribute('draggable', 'true');
                });
            });

            dropZone.addEventListener('dragover', (e) => {
                e.preventDefault();
                dropZone.classList.remove('bg-background');
                dropZone.classList.add('bg-gradient-to-br', 'from-blue-500/20', 'to-indigo-500/20');
            });

            dropZone.addEventListener('dragleave', () => {
                dropZone.classList.remove('bg-gradient-to-br', 'from-blue-500/20', 'to-indigo-500/20');
                dropZone.classList.add('bg-background');
            });

            dropZone.addEventListener('drop', (e: Event) => {
                e.preventDefault();
                const dragEvent = e as DragEvent;
                dropZone.classList.remove('bg-gradient-to-br', 'from-blue-500/20', 'to-indigo-500/20');
                dropZone.classList.add('bg-background');

                // Change this line - it's looking for any draggable element instead of the currently dragged one
                const attachmentType = dragEvent.dataTransfer?.getData('attachment') as AttachmentName;
                this.handleAttachmentDrop(attachmentType);
            });
        }

        // Add click handlers for draggables
        const draggables = this.element.querySelectorAll('[data-attachment]');
        draggables.forEach(draggable => {
            draggable.addEventListener('click', (e) => {
                const attachmentType = draggable.getAttribute('data-attachment') as AttachmentName;
                if (attachmentType) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleAttachmentDrop(attachmentType);
                }
            });
        });

        // Add click handlers for color and emoji buttons
        const colorButton = this.element.querySelector('#color-button');
        const emojiButton = this.element.querySelector('#emoji-button');

        if (colorButton) {
            colorButton.addEventListener('click', () => this.handleColorClick());
        }
        if (emojiButton) {
            emojiButton.addEventListener('click', () => this.handleEmojiClick());
        }

        const closeStaging = this.element.querySelector('#close-staging');
        const stagingArea = this.element.querySelector('#staging-area');
        const stagingContent = this.element.querySelector('#staging-content');

        if (closeStaging && stagingArea && stagingContent) {
            closeStaging.addEventListener('click', () => {
                stagingArea.classList.add('hidden');
                stagingContent.innerHTML = '';
            });
        }

        this.loadChats();
        this.setupChatInput();
        UINotifications.hideGlobalLoadingIndication();
    }

    private renderDraggableItem(name: AttachmentName, icon: string, tooltip: string): string {
        const gradients = {
            'Kommin': 'from-[#414141] to-[#272727]',
            'Nearby': 'from-[#154DAD] to-[#CB0CEC]',
            'Commib': 'from-[#0088ff] to-[#8EDBFF]',
            'Analytix': 'from-[#E438B9] to-[#EC4073]',
            'Reply': 'from-[#ff0000] to-[#AA0606]'
        };

        const iconColors = {
            'Kommin': 'text-orange-400',
            'Nearby': 'text-white',
            'Commib': 'text-white',
            'Analytix': 'text-white',
            'Reply': 'text-white'
        };

        const fontWeight = name === 'Kommin' || name === 'Reply' ? 'font-extrabold' : 'font-bold';

        return `
            <div class="group relative shrink-0 cursor-pointer" draggable="true" data-attachment="${name}">
                <div class="w-14 h-14 bg-gradient-to-br ${gradients[name]} rounded-full 
                    flex items-center justify-center text-2xl ${fontWeight} ${iconColors[name]}
                    shadow-lg hover:scale-105 transition-all duration-300
                    active:scale-95">
                    ${icon}
                </div>
                <!-- Tooltip -->
                <div class="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 
                    bg-black/90 text-white text-sm rounded whitespace-nowrap
                    opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    ${tooltip}
                </div>
            </div>
        `;
    }

    private async handleAttachmentDrop(attachmentType: AttachmentName) {
        const stagingArea = this.element.querySelector('#staging-area');
        const stagingContent = this.element.querySelector('#staging-content');
        if (!stagingArea || !stagingContent || !this.nano) return;

        stagingArea.classList.remove('hidden');
        stagingContent.innerHTML = ''; // Clear previous content

        switch (attachmentType) {
            case 'Kommin':
                const komminContent = new KomminSelectionContent((discussion) => {
                    stagingContent.innerHTML = `
                        <div class="flex flex-col gap-2 text-white relative">
                            <div class="flex items-center gap-2 mb-1">
                                <div class="text-sm text-white/60">Discussion by</div>
                                <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                                    rounded-full border border-white/10">
                                    ${this.truncateText(discussion.Author, 20)}
                                </div>
                            </div>
                            <div class="text-base">${this.truncateText(discussion.Body)}</div>
                            <div class="text-xs text-white/40 mt-2">
                                ${discussion.Timestamp}
                            </div>
                            <a href="/kommin/discussion/${Hash.encodeId(discussion.ID)}" 
                                class="absolute bottom-0 right-0 w-8 h-8 flex items-center justify-center
                                bg-white/5 hover:bg-white/10 rounded-lg border border-white/10 
                                transition-colors"
                                target="_blank">
                                <i class="fas fa-external-link-alt text-white/60"></i>
                            </a>
                        </div>
                    `;
                    this.currentAttachmentId = discussion.ID;
                    this.currentAttachmentType = 'Kommin';
                    this.modal.close();
                });
                this.modal.clear();
                this.modal.append(komminContent.getElement());
                this.modal.open();
                
                // Only triggers when user clicks overlay or close button
                this.modal.onVoluntaryClose(() => this.clearStagingArea());
                break;
            case 'Nearby':
                const nearbyContent = new NearbySelectionContent();
                this.modal.clear();
                this.modal.append(nearbyContent.getElement());
                this.modal.open();
                this.clearStagingArea(); // Clear staging area after opening modal
                break;
            case 'Commib':
                stagingContent.innerHTML = '<div class="animate-pulse text-white/60">Loading icebreaker...</div>';
                const commib = await PlatoChatService.getHighestRatedCommibForNano(
                    this.nano.user1_id,
                    this.nano.user2_id,
                    this.nano.user3_id,
                    this.nano.user4_id
                );
                
                if (commib) {
                    stagingContent.innerHTML = `
                        <div class="flex flex-col gap-2 text-white">
                            <div class="flex items-center gap-2 mb-1">
                                <div class="text-sm text-white/60">Icebreaker by</div>
                                <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                                    rounded-full border border-white/10">
                                    ${this.truncateText(commib.author, 20)}
                                </div>
                            </div>
                            <div class="text-lg">${this.truncateText(commib.content)}</div>
                            <div class="flex gap-2 text-sm text-white/40">
                                <span>Avg Rating: ${commib.avgRating.toFixed(1)}</span>
                                <span></span>
                                <span>Views: ${commib.views}</span>
                            </div>
                        </div>
                    `;
                    this.currentAttachmentId = commib.ID;
                    this.currentAttachmentType = 'Commib';
                    this.modal.close();
                } else {
                    stagingContent.innerHTML = `
                        <div class="text-white/60">
                            No icebreaker available at this time
                        </div>
                    `;

                }
                break;
            case 'Analytix':
                const analytixContent = new AnalytixSelectionContent(this.users, (result) => {
                    stagingContent.innerHTML = `
                        <div class="flex flex-col gap-2 text-white relative">
                            <div class="flex items-center gap-2 mb-1">
                                <div class="text-xs text-white/40">Personality Analytix for</div>
                                <div class="px-2 py-0.5 text-xs text-white/60 bg-white/5 rounded-full border border-white/10">
                                    ${this.truncateText(result.userName, 20)}
                                </div>
                            </div>
                            <div class="flex justify-center gap-0.5 max-w-[300px] mx-auto w-full">
                                ${result.scores.map(({ category, score }) => {
                                    const gradientClass = 
                                        category === 'O' ? 'from-purple-500 to-pink-500' :
                                        category === 'C' ? 'from-orange-500 to-yellow-300' :
                                        category === 'E' ? 'from-blue-500 to-cyan-300' :
                                        category === 'A' ? 'from-green-500 to-emerald-300' :
                                                         'from-red-500 to-rose-300';
                                    return `
                                        <div class="flex-1">
                                            <div class="h-20 bg-white/5 rounded-lg relative overflow-hidden">
                                                <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t ${gradientClass}"
                                                    style="height: ${score}%">
                                                </div>
                                            </div>
                                            <div class="text-[10px] mt-1 text-center">
                                                <div class="text-white/40">${category}</div>
                                                <div class="text-white/60">${score.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                    `;
                                }).join('')}
                            </div>
                        </div>
                    `;
                    this.currentAttachmentId = result.analytixId;
                    this.currentAttachmentType = 'Analytix';
                    this.modal.close();
                });
                this.modal.clear();
                this.modal.append(analytixContent.getElement());
                this.modal.open();
                this.modal.onVoluntaryClose(() => this.clearStagingArea());
                break;
            case 'Reply':
                const replyContent = new ReplySelectionContent(this.nano.ID, (chat) => {
                    stagingContent.innerHTML = `
                        <div class="flex flex-col gap-2 text-white relative">
                            <div class="flex items-center gap-2 mb-1">
                                <div class="text-sm text-white/60">Replying to</div>
                                <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                                    rounded-full border border-white/10">
                                    ${this.truncateText(chat.author, 20)}
                                </div>
                            </div>
                            <div class="text-base">${this.truncateText(chat.content)}</div>
                            <div class="text-xs text-white/40 mt-2">
                                ${new Date(chat.timestamp).toLocaleTimeString()}
                            </div>
                        </div>
                    `;
                    this.currentAttachmentId = chat.ID;
                    this.currentAttachmentType = 'Reply';
                    this.modal.close();
                });
                this.modal.clear();
                this.modal.append(replyContent.getElement());
                this.modal.open();
                this.modal.onVoluntaryClose(() => this.clearStagingArea());
                break;
        }
    }

    private async handleColorClick() {
        const colorPicker = new ColorPicker(async (newColor) => {
            if (this.nano) {
                await PlatoChatService.updateNanoColorAndEmoji(
                    this.nano.ID, 
                    newColor,  // This will now be in the format 'ff2196f3'
                    this.nano.emoji ?? '🥹'
                );
                this.initialize();
                this.modal.close();
            }
        });

        this.modal.clear();
        this.modal.append(colorPicker.getElement());
        this.modal.open();
    }

    private async handleEmojiClick() {
        const emojiPicker = new EmojiPicker(async (newEmoji) => {
            if (this.nano) {
                // Keep the existing color, only update emoji
                await PlatoChatService.updateNanoColorAndEmoji(
                    this.nano.ID,
                    this.nano.color ?? '#0088ff',  // Keep existing color
                    newEmoji  // New emoji from picker
                );
                this.initialize();
                this.modal.close();
            }
        });

        this.modal.clear();
        this.modal.append(emojiPicker.getElement());
        this.modal.open();
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        if (this.element.parentElement) {
            this.element.parentElement.removeChild(this.element);
        }
    }

    private truncateText(text: string, maxLength: number = 200): string {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength - 3) + '...';
    }

    private clearStagingArea(): void {
        const stagingArea = this.element.querySelector('#staging-area');
        const stagingContent = this.element.querySelector('#staging-content');
        if (stagingArea && stagingContent) {
            stagingArea.classList.add('hidden');
            stagingContent.innerHTML = '';
            this.currentAttachmentId = 0;
            this.currentAttachmentType = 'none';
        }
    }

    private async loadChats() {
        const contentZone = this.element.querySelector('#chat-drop-zone');
        if (!contentZone || !this.nano) return;

        const chats = await PlatoChatService.getChats(this.nano.ID, 0);
        if (!chats || chats.length === 0) {
            contentZone.innerHTML = `
                <div class="h-full flex flex-col items-center justify-center text-center">
                    <i class="fas fa-comment-dots text-white/10 text-6xl mb-6"></i>
                    <div class="text-xl text-indigo-400 font-light max-w-md">
                        New nano here! Get started by dragging an attachment below into the chat and then share your reaction.
                    </div>
                </div>
            `;
            return;
        }

        const chatContainer = document.createElement('div');
        chatContainer.className = 'flex flex-col gap-4 px-2 sm:px-4 w-full sm:max-w-4xl mx-auto';

        for (const chat of chats) {
            const chatElement = document.createElement('div');
            chatElement.className = 'flex flex-col sm:flex-row gap-0 sm:gap-3 items-stretch w-full relative';
            
            // Main chat content
            const contentHtml = `
                <div class="w-full sm:flex-[0.6] min-w-0">
                    <div class="h-full p-4 rounded-xl sm:rounded-xl rounded-b-none 
                        bg-white/5 backdrop-blur-sm border border-white/10 sm:border-b-white/10 border-b-transparent">
                        <div class="font-bold text-white/90 mb-2">${chat.author}</div>
                        <div class="text-white/80 text-base break-words">${chat.content}</div>
                        <div class="text-white/40 text-xs text-center mt-2">
                            ${new Date(chat.timestamp).toLocaleTimeString()}
                        </div>
                    </div>
                </div>
                <!-- Connector area (only visible on mobile) -->
                <div class="sm:hidden h-12 px-6 py-3 bg-gradient-to-b from-white/5 to-[${this.getAttachmentColor(chat)}] 
                    border-l border-r border-white/10
                    flex items-center gap-3">
                    <div class="w-6 h-6 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 
                        flex items-center justify-center shadow-lg
                        ring-2 ring-blue-500/50 ring-offset-1 ring-offset-blue-500/10">
                        <i class="fas fa-paperclip rotate-45 text-white text-sm"></i>
                    </div>
                    <span class="text-xs text-white/60">Reacting to:</span>
                </div>
            `;

            // Attachment section - full width on mobile, 40% on desktop
            let attachment = null;
            if (chat.analytix_id) {
                const analytixData = await AnalytixService.getAnalytixById(chat.analytix_id);
                if (analytixData) {
                    const user = await AuthService.getUserById(analytixData.userId);
                    attachment = {
                        ...analytixData,
                        analytixId: chat.analytix_id,
                        userName: user?.name || 'Unknown User'
                    };
                }
                console.log('Analytix attachment:', attachment);
            } else if (chat.commib_id) {
                attachment = await PlatoChatService.getCommibById(chat.commib_id);
                console.log('Commib attachment:', attachment);
            } else if (chat.post_id) {
                attachment = await KomminService.getDiscussionById(chat.post_id);
                console.log('Kommin attachment:', attachment);
            } else if (chat.reply_id) {
                attachment = await PlatoChatService.getChatById(chat.reply_id);
                console.log('Reply attachment:', attachment);
            }

            const attachmentHtml = `
                <div class="w-full sm:flex-[0.4] min-w-0">
                    <div class="h-full p-4 rounded-xl sm:rounded-xl rounded-t-none 
                        backdrop-blur-md bg-opacity-20 border border-white/10
                        ${this.getAttachmentColor(chat)} 
                        bg-gradient-to-br from-white/5 to-transparent">
                        ${this.buildAttachmentContent(chat, attachment)}
                    </div>
                </div>
            `;

            chatElement.innerHTML = `${contentHtml}${attachmentHtml}`;
            chatContainer.appendChild(chatElement);
        }

        const existingContainer = contentZone.querySelector('.chat-container');
        if (existingContainer) existingContainer.remove();
        
        chatContainer.classList.add('chat-container');
        contentZone.appendChild(chatContainer);
    }

    private getAttachmentColor(chat: ChatResponse): string {
        if (chat.commib_id) return 'bg-[#0D69B4]/40';
        if (chat.post_id) return 'bg-[#B86E00]/40';
        if (chat.reply_id) return 'bg-[#B8160A]/40';
        if (chat.analytix_id) return 'bg-[#8F0988]/40';
        return 'bg-[#313131]/40';
    }

    private buildAttachmentContent(chat: ChatResponse, attachment: any): string {
        if (!attachment) {
            const noAttachmentStrings = [
                'Nothing here', 'Nada', 'Anybody seen my attachment?',
                'Zilch', 'No attachment, no fun', 'Wherefore art thou attachment?',
                '[Enter attachment here]', '♫ If I was an attachment ♫',
                'Attached at the hip', 'Lack of attachment means lack of satisfaction'
            ];
            const randomMessage = noAttachmentStrings[Math.floor(Math.random() * noAttachmentStrings.length)];
            return `<div class="text-white/90 text-sm text-center">${randomMessage}</div>`;
        }

        if (chat.post_id) {
            return `
                <div class="flex flex-col gap-2 text-white break-words">
                    <!-- Author -->
                    <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                        rounded-full border border-white/10 w-fit">
                        ${this.truncateText(attachment.Author, 20)}
                    </div>

                    <!-- Content -->
                    <div class="text-base whitespace-normal break-words">
                        ${this.truncateText(attachment.Body)}
                    </div>

                    <!-- Rating -->
                    <div class="text-xs text-white/40">
                        Avg Rating: ${attachment.AverageRating.toFixed(1)}
                    </div>

                    <!-- Rating Controls -->
                    <div class="flex items-center gap-2">
                        <input type="range" min="1" max="100" step="1" value="50"
                            class="w-24 sm:w-32 md:w-full accent-blue-500"
                            data-discussion-id="${attachment.ID}"
                            oninput="this.nextElementSibling.textContent = this.value">
                        <span class="text-xs text-white/60 w-8">50</span>
                    </div>

                    <button class="px-3 py-1 text-xs bg-blue-500/20 hover:bg-blue-500/30 
                        rounded border border-white/10 transition-colors"
                        onclick="rateDiscussion(${attachment.ID}, this.previousElementSibling.querySelector('input').value)">
                        Rate Discussion
                    </button>

                    <a href="/kommin/discussion/${Hash.encodeId(attachment.ID)}" 
                        class="w-8 h-8 flex items-center justify-center
                        bg-white/5 hover:bg-white/10 rounded-lg border border-white/10 
                        transition-colors"
                        target="_blank">
                        <i class="fas fa-external-link-alt text-white/60"></i>
                    </a>

                    <div class="text-xs text-white/40">
                        ${new Date(attachment.CreatedAt).toLocaleString()}
                    </div>
                </div>
            `;
        }

        if (chat.analytix_id) {
            return `
                <div class="flex flex-col gap-2 text-white">
                    <div class="flex items-center gap-2 mb-1">
                        <div class="text-xs text-white/40">Personality Analytix for</div>
                        <div class="px-2 py-0.5 text-xs text-white/60 bg-white/5 rounded-full border border-white/10">
                            ${this.truncateText(attachment.userName, 20)}
                        </div>
                    </div>
                    <div class="flex justify-center gap-0.5 max-w-[200px] sm:max-w-[300px] mx-auto w-full">
                        ${[
                            { category: 'O', score: attachment.tOpenness, gradient: 'from-purple-500 to-pink-500' },
                            { category: 'C', score: attachment.tConscientiousness, gradient: 'from-orange-500 to-yellow-300' },
                            { category: 'E', score: attachment.tExtraversion, gradient: 'from-blue-500 to-cyan-300' },
                            { category: 'A', score: attachment.tAgreeableness, gradient: 'from-green-500 to-emerald-300' },
                            { category: 'N', score: attachment.tNeuroticism, gradient: 'from-red-500 to-rose-300' }
                        ].map(({ category, score, gradient }) => `
                            <div class="flex-1">
                                <div class="h-20 bg-white/5 rounded-lg relative overflow-hidden">
                                    <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t ${gradient}"
                                        style="height: ${score}%">
                                    </div>
                                </div>
                                <div class="text-[10px] mt-1 text-center">
                                    <div class="text-white/40">${category}</div>
                                    <div class="text-white/60">${score.toFixed(1)}%</div>
                                </div>
                            </div>
                        `).join('')}
                    </div>
                </div>
            `;
        }

        if (chat.commib_id) {
            return `
                <div class="flex flex-col gap-2 text-white break-words">
                    <!-- Header -->
                    <div class="flex items-center justify-between">
                        <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                            rounded-full border border-white/10">
                            ${this.truncateText(attachment.author, 20)}
                        </div>
                    </div>

                    <!-- Content -->
                    <div class="text-base whitespace-normal break-words p-3 
                        bg-white/5 rounded-lg border border-white/10">
                        ${this.truncateText(attachment.content)}
                    </div>

                    <!-- Rating Controls -->
                    <div class="flex items-center gap-2">
                            <input type="range" min="1" max="100" step="1" value="50"
                            class="w-24 sm:w-32 md:w-full accent-blue-500"                              data-commib-id="${attachment.ID}"
                                oninput="this.nextElementSibling.textContent = this.value">
                        <span class="text-xs text-white/60 w-8">50</span>
                        </div>

                    <button class="px-3 py-1 text-xs bg-blue-500/20 hover:bg-blue-500/30 
                        rounded border border-white/10 transition-colors"
                            onclick="window.rateCommib(${attachment.ID}, parseInt(this.previousElementSibling.querySelector('input').value))">
                            Rate Icebreaker
                        </button>
                    </div>

                    <!-- Stats -->
                    <div class="flex justify-between text-xs text-white/60 mt-1 px-1">
                        <div class="flex items-center gap-1">
                            <i class="fas fa-star text-yellow-500/80"></i>
                            ${attachment.avgRating?.toFixed(1) || 'No ratings'}
                        </div>
                        <div class="flex items-center gap-1">
                            <i class="fas fa-eye"></i>
                            ${attachment.views}
                        </div>
                    </div>
                </div>
            `;
        }

        if (chat.reply_id) {
            return `
                <div class="flex flex-col gap-3 text-white break-words">
                    <!-- Header -->
                    <div class="flex items-center justify-between">
                        <div class="px-2 py-0.5 text-xs text-white bg-red-500/30 
                            rounded-full border border-white/10">
                            ${this.truncateText(attachment.author, 20)}
                        </div>
                        <div class="text-xs text-white/60">
                            <i class="fas fa-reply mr-1"></i>Reply
                        </div>
                    </div>

                    <!-- Content -->
                    <div class="text-base whitespace-normal break-words p-3 
                        bg-white/5 rounded-lg border border-white/10">
                        ${this.truncateText(attachment.content)}
                    </div>

                    <!-- Timestamp -->
                    <div class="text-xs text-white/40 text-right">
                        ${new Date(attachment.timestamp).toLocaleTimeString([], { 
                            hour: '2-digit', 
                            minute: '2-digit'
                        })}
                    </div>
                </div>
            `;
        }

        return '';
    }

    private setupChatInput() {
        const input = this.element.querySelector('input[type="text"]') as HTMLInputElement;
        const sendButton = this.element.querySelector('.flex.gap-3.w-full.max-w-3xl button') as HTMLButtonElement;
        if (!input || !sendButton) {
            console.error('Chat input elements not found');
            return;
        }

        const handleSend = async () => {
            if (!input.value.trim() || !this.nano) return;
            
            const userId = Number(localStorage.getItem('userId'));
            const user = this.users.find(u => u.id === userId);
            if (!user) {
                console.error('User not found:', userId);
                return;
            }

            const chatParams: ChatParams = {
                author: user.name,
                userId: user.id,
                nanoId: this.nano.ID,
                content: input.value,
                timestamp: new Date().toISOString(),
                analytixId: undefined,
                commibId: undefined,
                postId: undefined,
                eventId: undefined,
                replyId: undefined
            };

            // Add attachment if exists
            if (this.currentAttachmentType !== 'none' && this.currentAttachmentId) {
                const attachmentId = Number(this.currentAttachmentId);
                switch (this.currentAttachmentType) {
                    case 'Analytix':
                        chatParams.analytixId = attachmentId;
                        break;
                    case 'Commib':
                        chatParams.commibId = attachmentId;
                        break;
                    case 'Kommin':
                        chatParams.postId = attachmentId;
                        break;
                    case 'Nearby':
                        chatParams.eventId = attachmentId;
                        break;
                    case 'Reply':
                        chatParams.replyId = attachmentId;
                        break;
                }
            }

            console.log('Chat params:', chatParams);

            const success = await PlatoChatService.createChat(chatParams);
            if (success) {
                input.value = '';
                this.clearStagingArea();
                window.location.reload();
            }
        };

        input.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') handleSend();
        });
        sendButton.addEventListener('click', handleSend);
    }

    private updateStagingContent(type: AttachmentName, data: any) {
        const stagingContent = this.element.querySelector('#staging-content');
        if (!stagingContent) return;

        this.currentAttachmentType = type;
        this.currentAttachmentId = data.id?.toString() || null;

        switch (type) {
            case 'Analytix':
                stagingContent.setAttribute('data-insights', 
                    `${data.userName}: O:${data.scores[0].score}% C:${data.scores[1].score}% E:${data.scores[2].score}% A:${data.scores[3].score}% N:${data.scores[4].score}%`
                );
                break;
            case 'Kommin':
                stagingContent.setAttribute('data-insights', data.Body?.substring(0, 100) + '...');
                break;
            case 'Commib':
                stagingContent.setAttribute('data-insights', data.content);
                break;
            case 'Nearby':
                stagingContent.setAttribute('data-insights', data.title);
                break;
        }
    }

    private async loadAttachment(chat: ChatResponse): Promise<any> {
        if (chat.commib_id) {
            const commib = await PlatoChatService.getCommibById(chat.commib_id);
            if (commib) {
                // Increment view count when loading commib
                await PlatoChatService.incrementViewCount(chat.commib_id);
                return commib;
            }
        }
        // ... rest of the method
    }
}
