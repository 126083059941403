import { Header } from './header';
import { DailyCommib } from './daily-commib';
import { NanoBubbles } from './nano-bubbles';

export class PlatoChatContent {
    private element: HTMLElement;
    private header?: Header;
    private dailyCommib: DailyCommib;
    private nanoBubble?: NanoBubbles;
    private isLoggedIn: boolean;

    constructor() {
        this.element = document.createElement('div');
        const userId = localStorage.getItem('userId');
        this.isLoggedIn = !!userId;

        if (this.isLoggedIn) {
            this.header = new Header(parseInt(userId!));
            this.nanoBubble = new NanoBubbles(parseInt(userId!));
        }
        this.dailyCommib = new DailyCommib(userId ? parseInt(userId) : null);
    }

    public mount(parent: HTMLElement): void {
        // Clear any existing content
        this.element.innerHTML = '';
        
        if (this.header) {
            this.header.mount(this.element);
        }
        this.dailyCommib.mount(this.element);
        
        if (this.nanoBubble) {
            this.nanoBubble.mount(this.element);
        } else {
            const placeholder = document.createElement('div');
            placeholder.className = `
                p-6 min-h-[300px] flex items-center justify-center mt-8
                bg-black/20 backdrop-blur-sm border border-white/10 rounded-2xl
                text-white/80 text-center text-2xl
            `;
            placeholder.textContent = "This is where you'll be able to chat when you're logged in";
            this.element.appendChild(placeholder);
        }
        
        parent.appendChild(this.element);
    }
}
