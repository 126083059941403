import { TopBar } from '../pieces/reusable/top-bar';
import { ModalForm } from '../pieces/reusable/modal-form';
import { parse5mark, build5markFromDiscussion } from '../utilities/5mark-parser';
import { KomminService } from '../actions/kommin/api-services';
import { DiscussionResponse, CommentResponse } from '../actions/kommin/models';
import { UINotifications } from '../utilities/errors';
import { AuthService } from '../actions/auth/api-services';
import { KomminFooter } from '../pieces/kommin/kommin-footer';
import { ContentTitle } from '../pieces/reusable/content-title';
import { GamificationReminder } from '../pieces/reusable/gamification-reminder';
import { DiscussionMetadata } from '../utilities/metadata'; 

export class KomminScreen {
    private element: HTMLElement;
    private modalForm: ModalForm;
    private topBar: TopBar;
    private komminFooter: KomminFooter;
    private discussionId: number;
    private mainContent: HTMLElement;
    private commentsSection: HTMLElement;
    private currentRating?: string;
    private hasRated: boolean = false;

    constructor(discussionId: number) {
        this.discussionId = discussionId;
        this.modalForm = new ModalForm();
        this.topBar = new TopBar(this.modalForm);
        this.komminFooter = new KomminFooter(this.modalForm);
        this.element = document.createElement('div');
        this.element.className = 'min-h-screen';
        
        // Create main layout containers
        this.mainContent = document.createElement('div');
        this.mainContent.className = 'w-full lg:w-2/3 pr-0 lg:pr-4';
        
        // Single comments section that's responsive
        this.commentsSection = document.createElement('div');
        this.commentsSection.className = `
            w-full lg:w-1/3
            relative
            overflow-y-auto
            rounded-2xl
            border border-orange-500/10
            backdrop-blur-md
            bg-gradient-to-br from-orange-900/20 via-amber-900/10 to-orange-800/20
            mt-8
        `;
        
        this.modalForm.mount(this.element);
        this.initialize();
    }

    private async initialize(): Promise<void> {
        try {
            // Get discussion data first
            const discussion = await KomminService.getDiscussionById(this.discussionId);
            if (!discussion) {
                throw new Error('Discussion not found');
            }

            // Update metadata immediately
            document.title = `PLATO5 Kommin Discussion: ${discussion.Body.split('\n')[0]}`;
            
            // Update meta tags directly
            const metaTags = {
                'description': `Discussion from our interest-tracking discussion board: ${discussion.Body.slice(0, 160)}...`,
                'author': discussion.Author,
                'og:type': 'discussion',
                'og:title': `PLATO5 Kommin Discussion: ${discussion.Body.split('\n')[0]}`,
                'og:description': `Discussion from our interest-tracking discussion board: ${discussion.Body.slice(0, 160)}...`,
                'article:published_time': discussion.CreatedAt,
                'article:author': discussion.Author
            };

            // Update or create meta tags
            Object.entries(metaTags).forEach(([name, content]) => {
                let meta = document.querySelector(`meta[name="${name}"]`) || 
                           document.querySelector(`meta[property="${name}"]`);
                
                if (!meta) {
                    meta = document.createElement('meta');
                    if (name.startsWith('og:') || name.startsWith('article:')) {
                        meta.setAttribute('property', name);
                    } else {
                        meta.setAttribute('name', name);
                    }
                    document.head.appendChild(meta);
                }
                meta.setAttribute('content', content);
            });

            // Continue with normal initialization
            this.topBar.mount(this.element);

        // Container for the entire header section
        const titleContainer = document.createElement('div');
        titleContainer.className = 'container mx-auto px-6 mt-24 mb-8';
        
        // Create the content title
        const contentTitle = new ContentTitle('Kommin Discussions', 'kommin');
        
        // Add GamificationReminder to ContentTitle's container
        const userId = localStorage.getItem('userId');
        if (userId) {
            const reminder = new GamificationReminder(parseInt(userId, 10));
            // Mount both to the title container
            contentTitle.mount(titleContainer);
            reminder.mount(contentTitle.getContainer()); // We'll need to add this getter
        } else {
            contentTitle.mount(titleContainer);
        }

        this.element.appendChild(titleContainer);

        // Create flex container with proper centering
        const container = document.createElement('div');
        container.className = 'container mx-auto px-6 flex flex-col lg:flex-row gap-8';
        
        container.appendChild(this.mainContent);
        container.appendChild(this.commentsSection);
        this.element.appendChild(container);
        this.komminFooter.mount(this.element);

        // Show loading indicator
        UINotifications.showGlobalLoadingIndication();

        try {
            // Record impression first
            await KomminService.postRecordImpression(this.discussionId);
            
            // Then load content
            await Promise.all([
                this.loadDiscussion(),
                this.loadComments()
            ]);
        } catch (error) {
            console.error('Error loading content:', error);
            UINotifications.shipErrorToUI('Failed to load content');
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    } catch (error) {
        console.error('Error initializing:', error);
        UINotifications.shipErrorToUI('Failed to initialize discussion');
        }
    }

    private async loadDiscussion(): Promise<void> {
        try {
            console.log('Loading discussion:', this.discussionId);
            const discussion = await KomminService.getDiscussionById(this.discussionId);
            console.log('Discussion loaded:', discussion);
            
            if (!discussion) {
                console.error('Discussion not found');
                history.replaceState({}, '', '/unknown-route');
                window.location.reload();
                return;
            }

            const fiveMark = build5markFromDiscussion(discussion);
            console.log('5mark built:', fiveMark);
            const content = parse5mark(fiveMark);
            this.mainContent.appendChild(content);

            const ratingSlider = this.createRatingSlider();
            this.mainContent.appendChild(ratingSlider);

        } catch (error) {
            console.error('Error loading discussion:', error);
            history.replaceState({}, '', '/unknown-route');
            window.location.reload();
            throw error;
        }
    }

    private createCommentInput(): HTMLElement {
        const container = document.createElement('div');
        container.className = `
            mb-6 p-4 rounded-lg
            bg-gradient-to-br from-orange-900/20 via-amber-900/10 to-orange-800/20
            backdrop-blur-sm border border-orange-500/10
        `;

        const input = document.createElement('textarea');
        input.className = `
            w-full
            bg-white/5
            border border-orange-500/10
            rounded-xl
            p-3
            text-white/90
            placeholder-white/50
            focus:outline-none
            focus:border-orange-500/50
            min-h-[100px]
            resize-none
        `;
        input.placeholder = 'Add your comment...';

        const button = document.createElement('button');
        button.className = `
            w-full
            py-2
            bg-orange-500/20
            hover:bg-orange-500/30
            rounded-lg
            border border-orange-500/10
            text-white/90
            text-sm
            transition-all
            duration-300
        `;
        button.textContent = 'Comment';

        button.addEventListener('click', async () => {
            const commentText = input.value.trim();
            if (!commentText) return;

            button.disabled = true;
            button.className += ' opacity-50 cursor-not-allowed';
            
            try {
                const userId = localStorage.getItem('userId');
                if (!userId) {
                    UINotifications.shipErrorToUI('Please log in to comment');
                    return;
                }
                
                // Get user details
                const user = await AuthService.getUserById(parseInt(userId, 10));
                if (!user) {
                    throw new Error('Failed to get user details');
                }

                const comment = await KomminService.postAddComment(
                    parseInt(userId, 10),
                    user.name, // Use actual username instead of "User"
                    this.discussionId,
                    commentText
                );

                if (comment) {
                    // Add new comment to the list
                    const commentElement = this.createCommentElement(comment);
                    const commentsContainer = this.commentsSection.querySelector('.comments-container');
                    if (commentsContainer) {
                        commentsContainer.insertBefore(commentElement, commentsContainer.firstChild);
                    }
                    
                    // Clear input
                    input.value = '';
                    UINotifications.shipHintToUI('Comment posted successfully!');
                    window.location.reload();
                } else {
                    throw new Error('Failed to post comment');
                }
            } catch (error) {
                console.error('Error posting comment:', error);
                UINotifications.shipErrorToUI('Failed to post comment');
            } finally {
                button.disabled = false;
                button.className = button.className.replace(' opacity-50 cursor-not-allowed', '');
            }
        });

        container.appendChild(input);
        container.appendChild(button);
        return container;
    }

    private async loadComments(): Promise<void> {
        const comments = await KomminService.getDiscussionComments(this.discussionId);
        
        // Clear existing content
        this.commentsSection.innerHTML = '';
        
        // Create inner container
        const innerContainer = document.createElement('div');
        innerContainer.className = 'px-6 py-12'; // Keep standard padding
        
        // Add header
        const header = document.createElement('h2');
        header.className = 'text-2xl font-bold mb-8 text-white text-center';
        header.textContent = 'Comments';
        innerContainer.appendChild(header);

        // Add comment input at the top
        innerContainer.appendChild(this.createCommentInput());

        // Create comments container
        const commentsContainer = document.createElement('div');
        commentsContainer.className = 'comments-container mt-6';

        if (!comments || comments.length === 0) {
            const noComments = document.createElement('p');
            noComments.className = 'text-white/60 text-lg';
            noComments.textContent = 'No comments yet. Be the first to comment!';
            commentsContainer.appendChild(noComments);
        } else {
            comments.forEach(comment => {
                const commentElement = this.createCommentElement(comment);
                commentsContainer.appendChild(commentElement);
            });
        }

        innerContainer.appendChild(commentsContainer);
        this.commentsSection.appendChild(innerContainer);
    }

    private createCommentElement(comment: CommentResponse): HTMLElement {
        const element = document.createElement('div');
        element.className = `
            mb-6 p-4 rounded-lg
            bg-gradient-to-br from-orange-900/20 via-amber-900/10 to-orange-800/20
            backdrop-blur-sm border border-orange-500/10
        `;
        
        element.innerHTML = `
            <div class="flex items-center justify-between mb-2">
                <span class="font-semibold text-white/90">${comment.Author}</span>
                <span class="text-sm text-white/60">${new Date(comment.Timestamp).toLocaleString()}</span>
            </div>
            <p class="text-white/80 text-lg leading-relaxed">${comment.Text}</p>
        `;
        
        return element;
    }

    private async handleRatingSubmit(rating: string, sliderId: string): Promise<void> {
        try {
            if (this.hasRated) return;
            
            const slider = document.getElementById(sliderId) as HTMLInputElement;
            const submitButton = document.getElementById(`${sliderId}-submit`) as HTMLButtonElement;
            
            if (!slider || !submitButton) return;

            // Use the existing KomminService.postRateDiscussion method
            
            const userId = localStorage.getItem('userId'); 
            if (!userId) {
                UINotifications.shipErrorToUI('It doesn\'t look like you\'re logged in.');
                throw new Error('Failed to submit rating');
            }
            const success = await KomminService.postRateDiscussion(
                parseInt(userId, 10),
                this.discussionId,
                parseInt(rating, 10)
            );

            if (success) {
                // Update UI
                slider.disabled = true;
                submitButton.disabled = true;
                submitButton.textContent = 'Rated!';
                submitButton.className += ' opacity-50 cursor-not-allowed';
                
                this.currentRating = rating;
                this.hasRated = true;
                UINotifications.shipSuccessToUI('Rating submitted successfully!');
                window.location.reload();
            } else {
                UINotifications.shipErrorToUI('You can only rate once');   
                throw new Error('Failed to submit rating');
            }
        } catch (error) {
            console.error('Error submitting rating:', error);
            this.hasRated = false;
        }
    }

    private createRatingSlider(): HTMLElement {
        const container = document.createElement('div');
        container.className = `
            mb-6 p-4 rounded-lg
            bg-gradient-to-br from-orange-900/20 via-amber-900/10 to-orange-800/20
            backdrop-blur-sm border border-orange-500/10
            mt-8
            px-6
        `;

        const sliderId = `rating-slider-${Date.now()}`;
        const slider = document.createElement('input');
        slider.type = 'range';
        slider.min = '0';
        slider.max = '100';
        slider.value = '50';
        slider.id = sliderId;
        slider.className = 'w-full';

        const button = document.createElement('button');
        button.id = `${sliderId}-submit`;
        button.className = `
            w-full py-2 mt-8
            bg-orange-500/20
            hover:bg-orange-500/30
            rounded-lg
            border border-orange-500/10
            text-white/90
            text-sm
            transition-all
            duration-300
        `;
        button.textContent = 'Submit Rating';

        button.addEventListener('click', () => {
            this.handleRatingSubmit(slider.value, sliderId);
        });

        container.appendChild(slider);
        container.appendChild(button);
        return container;
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        if (this.element.parentElement) {
            this.element.parentElement.removeChild(this.element);
        }
    }

    async getDiscussionData(): Promise<DiscussionMetadata> {
        const discussion = await KomminService.getDiscussionById(this.discussionId);
        if (!discussion) {
            throw new Error('Discussion not found');
        }
        
        // Extract first line or first X characters as title
        const title = discussion.Body.split('\n')[0].slice(0, 60) + '...';
        
        return {
            title: title,
            description: discussion.Body.slice(0, 160),
            author: discussion.Author,
            created: discussion.CreatedAt
        };
    }
}
